.flex {
  @include flex;
}
.inline-flex {
  @include inline-flex;
}
.flex-row {
  @include flex-row;
}
.flex-row-reverse {
  @include flex-row-reverse;
}
.flex-column {
  @include flex-column;
}
.flex-column-reverse {
  @include flex-column-reverse;
}
.flex-wrap {
  @include flex-wrap;
}
.flex-grow-0 {
  @include flex-grow(0);
}
.flex-grow-1 {
  @include flex-grow(1);
}
.flex-shrink-0 {
  @include flex-shrink(0);
}
.flex-shrink-1 {
  @include flex-shrink(1);
}
/*.flex-basis(25) {
  @include flex-basis(25%);
}*/
.align-items-stretch {
  @include align-items-stretch;
}
.align-items-start  {
  @include align-items-start;
}
.align-items-center {
  @include align-items-center;
}
.align-items-end {
  @include align-items-end;
}
.align-self-stretch {
  @include align-self-stretch;
}
.align-self-start {
  @include align-self-start;
}
.align-self-center {
  @include align-self-center;
}
.align-self-end {
  @include align-self-end;
}
.justify-content-start {
  @include justify-content-start;
}
.justify-content-center {
  @include justify-content-center;
}
.justify-content-end {
  @include justify-content-end;
}
.justify-content-around {
  @include justify-content-around;
}
.justify-content-between {
  @include justify-content-between;
}
.justify-content-evenly {
  @include justify-content-evenly;
}
/*@mixin flex-order($i) {
  -webkit-order: $i;
  order: $i;
}*/

.flex-center {
  @include flex;
  @include align-items-center;
  @include justify-content-center;
}
.inline-flex-center {
  @include inline-flex;
  @include align-items-center;
  @include justify-content-center;
}
