.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
  .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box;
    &:nth-child(n+2) {
      font-size: 12px;
    }
  }
}


