@use '~@angular/material' as mat;
@import "variables/colors";

.mat-list-item,
.mat-list-option {
  /*font-family: $primarFont;*/

  .mat-list-item-ripple {
    border-radius: 4px;
  }
}

neo-menu-item {
  .mat-list-item {
    width: 100%;
    margin-bottom: 3px;
    @include text-p5;
  }
}
.mat-list-base {
  .mat-list-item,
  .mat-list-option {
    color: rgba($black, 0.87);    
    @include text-p5;

    .mat-line {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      box-sizing: border-box;
      &:nth-child(n+2) {
        font-size: 14px;
      }
    }
    .mat-list-item-content {
      padding: 0 10px!important;
    }
  }
  .mat-list-item {
    height: 44px;
    border-radius: 4px;
  }
  .mat-primary {
    .mat-list-item {
      &.active {
        background-color: mat.get-color-from-palette($mat-primary, 50);
        color: $primaryColor;
      }
      &:hover,
      &:focus {
        & > .mat-list-item:not(.expanded) {
          background-color: mat.get-color-from-palette($mat-primary, 50)!important;
          color: $primaryColor !important;
        }
      }
    }
  }
  .mat-accent {
    .mat-list-item {
      &.active {
        background-color: mat.get-color-from-palette($mat-secondary, 50);
        color: $secondaryColor;
      }
      &:hover,
      &:focus {
        & > .mat-list-item:not(.expanded) {
          background-color: mat.get-color-from-palette($mat-secondary, 50)!important;
          color: $secondaryColor !important;
        }
      }
    }
  }
  .mat-subheader {
    color:rgba($black, 0.54);
    /*font-family: $primeryFont;*/
    font-size: 14px;
    font-weight: 500;
  }
  &[dense] {
    .mat-list-item,
    .mat-list-option {
      @include text-h4;
      
      .mat-line {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        box-sizing: border-box;
        &:nth-child(n+2) {
          @include text-h4;
        }
      }
    }
    .mat-subheader {
      /*font-family: $primaryFont;*/
      @include text-h4;
      font-weight: 500;
    }
  }
}
.plegada {
  .mat-list-base {
    .mat-list-item {
      width: 44px;
      margin-left: -3px;
      margin-right: -2px;
      border-radius: 50%;
    }
  }
}

.mat-list-item-disabled {
  background-color: #eee;
}
.mat-list-option,
.mat-nav-list .mat-list-item,
.mat-action-list .mat-list-item {
  &:hover,
  &:focus {
    background: rgba($black, 0.04);
  }
}
.mat-list-single-selected-option {
  &,
  :hover,
  :focus {
    background: rgba($black, 0.12);
  }
}
