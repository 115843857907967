@import "variables/colors";
@import "mixins/flex.scss";

.mat-icon {
  &.mat-primary {
    color: $primaryColor;
  }
  &.mat-accent {
    color: $secondaryColor;
  }
  &.mat-warn {
    color: $danger;
  }
}

// iconografia (de matrix)
[class^="icon-"], [class*=" icon-"] {
  /*@include icono;*/
  /*display: inline-block;*/
  @include inline-flex;
  @include justify-content-center;
  @include align-items-center;

  &:before {
    display: block;
  }
}
.mat-button .icon-12,
.icon-12 {
  font-size: 12px;
}
.icon-14 {
  font-size: 14px;
}
.icon-16 {
  font-size: 16px;
}
.icon-18 {
  font-size: 18px;
}
.icon-20 {
  font-size: 20px;
}
.icon-22 {
  font-size: 22px;
}
.icon-24 {
  font-size: 24px;
}
.icon-26 {
  font-size: 26px;
}
.icon-28 {
  font-size: 28px;
}
.icon-30 {
  font-size: 30px;
}
.icon-32 {
  font-size: 32px;
}
.icon-60 {
  font-size: 60px;
}
.icon-100 {
  font-size: 100px;
}
.icon-background {
  position: absolute;
}
.icon-background-left-center {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
