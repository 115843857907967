@use '~@angular/material' as mat;
@import "variables/colors";
@import "mixins/flex.scss";

.mat-dialog-container {
  width: 656px;
  max-width: 100%;
  box-shadow: 0px 11px 15px -7px rgba($black, 0.2), 0px 24px 38px 3px rgba($black, 0.14), 0px 9px 46px 8px rgba($black, 0.12);
  background: $white;
  color: $dark-primary-text;

  .header-dialog {
    padding-bottom: 20px;
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: mat.get-color-from-palette($mat-gray, 200);
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .mat-dialog-actions {
      min-height: 30px;
      position: absolute;
      right: 0;
      top: -10px;
      .mat-mini-fab {
        width: 25px;
        height: 25px;
        @include inline-flex;
        @include justify-content-center;
        @include align-items-center;
        .mat-icon,
        [class^="icon-"],
        [class*=" icon-"] {
          width: 18px;
          height: 18px;
        }
      }
      
    }

    .mat-dialog-title {
      margin-bottom: 0;
      /*font-family: $primaryFont;*/
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: normal;
    }
  }

  .mat-dialog-content {
    .mat-menu-item {
      @include flex;
      @include justify-content-start;
      @include align-items-center;

      neo-icono {
        margin-right: 6px;
        @include inline-flex;
        @include align-items-center;
      }
    }

    button {
      &.mat-menu-item-primary {
        color: $primaryColor;
      }
      &.mat-menu-item-accent {
        color: $secondaryColor;
      }
      &.mat-menu-item-warn {
        color: $danger;
      }
    }
  }

  .mat-dialog-content + .mat-dialog-actions {
    position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: mat.get-color-from-palette($mat-gray, 200);
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.dialogoFormulario .mat-dialog-container{
  .mat-dialog-content + .mat-dialog-actions {
    margin-top: 0;
  }
}

@media ($mediaFromTabletV) {
  .mat-dialog-container {
    .header-dialog {
      margin-bottom: 20px;
    }
    .mat-dialog-content + .mat-dialog-actions {
      margin-top: 20px;
    }
    .mat-form-field-wrapper {
      padding-bottom: 20px;
    }
  }
  .dialogoBoton {
    .header-dialog {
      margin-bottom: 0;
    }
  }
}
@media ($mediaMobile) {
  .mat-dialog-container {
    .header-dialog {
      margin-bottom: 20px;
    }
    .mat-dialog-content + .mat-dialog-actions {
      margin-top: 20px;
    }
    .mat-form-field-wrapper {
      padding-bottom: 16px;
    }
  }
  .dialogoFormulario {
    .header-dialog {
      margin-bottom: 14px;
    }
    .mat-dialog-content + .mat-dialog-actions {
      margin-top: 14px;
    }
  }
  .dialogoBoton {
    .header-dialog {
      margin-bottom: 0;
    }
  }

  .acciones-button-block-xs {
    &.mat-dialog-actions {    
      > * {
        width: 100%;
  
        button {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }    
    }
  }  
}
