@import "variables/colors";
@import "mixins/tipography.scss";

.mat-slide-toggle-content {
  /*font-family: $primaryFont;*/
}
.mat-slider-thumb-label-text {
  /*font-family: $primaryFont;*/  
  @include text-h4;
  font-weight: 500;
}
.mat-slider-track-background {
  background-color:rgba($black, 0.26);
}
.mat-primary {
  .mat-slider-track-fill,
  .mat-slider-thumb,
  .mat-slider-thumb-label {
    background-color: $primaryColor;
  }
  .mat-slider-thumb-label-text {
    color: $white;
  }
  .mat-slider-focus-ring {
    background-color: rgba($primaryColor, 0.2);
  }
}
.mat-accent {
  .mat-slider-track-fill,
  .mat-slider-thumb,
  .mat-slider-thumb-label {
    background-color: $secondaryColor;
  }
  .mat-slider-thumb-label-text {
    color: $white;
  }
  .mat-slider-focus-ring {
    background-color:rgba($secondaryColor, 0.2);
  }
}
.mat-warn {
  .mat-slider-track-fill,
  .mat-slider-thumb,
  .mat-slider-thumb-label {
    background-color: $danger;
  }
  .mat-slider-thumb-label-text {
    color: $white;
  }
  .mat-slider-focus-ring {
    background-color: rgba($danger, 0.2);
  }
}

.mat-slider:hover,
.cdk-focused {
  .mat-slider-track-background{
    background-color:rgba($black, 0.38);
  }
}
.mat-slider-disabled {
  .mat-slider-track-background,
  .mat-slider-track-fill,
  .mat-slider-thumb,
  &:hover .mat-slider-track-background{
    background-color: rgba($black, 0.26);
  }
}

.mat-slider-min-value {
  .mat-slider-focus-ring {
    background-color: rgba($black, 0.12);
  }
  &.mat-slider-thumb-label-showing .mat-slider-thumb,
  &.mat-slider-thumb-label-showing .mat-slider-thumb-label {
    background-color: rgba($black, 0.87);
  }
  &.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
  &.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
    background-color: rgba($black, 0.26);
  }
  &:not(.mat-slider-thumb-label-showing) {
   .mat-slider-thumb {
      border-color: rgba($black, 0.26);
      background-color:transparent;
    }
    &:hover,
    &.cdk-focused {
      .mat-slider-thumb {
        border-color: rgba($black, 0.38);
      }
    }
    &:hover.mat-slider-disabled,
    &.cdk-focused.mat-slider-disabled {
      .mat-slider-thumb {
        border-color: rgba($black, 0.26);
      }
    }
  }
}
.mat-slider-has-ticks {
  .mat-slider-wrapper::after {
    border-color: rgba($black, 0.7)
  }
}
.mat-slider-horizontal {
  .mat-slider-ticks {
    background-image: repeating-linear-gradient(to right, rgba($black, 0.7), rgba($black, 0.7) 2px, transparent 0, transparent);
    background-image: -moz-repeating-linear-gradient(0.0001deg, rgba($black, 0.7), rgba($black, 0.7) 2px, transparent 0, transparent);
  }
}
.mat-slider-vertical {
  .mat-slider-ticks {
    background-image:repeating-linear-gradient(to bottom, rgba($black, 0.7), rgba($black, 0.7) 2px, transparent 0, transparent);
  }
}
