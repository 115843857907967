@import "variables/colors";

.mat-card {
  background: $white;
  color: $dark-primary-text;
  /*font-family: $primaryFont;*/
  &:not([class*=mat-elevation-z]) {
    box-shadow: 0px 2px 1px -1px rgba($black, 0.2),0px 1px 1px 0px rgba($black, 0.14),0px 1px 3px 0px rgba($black, 0.12);
  }
  &.mat-card-flat:not([class*=mat-elevation-z]) {
    box-shadow: 0px 0px 0px 0px rgba($black, 0.2),0px 0px 0px 0px rgba($black, 0.14),0px 0px 0px 0px rgba($black, 0.12);
  }
}
.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.mat-card-header {
  .mat-card-title {
    font-size: 20px;
  }
}
.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}
.mat-card-subtitle {
  color: $dark-secondary-text;
}

