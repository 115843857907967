@import "variables/colors";

.mat-expansion-panel {
  max-width: 100vw;
  background: $white;
  color: $dark-primary-text;
  &:not([class*=mat-elevation-z]) {
    box-shadow:0px 3px 1px -2px rgba($black, 0.2),0px 2px 2px 0px rgba($black, 0.14),0px 1px 5px 0px rgba($black, 0.12)
  }
  .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
  .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
  &:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
    background: rgba($black, 0.4);
  }
  .mat-expansion-panel-header {
    height: 48px;
   /* min-height: 50px;*/
    padding: 10px 20px;
    /*font-family: $primaryFont;*/
    font-size: 15px;
    font-weight: 400;
    &.mat-expanded {
      height: 64px
    }
  }
  .mat-panel-title {
    flex: 0 0 15px;
    -webkit-flex: 0 0 15px;
  }
  .mat-panel-description {
    width: 100%;
    align-items: center;
    -webkit-align-items: center;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }
}
.mat-expansion-panel-content {
  /*font-family: $primaryFont;*/
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: normal;
}

@media(hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) {
    .mat-expansion-panel-header:hover {
      background: $white;
    }
  }
}
.mat-expansion-panel-header-title {
  color: $dark-primary-text;
}
.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: $dark-secondary-text;
}
.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba($black, 0.26);
  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    color: inherit;
  }
}
