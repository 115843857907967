@import "variables/colors";

.mat-radio-button {
  
  /*font-family: $fontPrimary;*/

  .mat-radio-container {
    position: relative;

    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .mat-radio-outer-circle {
      width: 18px;
      height: 18px;
      border-width: 1px;
      border-color: $black;
    }

    .mat-radio-inner-circle {
      width: 7px;
      height: 7px;
    }
  }
  
  //por defecto no se introduce ninguna clase de color, si se quiere establecer el negro no poner nada o introducir por ejemplo [color]="'black'" en <mat-radio-button>
  // para primary [color]="'primary'", para secondary [color]="'accent'" y para danger [color]="'warn'" en <mat-radio-button>  
  &:not([class*=mat-primary]):not([class*=mat-accent]):not([class*=mat-warn]),
  &.mat-black {
    &.mat-radio-checked .mat-radio-outer-circle {
      border-color: $black;
    }
    .mat-radio-inner-circle,
    .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    &.mat-radio-checked .mat-radio-persistent-ripple,
    &:active .mat-radio-persistent-ripple {
      background-color: $black;
    }
  }

  &.mat-primary {
    &.mat-radio-checked .mat-radio-outer-circle {
      border-color: $primaryColor;
    }
    .mat-radio-inner-circle,
    .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    &.mat-radio-checked .mat-radio-persistent-ripple,
    &:active .mat-radio-persistent-ripple {
      background-color: $primaryColor;
    }
  }
  &.mat-accent {
    &.mat-radio-checked .mat-radio-outer-circle {
      border-color: $secondaryColor;
    }
    .mat-radio-inner-circle,
    .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    &.mat-radio-checked .mat-radio-persistent-ripple,
    &:active .mat-radio-persistent-ripple {
      background-color: $secondaryColor;
    }
  }
  &.mat-warn {
    &.mat-radio-checked .mat-radio-outer-circle {
      border-color: $danger;
    }
    .mat-radio-inner-circle,
    .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    &.mat-radio-checked .mat-radio-persistent-ripple,
    &:active .mat-radio-persistent-ripple {
      background-color: $danger;
    }
  }
  &.mat-radio-disabled {
    &.mat-radio-checked .mat-radio-outer-circle,
    .mat-radio-outer-circle {
      border-color: rgba($black, 0.38);
    }
    .mat-radio-ripple .mat-ripple-element,
    .mat-radio-inner-circle {
      background-color: rgba($black, 0.38);
    }
    .mat-radio-label-content {
      color: rgba($black, 0.38);
    }
  }
  .mat-ripple-element {
    background-color: $black;
  }
}




