// encabezado
@mixin text-h1 {
  font-size: 28px;
  line-height: 32px;
}
@mixin text-h2 {
  font-size: 20px;
  line-height: 24px;
}
@mixin text-h3 {
  font-size: 16px;
  line-height: 24px;
}
@mixin text-h4 {
  font-size: 12px;
  line-height: 16px;
}
@mixin text-h5 {
  font-size: 11px;
  line-height: 15px;
}
@mixin text-h6 {
  font-size: 9px;
  line-height: 13px;
}

// parrafo
@mixin text-p1 {
  font-size: 112px;
  line-height: 112px;
  letter-spacing: -5.6px;
}
@mixin text-p2 {
  font-size: 56px;
  line-height: 56px;
  letter-spacing: -1.12px;
}
@mixin text-p3 {
  font-size: 45px;
  line-height: 48px;
  letter-spacing: -0.225px;
}
@mixin text-p4 {
  font-size: 34px;
  line-height: 40px;
  letter-spacing: normal;
}
@mixin text-p5 {
  font-size: 16px;
  line-height: 20px;
}
@mixin text-p6 {
  font-size: 14px;
  line-height: 20px;
}
@mixin text-p7 {
  font-size: 12px;
  line-height: 20px;
}

// iconos
@mixin icono {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// sin resultados {
@mixin sin-resultados {
  color: $grey2;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
