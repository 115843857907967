@import "utilities";

@mixin appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

@mixin border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@mixin content-box {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

@mixin center-block {
  margin-left: auto;
  margin-right: auto;
}

@mixin line-clamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin column-count($num) {
  -webkit-column-count: $num;
  -moz-column-count: $num;
  column-count: $num;
}
@mixin column-gap($gap) {
  -webkit-column-gap: $gap;
  -moz-column-gap: $gap;
  column-gap: $gap;
}

@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;
}

@mixin indented-lg($side) {
  @media ($mediaDesktop) {
    margin-#{$side}: -82px;
  }
}
@mixin indented-md($side) {
  @media ($mediaTabletH) {
    margin-#{$side}: -40px;
  }
}
@mixin indented-sm($side) {
  @media ($mediaTabletV) {
    margin-#{$side}: -40px;
  }
}
@mixin indented-xs($side) {
  @media ($mediaMobile) {
    margin-#{$side}: -20px;
  }
}
