@use '~@angular/material' as mat;
@import "variables/colors";

.mat-progress-bar-background {
  fill: mat.get-color-from-palette($mat-primary, 200);
}
.mat-progress-bar-buffer {
  background-color: mat.get-color-from-palette($mat-primary, 200);
}
.mat-progress-bar-fill::after {
  background-color: $primaryColor;
}
.mat-progress-bar{
  &.mat-accent {
    .mat-progress-bar-background {
      fill: mat.get-color-from-palette($mat-secondary, 200);
    }
    .mat-progress-bar-buffer {
      background-color: mat.get-color-from-palette($mat-secondary, 200);
    }
    .mat-progress-bar-fill::after {
      background-color: $secondaryColor;
    }
  }
  &.mat-warn {
    .mat-progress-bar-background {
      fill: mat.get-color-from-palette($mat-danger, 200);
    }
    .mat-progress-bar-buffer {
      background-color: mat.get-color-from-palette($mat-danger, 200);
    }
    .mat-progress-bar-fill::after {
      background-color: $danger;
    }
  }
}
