@import "variables/colors";

.mat-snack-bar-container {
  color: rgba($white, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba($black, 0.2),0px 6px 10px 0px rgba($black, 0.14),0px 1px 18px 0px rgba($black, 0.12);
}
.mat-simple-snackbar {
  /*font-family: $primaryFont;*/
  font-size: 14px;
}
.mat-simple-snackbar-action {
  color: $secondaryColor;
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
