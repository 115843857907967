@import "variables/colors";


.mat-button-toggle {
  color: $dark-disabled-text;
  /*font-family: $primaryFont;*/
  .mat-button-toggle-focus-overlay {
    background-color: rgba($black, 0.12);
  }
}
.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba($black, 0.2),0px 2px 2px 0px rgba($black, 0.14),0px 1px 5px 0px rgba($black, 0.12);
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
  border: solid 1px $dark-dividers;
}

.mat-button-toggle-appearance-standard {
  color: $dark-primary-text;
  background: $white;
  .mat-button-toggle-focus-overlay {
    background-color: $black;
  }
  .mat-button-toggle-label-content {
    line-height: 48px;
  }
}

.mat-button-toggle-group-appearance-standard {
  .mat-button-toggle + .mat-button-toggle {
    border-left: solid 1px $dark-dividers;
  }
  &.mat-button-toggle-vertical {
    .mat-button-toggle + .mat-button-toggle {
      border-left: none;
      border-right: none;
      border-top:solid 1px $dark-dividers;
    }
  }
}
[dir=rtl] {
  .mat-button-toggle-group-appearance-standard {
    .mat-button-toggle + .mat-button-toggle {
      border-left: none;
      border-right: solid 1px $dark-dividers;
    }
  }
}
.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: $dark-secondary-text;
  &.mat-button-toggle-appearance-standard {
    color: $dark-primary-text;
  }
}
.mat-button-toggle-disabled {
  color: $dark-disabled-text;
  background-color: #eee;
  &.mat-button-toggle-appearance-standard {
    background: $white;
  }
  &.mat-button-toggle-checked {
    background-color: #bdbdbd;
  }
}
