@import "variables/variables";

.cdk-visually-hidden {
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  clip: rect($black);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0;
  left: 0;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;

  &:empty {
    display: none;
  }
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  position: absolute;
  pointer-events: auto;
  z-index: 1000;
  display: flex;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  opacity: 0;

  &.cdk-overlay-backdrop-showing {
    opacity: 1
  }
}

.cdk-high-contrast-active {
  .cdk-overlay-backdrop {
    &.cdk-overlay-backdrop-showing {
      opacity: 0.6;
    }
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba($black, 0.32);
}

.cdk-overlay-transparent-backdrop {
  &,
  &.cdk-overlay-backdrop-showing {
    opacity: 0;
  }
}

.cdk-overlay-connected-position-bounding-box {
  min-width: 1px;
  min-height: 1px;
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.cdk-global-scrollblock {
  width: 100%;
  position: fixed;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}

.cdk-text-field-autofill-monitored {
  &:-webkit-autofill {
    animation: cdk-text-field-autofill-start 0s 1ms;
  }

  &:not(:-webkit-autofill) {
    animation: cdk-text-field-autofill-end 0s 1ms;
  }
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  overflow: hidden !important
}

textarea.cdk-textarea-autosize-measuring-firefox {
  height: 0 !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important;
}

@media ($mediaMobile) {
  .cdk-overlay-pane {
    max-width: calc(100% - 20px)!important;
  }
}
