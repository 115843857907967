@use '~@angular/material' as mat;
/*@import "~@angular/neoMaterial/theming";*/
//Importante importar a continuación del theming de neoMaterial
/*@import '~@angular/neoMaterial/neoCore/theming/_palette';*/
/*@import '~@angular/neoMaterial/neoCore/typography/_typography';*/
@import "utilities";

.not-selectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

p, ol ul, li {
  margin-bottom: 20px;
}
li:last-child {
  margin-bottom: 0;
}

.blq-card {
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: $white;
  overflow: hidden;

  // & + .blq-card {
  //   margin-top: 10px;
  // }
}

// color
.text-primary {
  color: $primaryColor;
}
.text-secondary {
  color: $secondaryColor;
}
.text-tertiary {
  color: $tertiaryColor;
}
.text-black {
  color: $black;
}
.text-grey50 {
  color: mat.get-color-from-palette($mat-gray, 50);
}
.text-grey100 {
  color: mat.get-color-from-palette($mat-gray, 100);
}
.text-grey200 {
  color: mat.get-color-from-palette($mat-gray, 200);
}
.text-grey300 {
  color: mat.get-color-from-palette($mat-gray, 300);
}
.text-grey400 {
  color: mat.get-color-from-palette($mat-gray, 400);
}
.text-grey500 {
  color: mat.get-color-from-palette($mat-gray, 500);
}
.text-grey600 {
  color: mat.get-color-from-palette($mat-gray, 600);
}
.text-grey700 {
  color: mat.get-color-from-palette($mat-gray, 700);
}
.text-grey800 {
  color: mat.get-color-from-palette($mat-gray, 800);
}
.text-grey900 {
  color: mat.get-color-from-palette($mat-gray, 900);
}
.text-white {
  color: $white;
}
.text-error {
  color: mat.get-color-from-palette($mat-danger, 900);
}
.text-success {
  color: $success;
}
.text-warning {
  color: $warning;
}
.text-danger {
  color: $danger;
}
.text-grey3 {
  color: $grey3;
}

// estilo
.text-italic {
  font-style: italic;
}

// grosor
.text-light {
  font-weight: 300;
}
.text-regular {
  font-weight: 400;
}
.text-medium {
  font-weight: 500;
}
.text-semibold {
  font-weight: 600;
}
.text-bold {
  font-weight: 700;
}
.text-extrabold {
  font-weight: 800;
}
.text-black {
  font-weight: 900;
}

// alineacion
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

// decoracion
.text-underline {
  text-decoration: underline;
}
.text-linethrough {
  text-decoration: line-through;
}

// transformacion
.text-uppercase {
  text-transform: uppercase;
}


// CUERPOS DE TEXTO

// encabezado
.text-h1 {
  @include text-h1;
}
.text-h2 {
  @include text-h2;
}
.text-h3 {
  @include text-h3;
}
.text-h4 {
  @include text-h4;
}
.text-h5 {
  @include text-h5;
}
.text-h6 {
  @include text-h6;
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
  margin: 0 0 16px;
  @include text-h1;
  font-weight: 700;
  letter-spacing: normal;
}
.mat-h2,
.mat-title,
.mat-typography h2 {
  margin: 0 0 16px;
  @include text-h2;
  font-weight: 400;
  letter-spacing: normal;
}
.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
  margin: 0 0 16px;
  @include text-h3;
  font-weight: 700;
  letter-spacing: normal;
}
.mat-h4,
.mat-subheading-1,
.mat-typography h4 {
  margin: 0 0 16px;
  @include text-h4;
  font-weight: 700;
  letter-spacing: normal;
  text-transform: uppercase;
}
.mat-h5,
.mat-typography h5 {
  margin: 0 0 12px;
  @include text-h5;
  font-weight: 700;
}
.mat-h6,
.mat-typography h6 {
  margin: 0 0 12px;
  @include text-h6;
  font-weight: 700;
}

/*.text-h7 {
  @include text-h7;
}
.text-h8 {
  @include text-h8;
}
.text-h9 {
  @include text-h9;
}
.text-h10 {
  @include text-h10;
}
.text-h11 {
  @include text-h11;
}
.text-h12 {
  @include text-h12;
}
.text-h13 {
  @include text-h13;
}
.text-h14 {
  @include text-h14;
}
.text-h15 {
  @include text-h15;
}*/

/*@media ($mediaFromTabletV) {
  .text-h1-sm {
    @include text-h1;
  }
  .text-h2-sm {
    @include text-h2;
  }
  .text-h3-sm {
    @include text-h3;
  }
  .text-h4-sm {
    @include text-h4;
  }
  .text-h5-sm {
    @include text-h5;
  }
  .text-h6-sm {
    @include text-h6;
  }
  .text-h7-sm {
    @include text-h7;
  }
  .text-h8-sm {
    @include text-h8;
  }
  .text-h9-sm {
    @include text-h9;
  }
  .text-h10-sm {
    @include text-h10;
  }
  .text-h11-sm {
    @include text-h11;
  }
  .text-h12-sm {
    @include text-h12;
  }
  .text-h13-sm {
    @include text-h13;
  }
  .text-h14-sm {
    @include text-h14;
  }
  .text-h15-sm {
    @include text-h15;
  }
}
@media ($mediaFromTabletH) {
  .text-h1-md {
    @include text-h1;
  }
  .text-h2-md {
    @include text-h2;
  }
  .text-h3-md {
    @include text-h3;
  }
  .text-h4-md {
    @include text-h4;
  }
  .text-h5-md {
    @include text-h5;
  }
  .text-h6-md {
    @include text-h6;
  }
  .text-h7-md {
    @include text-h7;
  }
  .text-h8-md {
    @include text-h8;
  }
  .text-h9-md {
    @include text-h9;
  }
  .text-h10-md {
    @include text-h10;
  }
  .text-h11-md {
    @include text-h11;
  }
  .text-h12-md {
    @include text-h12;
  }
  .text-h13-md {
    @include text-h13;
  }
  .text-h14-md {
    @include text-h14;
  }
  .text-h15-md {
    @include text-h15;
  }
}
@media ($mediaDesktop) {
  .text-h1-lg {
    @include text-h1;
  }
  .text-h2-lg {
    @include text-h2;
  }
  .text-h3-lg {
    @include text-h3;
  }
  .text-h4-lg {
    @include text-h4;
  }
  .text-h5-lg {
    @include text-h5;
  }
  .text-h6-lg {
    @include text-h6;
  }
  .text-h7-lg {
    @include text-h7;
  }
  .text-h8-lg {
    @include text-h8;
  }
  .text-h9-lg {
    @include text-h9;
  }
  .text-h10-lg {
    @include text-h10;
  }
  .text-h11-lg {
    @include text-h11;
  }
  .text-h12-lg {
    @include text-h12;
  }
  .text-h13-lg {
    @include text-h13;
  }
  .text-h14-lg {
    @include text-h14;
  }
  .text-h15-lg {
    @include text-h15;
  }
}*/

// parrafo
.text-p1 {
  @include text-p1;
}
.text-p2 {
  @include text-p2;
}
.text-p3 {
  @include text-p3;
}
.text-p4 {
  @include text-p4;
}
.text-p5 {
  @include text-p5;
}
.text-p6 {
  @include text-p6;
}
.text-p7 {
  @include text-p7;
}

.mat-body-strong,
.mat-body-2 {
  @include text-p5;
  font-weight: 500;
}
.mat-body,
.mat-body-1,
.mat-typography {
  @include text-p6;
  font-weight: 400;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}
.mat-small,
.mat-caption {
  letter-spacing: normal;
  @include text-p7;
  font-weight: 400;
}
.mat-display-4,
.mat-typography .mat-display-4 {
  margin:0 0 56px;
  @include text-p1;
  font-weight: 300;
}
.mat-display-3,
.mat-typography .mat-display-3 {
  margin:0 0 64px;
  @include text-p2;
  font-weight: 400;
}
.mat-display-2,
.mat-typography .mat-display-2 {
  margin:0 0 64px;
  @include text-p3;
  font-weight: 400;
}
.mat-display-1,
.mat-typography .mat-display-1 {
  margin: 0 0 64px;
  @include text-p4;
  font-weight: 400;
}

.sin-resultados {
  @include sin-resultados;
}


/*@media ($mediaFromTabletV) {
  .text-p1-sm {
    @include text-p1;
  }
  .text-p2-sm {
    @include text-p2;
  }
  .text-p3-sm {
    @include text-p3;
  }
  .text-p4-sm {
    @include text-p4;
  }
  .text-p5-sm {
    @include text-p5;
  }
}
@media ($mediaFromTabletH) {
  .text-p1-md {
    @include text-p1;
  }
  .text-p2-md {
    @include text-p2;
  }
  .text-p3-md {
    @include text-p3;
  }
  .text-p4-md {
    @include text-p4;
  }
  .text-p5-md {
    @include text-p5;
  }
}
@media ($mediaDesktop) {
  .text-p1-lg {
    @include text-p1;
  }
  .text-p2-lg {
    @include text-p2;
  }
  .text-p3-lg {
    @include text-p3;
  }
  .text-p4-lg {
    @include text-p4;
  }
  .text-p5-lg {
    @include text-p5;
  }
}*/


@media ($mediaDesktop) {
  .blq-card {
    padding: 20px;
  }
}
@media ($mediaToTabletH) {
  .blq-card {
    padding: 20px 10px;
  }
}
@media ($mediaMobile) {
  .blq-card,
  .blq-cont {
    margin-left: -10px;
    margin-right: -10px;
  }

  .row,
  .mat-tab-group {
    .blq-card {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
