@use '~@angular/material' as mat;
@import "variables/colors";

.mat-chip {

  width: 130px;
  // max-width: 100%;
  height: 24px;  
  min-height: 24px;
  padding: 0 4px 0 8px!important;
  border-radius: 4px;
  background-color: mat.get-color-from-palette($mat-gray, 100);
  /*color: $black*/
  font-size: 14px;
  font-weight: 500;
  @include flex;
  @include align-items-center;
  // @include justify-content-between;

  .mat-chip-remove {
    margin-left: auto;
  }

  .text-truncate {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  

  &:not(.mat-chip-disabled) {
    /*&:active {
      box-shadow: 0px 3px 3px -2px rgba($black, 0.2),0px 3px 4px 0px rgba($black, 0.14),0px 1px 8px 0px rgba($black, 0.12);
    }*/
  }
  &.mat-chip-disabled {
    opacity: 0.4;
  }
  &::after {
    background: $black;
  }

  &.mat-primary,
  &.mat-accent,
  &.mat-warn {
    .mat-ripple-element {
      background-color: rgba($white, 0.1);
    }
  }
  &.mat-primary {
    background-color: mat.get-color-from-palette($mat-primary, 100);
    color: $primaryColor;
  }
  &.mat-accent {
    background-color: mat.get-color-from-palette($mat-secondary, 100);
    color: $primaryColor;
  }
  &.mat-warn {
    background-color: mat.get-color-from-palette($mat-danger, 100);
    color: $danger;
  }


/*  &.mat-standard-chip {

  }*/
}

.etiqueta-abreviada-contador .mat-chip,
.chip-abreviado-contador.mat-chip {
  width: 68px;

  .text-truncate {
    max-width: 38px;
  }
}