@import "variables/colors";

.mat-progress-spinner,
.mat-spinner {
  stroke: $primaryColor;
  circle {
    stroke: $primaryColor;
  }
  &.mat-accent {
    circle {
      stroke: $secondaryColor;
    }
  }
  &.mat-warn {
    circle {
      stroke: $danger;
    }
  }
}
