@import "utilities";
@import "variables/variables";
@import "mixins/links";
@import "mixins/flex.scss";


a {
  color: #212429;
  cursor: pointer;
  text-decoration: none;
}
a:focus {
  color: #4d5054;
}
a:hover {
  color: #7a7c7f;
}

.lnk-primary {
  color: #00BC90;
}
.lnk-primary:focus {
  color: #33c9a6;
}
.lnk-primary:hover {
  color: #66d7bc;
}

.lnk-secondary {
  color: #006661;
}
.lnk-secondary:focus {
  color: #338581;
}
.lnk-secondary:hover {
  color: #66a3a0;
}

.lnk-tertiary {
  color: #1AFDD2;
}
.lnk-tertiary:focus {
  color: #48fddb;
}
.lnk-tertiary:hover {
  color: #76fee4;
}

.lnk-black {
  color: #212429;
}
.lnk-black:focus {
  color: #4d5054;
}
.lnk-black:hover {
  color: #7a7c7f;
}

.lnk-grey4 {
  color: #495057;
}
.lnk-grey4:focus {
  color: #6d7379;
}
.lnk-grey4:hover {
  color: #92969a;
}

.lnk-grey3 {
  color: #ACB5BD;
}
.lnk-grey3:focus {
  color: #bdc4ca;
}
.lnk-grey3:hover {
  color: #cdd3d7;
}

.lnk-grey2 {
  color: #DDE2E5;
}
.lnk-grey2:focus {
  color: #e4e8ea;
}
.lnk-grey2:hover {
  color: #ebeeef;
}

.lnk-grey1 {
  color: #F8F9FA;
}
.lnk-grey1:focus {
  color: #f9fafb;
}
.lnk-grey1:hover {
  color: #fbfbfc;
}

.lnk-white {
  color: #FFFFFF;
}
.lnk-white:focus {
  color: white;
}
.lnk-white:hover {
  color: white;
}

.lnk-icon {
  display: inline-flex;
  align-items: center;
}
.lnk-icon > * + * {
  margin-left: 5px;
}

.lnk-icon-circle {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
}
.lnk-icon-circle > * + * {
  margin-left: 5px;
}
.lnk-icon-circle > * + * {
  margin-left: 8px;
}
.lnk-icon-circle [class^=icon-] {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #66d7bc;
  color: #FFFFFF;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn {
  height: 40px;
  padding: 0 20px;
  border: 1px solid;
  border-radius: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn[disabled] {
  opacity: 0.2;
}
.btn > * + * {
  margin-left: 5px;
}
.btn.btn-block {
  width: 100%;
  display: flex;
}
.btn.btn-icon {
  width: 40px;
  padding: 0;
}
.btn.btn-n1 {
  border-color: #00BC90;
  background-color: #00BC90;
  color: #FFFFFF;
}
.btn.btn-n1:focus:after {
  content: "";
  border: 1px solid #FFFFFF;
  border-radius: inherit;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
}
.btn.btn-n1:hover {
  background-color: transparent;
  color: #00BC90;
}
.btn.btn-n1:hover:after {
  display: none;
}
.btn.btn-n2 {
  border-color: #1AFDD2;
  background-color: #1AFDD2;
  color: #FFFFFF;
}
.btn.btn-n2:focus:after {
  content: "";
  border: 1px solid #FFFFFF;
  border-radius: inherit;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
}
.btn.btn-n2:hover {
  background-color: transparent;
  color: #1AFDD2;
}
.btn.btn-n2:hover:after {
  display: none;
}
.btn.btn-primary {
  border-color: #00BC90;
  background-color: #00BC90;
  color: #FFFFFF;
}
.btn.btn-primary:focus:after {
  content: "";
  border: 1px solid #FFFFFF;
  border-radius: inherit;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
}
.btn.btn-primary:hover {
  background-color: transparent;
  color: #00BC90;
}
.btn.btn-primary:hover:after {
  display: none;
}
.btn.btn-primary-outlined {
  border-color: #00BC90;
  background-color: transparent;
  color: #00BC90;
}
.btn.btn-primary-outlined:focus:after {
  content: "";
  border: 1px solid #00BC90;
  border-radius: inherit;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
}
.btn.btn-primary-outlined:hover {
  background-color: #00BC90;
  color: #FFFFFF;
}
.btn.btn-primary-outlined:hover:after {
  display: none;
}
.btn.btn-primary-invisible {
  padding-left: 0;
  padding-right: 0;
  border-color: transparent;
  background-color: transparent;
  color: #00BC90;
}
.btn.btn-primary-invisible:focus, .btn.btn-primary-invisible:hover {
  color: #00BC90;
}
.btn.btn-primary-invisible:focus span, .btn.btn-primary-invisible:hover span {
  text-decoration: underline;
}
.btn.btn-secondary {
  border-color: #006661;
  background-color: #006661;
  color: #FFFFFF;
}
.btn.btn-secondary:focus:after {
  content: "";
  border: 1px solid #FFFFFF;
  border-radius: inherit;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
}
.btn.btn-secondary:hover {
  background-color: transparent;
  color: #006661;
}
.btn.btn-secondary:hover:after {
  display: none;
}
.btn.btn-secondary-outlined {
  border-color: #006661;
  background-color: transparent;
  color: #006661;
}
.btn.btn-secondary-outlined:focus:after {
  content: "";
  border: 1px solid #006661;
  border-radius: inherit;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
}
.btn.btn-secondary-outlined:hover {
  background-color: #006661;
  color: #FFFFFF;
}
.btn.btn-secondary-outlined:hover:after {
  display: none;
}
.btn.btn-secondary-invisible {
  padding-left: 0;
  padding-right: 0;
  border-color: transparent;
  background-color: transparent;
  color: #006661;
}
.btn.btn-secondary-invisible:focus, .btn.btn-secondary-invisible:hover {
  color: #006661;
}
.btn.btn-secondary-invisible:focus span, .btn.btn-secondary-invisible:hover span {
  text-decoration: underline;
}
.btn.btn-tertiary {
  border-color: #1AFDD2;
  background-color: #1AFDD2;
  color: #FFFFFF;
}
.btn.btn-tertiary:focus:after {
  content: "";
  border: 1px solid #FFFFFF;
  border-radius: inherit;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
}
.btn.btn-tertiary:hover {
  background-color: transparent;
  color: #1AFDD2;
}
.btn.btn-tertiary:hover:after {
  display: none;
}
.btn.btn-tertiary-outlined {
  border-color: #1AFDD2;
  background-color: transparent;
  color: #1AFDD2;
}
.btn.btn-tertiary-outlined:focus:after {
  content: "";
  border: 1px solid #1AFDD2;
  border-radius: inherit;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
}
.btn.btn-tertiary-outlined:hover {
  background-color: #1AFDD2;
  color: #FFFFFF;
}
.btn.btn-tertiary-outlined:hover:after {
  display: none;
}
.btn.btn-tertiary-invisible {
  padding-left: 0;
  padding-right: 0;
  border-color: transparent;
  background-color: transparent;
  color: #1AFDD2;
}
.btn.btn-tertiary-invisible:focus, .btn.btn-tertiary-invisible:hover {
  color: #1AFDD2;
}
.btn.btn-tertiary-invisible:focus span, .btn.btn-tertiary-invisible:hover span {
  text-decoration: underline;
}
.btn.btn-black {
  border-color: #212429;
  background-color: #212429;
  color: #FFFFFF;
}
.btn.btn-black:focus:after {
  content: "";
  border: 1px solid #FFFFFF;
  border-radius: inherit;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
}
.btn.btn-black:hover {
  background-color: transparent;
  color: #212429;
}
.btn.btn-black:hover:after {
  display: none;
}
.btn.btn-black-outlined {
  border-color: #212429;
  background-color: transparent;
  color: #212429;
}
.btn.btn-black-outlined:focus:after {
  content: "";
  border: 1px solid #212429;
  border-radius: inherit;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
}
.btn.btn-black-outlined:hover {
  background-color: #212429;
  color: #FFFFFF;
}
.btn.btn-black-outlined:hover:after {
  display: none;
}
.btn.btn-black-invisible {
  padding-left: 0;
  padding-right: 0;
  border-color: transparent;
  background-color: transparent;
  color: #212429;
}
.btn.btn-black-invisible:focus, .btn.btn-black-invisible:hover {
  color: #212429;
}
.btn.btn-black-invisible:focus span, .btn.btn-black-invisible:hover span {
  text-decoration: underline;
}
.btn.btn-white {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
  color: #FFFFFF;
}
.btn.btn-white:focus:after {
  content: "";
  border: 1px solid #FFFFFF;
  border-radius: inherit;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
}
.btn.btn-white:hover {
  background-color: transparent;
  color: #FFFFFF;
}
.btn.btn-white:hover:after {
  display: none;
}
.btn.btn-white-outlined {
  border-color: #FFFFFF;
  background-color: transparent;
  color: #FFFFFF;
}
.btn.btn-white-outlined:focus:after {
  content: "";
  border: 1px solid #FFFFFF;
  border-radius: inherit;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
}
.btn.btn-white-outlined:hover {
  background-color: #FFFFFF;
  color: #FFFFFF;
}
.btn.btn-white-outlined:hover:after {
  display: none;
}
.btn.btn-white-invisible {
  padding-left: 0;
  padding-right: 0;
  border-color: transparent;
  background-color: transparent;
  color: #FFFFFF;
}
.btn.btn-white-invisible:focus, .btn.btn-white-invisible:hover {
  color: #FFFFFF;
}
.btn.btn-white-invisible:focus span, .btn.btn-white-invisible:hover span {
  text-decoration: underline;
}

.btn-lnk {
  padding: 0;
  border: 0;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;
}

.btn-pulse {
  position: relative;
  overflow: visible;
}
.btn-pulse:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: inherit;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
}
.btn-pulse:after {
  display: none;
}

@-webkit-keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(1.333333);
  }
  100% {
    opacity: 0;
    transform: scale(1.333333);
  }
}
@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(1.333333);
  }
  100% {
    opacity: 0;
    transform: scale(1.333333);
  }
}/*# sourceMappingURL=links.css.map */
