@font-face {
  font-family: 'icomoon';
  src:  url('src/assets/icons/icomoon.eot?al80q9');
  src:  url('src/assets/icons/icomoon.eot?al80q9#iefix') format('embedded-opentype'),
    url('src/assets/icons/icomoon.ttf?al80q9') format('truetype'),
    url('src/assets/icons/icomoon.woff?al80q9') format('woff'),
    url('src/assets/icons/icomoon.svg?al80q9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dua-Light-account_circle:before {
  content: "\eebe";
}
.icon-dua-Light-arrow_back_ios:before {
  content: "\eebf";
}
.icon-dua-Light-arrow_drop_down:before {
  content: "\eec0";
}
.icon-dua-Light-atention:before {
  content: "\eec1";
}
.icon-dua-Light-double_arrow:before {
  content: "\eec2";
}
.icon-dua-Light-favorite_border:before {
  content: "\eec3";
}
.icon-dua-Light-local_offer:before {
  content: "\eec4";
}
.icon-dua-Light-local_shipping:before {
  content: "\eec5";
}
.icon-dua-Light-menu:before {
  content: "\eec6";
}
.icon-dua-Light-restore:before {
  content: "\eec7";
}
.icon-dua-Light-search:before {
  content: "\eec8";
}
.icon-dua-Light-shopping_cart:before {
  content: "\eec9";
}
.icon-dua-RRSS-facebook:before {
  content: "\eeca";
}
.icon-dua-RRSS-linkedin:before {
  content: "\eecb";
}
.icon-dua-RRSS-twitter-outlined:before {
  content: "\eecc";
}
.icon-dua-RRSS-whastapp:before {
  content: "\eecd";
}
.icon-ibm-3D_MPR_toggle:before {
  content: "\eeb7";
}
.icon-ibm-3D_cursor:before {
  content: "\eebc";
}
.icon-ibm-3D_cursor-alt:before {
  content: "\eebd";
}
.icon-ibm-3D_curve_auto_colon:before {
  content: "\eebb";
}
.icon-ibm-3D_curve_auto_vessels:before {
  content: "\eeba";
}
.icon-ibm-3D_curve_manual:before {
  content: "\eeb9";
}
.icon-ibm-3D_iCA:before {
  content: "\eeb8";
}
.icon-ibm-3D_print_mesh:before {
  content: "\eeb6";
}
.icon-ibm-3D_software:before {
  content: "\eeb5";
}
.icon-ibm-3rd_party_connected:before {
  content: "\eeb4";
}
.icon-ibm-4K:before {
  content: "\eeb2";
}
.icon-ibm-4K-filled:before {
  content: "\eeb3";
}
.icon-ibm-API:before {
  content: "\ee71";
}
.icon-ibm-CAD:before {
  content: "\ee21";
}
.icon-ibm-CDA:before {
  content: "\edfc";
}
.icon-ibm-CSV:before {
  content: "\ed4c";
}
.icon-ibm-DOC:before {
  content: "\ecdd";
}
.icon-ibm-DVR:before {
  content: "\ecb5";
}
.icon-ibm-EDT_loop:before {
  content: "\eca5";
}
.icon-ibm-GIF:before {
  content: "\ec32";
}
.icon-ibm-H:before {
  content: "\ec21";
}
.icon-ibm-HD:before {
  content: "\ec19";
}
.icon-ibm-HD-filled:before {
  content: "\ec1a";
}
.icon-ibm-HDR:before {
  content: "\ec18";
}
.icon-ibm-HMTL:before {
  content: "\ec0a";
}
.icon-ibm-HTML_reference:before {
  content: "\ec02";
}
.icon-ibm-HTTP:before {
  content: "\ec01";
}
.icon-ibm-ISO:before {
  content: "\ebd1";
}
.icon-ibm-ISO-filled:before {
  content: "\ebd3";
}
.icon-ibm-ISO-outline:before {
  content: "\ebd2";
}
.icon-ibm-JPG:before {
  content: "\ebcb";
}
.icon-ibm-JSON:before {
  content: "\ebc9";
}
.icon-ibm-JSON_reference:before {
  content: "\ebca";
}
.icon-ibm-MOV:before {
  content: "\eb23";
}
.icon-ibm-MP3:before {
  content: "\eb20";
}
.icon-ibm-MP4 .path1:before {
  content: "\eb1c";
  color: rgb(0, 0, 0);
}
.icon-ibm-MP4 .path2:before {
  content: "\eb1d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ibm-MP4 .path3:before {
  content: "\eb1e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-MP4 .path4:before {
  content: "\eb1f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-MPEG:before {
  content: "\eb1b";
}
.icon-ibm-MPG2:before {
  content: "\eb1a";
}
.icon-ibm-PDF:before {
  content: "\ea8c";
}
.icon-ibm-PDF_reference:before {
  content: "\ea8d";
}
.icon-ibm-PNG:before {
  content: "\ea58";
}
.icon-ibm-PPT:before {
  content: "\ea51";
}
.icon-ibm-RAW:before {
  content: "\ea33";
}
.icon-ibm-S:before {
  content: "\e9f8";
}
.icon-ibm-S-alt:before {
  content: "\e9f9";
}
.icon-ibm-SDK:before {
  content: "\e9db";
}
.icon-ibm-SQL:before {
  content: "\e97d";
}
.icon-ibm-SVG:before {
  content: "\e957";
}
.icon-ibm-T:before {
  content: "\e952";
}
.icon-ibm-T-alt:before {
  content: "\e953";
}
.icon-ibm-TIF:before {
  content: "\e902";
}
.icon-ibm-TSV:before {
  content: "\eece";
}
.icon-ibm-TXT:before {
  content: "\eecf";
}
.icon-ibm-TXT_reference:before {
  content: "\eed0";
}
.icon-ibm-U1:before {
  content: "\eed1";
}
.icon-ibm-U2:before {
  content: "\eed2";
}
.icon-ibm-U3:before {
  content: "\eed3";
}
.icon-ibm-USB:before {
  content: "\eed4";
}
.icon-ibm-VPN:before {
  content: "\eed5";
}
.icon-ibm-WMV:before {
  content: "\eed6";
}
.icon-ibm-X:before {
  content: "\eed7";
}
.icon-ibm-XLS:before {
  content: "\eed8";
}
.icon-ibm-XML:before {
  content: "\eed9";
}
.icon-ibm-Y:before {
  content: "\eeda";
}
.icon-ibm-Z:before {
  content: "\eedb";
}
.icon-ibm-ZIP:before {
  content: "\eedc";
}
.icon-ibm-ZIP_reference:before {
  content: "\eedd";
}
.icon-ibm-accesibility:before {
  content: "\eeae";
}
.icon-ibm-accesibility-alt:before {
  content: "\eeaf";
}
.icon-ibm-accesibility_color:before {
  content: "\eeb0";
}
.icon-ibm-accesibility_color-filled:before {
  content: "\eeb1";
}
.icon-ibm-account:before {
  content: "\eead";
}
.icon-ibm-accumulation_ice:before {
  content: "\eeac";
}
.icon-ibm-accumulation_precipitation:before {
  content: "\eeab";
}
.icon-ibm-accumulation_rain:before {
  content: "\eeaa";
}
.icon-ibm-accumulation_snow:before {
  content: "\eea9";
}
.icon-ibm-activity:before {
  content: "\eea8";
}
.icon-ibm-add:before {
  content: "\eea4";
}
.icon-ibm-add-alt:before {
  content: "\eea6";
}
.icon-ibm-add-filled:before {
  content: "\eea5";
}
.icon-ibm-add_comment:before {
  content: "\eea7";
}
.icon-ibm-agriculture_analytics:before {
  content: "\eea3";
}
.icon-ibm-ai_results:before {
  content: "\ee9d";
}
.icon-ibm-ai_results_high:before {
  content: "\eea2";
}
.icon-ibm-ai_results_low:before {
  content: "\eea1";
}
.icon-ibm-ai_results_medium:before {
  content: "\eea0";
}
.icon-ibm-ai_results_urgent:before {
  content: "\ee9f";
}
.icon-ibm-ai_results_very_high:before {
  content: "\ee9e";
}
.icon-ibm-ai_status:before {
  content: "\ee93";
}
.icon-ibm-ai_status_complete:before {
  content: "\ee9c";
}
.icon-ibm-ai_status_failed:before {
  content: "\ee9b";
}
.icon-ibm-ai_status_in_progress:before {
  content: "\ee9a";
}
.icon-ibm-ai_status_queued .path1:before {
  content: "\ee95";
  color: rgb(0, 0, 0);
}
.icon-ibm-ai_status_queued .path2:before {
  content: "\ee96";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-ai_status_queued .path3:before {
  content: "\ee97";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-ai_status_queued .path4:before {
  content: "\ee98";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-ai_status_queued .path5:before {
  content: "\ee99";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-ibm-ai_status_rejected:before {
  content: "\ee94";
}
.icon-ibm-airline-cabin_care:before {
  content: "\ee90";
}
.icon-ibm-airline-cabin_care_alert:before {
  content: "\ee92";
}
.icon-ibm-airline-cabin_care_alt:before {
  content: "\ee91";
}
.icon-ibm-airline-digital_gate:before {
  content: "\ee8f";
}
.icon-ibm-airline-manage_gates:before {
  content: "\ee8e";
}
.icon-ibm-airline-passenger_care:before {
  content: "\ee8d";
}
.icon-ibm-airline-rapid_board:before {
  content: "\ee8c";
}
.icon-ibm-airport_01:before {
  content: "\ee8b";
}
.icon-ibm-airport_02:before {
  content: "\ee8a";
}
.icon-ibm-airport_location:before {
  content: "\ee89";
}
.icon-ibm-alarm:before {
  content: "\ee86";
}
.icon-ibm-alarm_add:before {
  content: "\ee88";
}
.icon-ibm-alarm_subtract:before {
  content: "\ee87";
}
.icon-ibm-align_box_bottom_center:before {
  content: "\ee85";
}
.icon-ibm-align_box_bottom_left:before {
  content: "\ee84";
}
.icon-ibm-align_box_bottom_right:before {
  content: "\ee83";
}
.icon-ibm-align_box_middle_center:before {
  content: "\ee82";
}
.icon-ibm-align_box_middle_left:before {
  content: "\ee81";
}
.icon-ibm-align_box_middle_right:before {
  content: "\ee80";
}
.icon-ibm-align_box_top_center:before {
  content: "\ee7f";
}
.icon-ibm-align_box_top_left:before {
  content: "\ee7e";
}
.icon-ibm-align_box_top_right:before {
  content: "\ee7d";
}
.icon-ibm-align_horizontal_center:before {
  content: "\ee7c";
}
.icon-ibm-align_horizontal_left:before {
  content: "\ee7b";
}
.icon-ibm-align_horizontal_right:before {
  content: "\ee7a";
}
.icon-ibm-align_vertical_bottom:before {
  content: "\ee79";
}
.icon-ibm-align_vertical_center:before {
  content: "\ee78";
}
.icon-ibm-align_vertical_top:before {
  content: "\ee77";
}
.icon-ibm-analytics:before {
  content: "\ee75";
}
.icon-ibm-analytics_reference:before {
  content: "\ee76";
}
.icon-ibm-angle:before {
  content: "\ee74";
}
.icon-ibm-annotation_visibility:before {
  content: "\ee73";
}
.icon-ibm-aperture:before {
  content: "\ee72";
}
.icon-ibm-app:before {
  content: "\ee6f";
}
.icon-ibm-app_connectivity:before {
  content: "\ee70";
}
.icon-ibm-apple:before {
  content: "\ee6e";
}
.icon-ibm-application:before {
  content: "\ee6d";
}
.icon-ibm-apps:before {
  content: "\ee6c";
}
.icon-ibm-archive:before {
  content: "\ee6b";
}
.icon-ibm-area:before {
  content: "\ee69";
}
.icon-ibm-area_custom:before {
  content: "\ee6a";
}
.icon-ibm-arrival:before {
  content: "\ee68";
}
.icon-ibm-arrow_annotation:before {
  content: "\ee67";
}
.icon-ibm-arrow_down:before {
  content: "\ee64";
}
.icon-ibm-arrow_down_left:before {
  content: "\ee66";
}
.icon-ibm-arrow_down_right:before {
  content: "\ee65";
}
.icon-ibm-arrow_left:before {
  content: "\ee63";
}
.icon-ibm-arrow_right:before {
  content: "\ee62";
}
.icon-ibm-arrow_shift_down:before {
  content: "\ee61";
}
.icon-ibm-arrow_up:before {
  content: "\ee5e";
}
.icon-ibm-arrow_up_left:before {
  content: "\ee60";
}
.icon-ibm-arrow_up_right:before {
  content: "\ee5f";
}
.icon-ibm-arrows_horizontal:before {
  content: "\ee5d";
}
.icon-ibm-arrows_vertical:before {
  content: "\ee5c";
}
.icon-ibm-asleep:before {
  content: "\ee5a";
}
.icon-ibm-asleep-filled:before {
  content: "\ee5b";
}
.icon-ibm-asset:before {
  content: "\ee59";
}
.icon-ibm-at:before {
  content: "\ee58";
}
.icon-ibm-attachment:before {
  content: "\ee57";
}
.icon-ibm-audio_console:before {
  content: "\ee56";
}
.icon-ibm-augmented_reality:before {
  content: "\ee55";
}
.icon-ibm-auto_scroll:before {
  content: "\ee54";
}
.icon-ibm-automatic:before {
  content: "\ee53";
}
.icon-ibm-badge:before {
  content: "\ee52";
}
.icon-ibm-baggage_claim:before {
  content: "\ee51";
}
.icon-ibm-bar:before {
  content: "\ee50";
}
.icon-ibm-barcode:before {
  content: "\ee4f";
}
.icon-ibm-barrier:before {
  content: "\ee4e";
}
.icon-ibm-basketball:before {
  content: "\ee4d";
}
.icon-ibm-bat:before {
  content: "\ee4c";
}
.icon-ibm-battery_charging:before {
  content: "\ee4b";
}
.icon-ibm-battery_empty:before {
  content: "\ee4a";
}
.icon-ibm-battery_full:before {
  content: "\ee49";
}
.icon-ibm-battery_half:before {
  content: "\ee48";
}
.icon-ibm-battery_low:before {
  content: "\ee47";
}
.icon-ibm-battery_quarter:before {
  content: "\ee46";
}
.icon-ibm-bee:before {
  content: "\ee44";
}
.icon-ibm-bee_bat:before {
  content: "\ee45";
}
.icon-ibm-bicycle:before {
  content: "\ee43";
}
.icon-ibm-binoculars:before {
  content: "\ee42";
}
.icon-ibm-bloch_sphere:before {
  content: "\ee41";
}
.icon-ibm-blockchain:before {
  content: "\ee40";
}
.icon-ibm-blog:before {
  content: "\ee3f";
}
.icon-ibm-bluetooth:before {
  content: "\ee3d";
}
.icon-ibm-bluetooth_off:before {
  content: "\ee3e";
}
.icon-ibm-book:before {
  content: "\ee3c";
}
.icon-ibm-bookmark:before {
  content: "\ee39";
}
.icon-ibm-bookmark-filled:before {
  content: "\ee3a";
}
.icon-ibm-bookmark_add:before {
  content: "\ee3b";
}
.icon-ibm-boolean:before {
  content: "\ee38";
}
.icon-ibm-border_bottom:before {
  content: "\ee37";
}
.icon-ibm-border_full:before {
  content: "\ee36";
}
.icon-ibm-border_left:before {
  content: "\ee35";
}
.icon-ibm-border_none:before {
  content: "\ee34";
}
.icon-ibm-border_right:before {
  content: "\ee33";
}
.icon-ibm-border_top:before {
  content: "\ee32";
}
.icon-ibm-bot:before {
  content: "\ee31";
}
.icon-ibm-box:before {
  content: "\ee2f";
}
.icon-ibm-box_plot:before {
  content: "\ee30";
}
.icon-ibm-branch:before {
  content: "\ee2e";
}
.icon-ibm-breaking_changes:before {
  content: "\ee2d";
}
.icon-ibm-brightness_contrast:before {
  content: "\ee2c";
}
.icon-ibm-bring_to_front:before {
  content: "\ee2b";
}
.icon-ibm-brush_freehand:before {
  content: "\ee2a";
}
.icon-ibm-brush_polygon:before {
  content: "\ee29";
}
.icon-ibm-building:before {
  content: "\ee25";
}
.icon-ibm-building_insights_1:before {
  content: "\ee28";
}
.icon-ibm-building_insights_2:before {
  content: "\ee27";
}
.icon-ibm-building_insights_3:before {
  content: "\ee26";
}
.icon-ibm-bullhorn:before {
  content: "\ee24";
}
.icon-ibm-buoy:before {
  content: "\ee23";
}
.icon-ibm-bus:before {
  content: "\ee22";
}
.icon-ibm-cU1:before {
  content: "\ed4b";
}
.icon-ibm-cU3:before {
  content: "\ed4a";
}
.icon-ibm-cY:before {
  content: "\ed37";
}
.icon-ibm-cZ:before {
  content: "\ed35";
}
.icon-ibm-cafe:before {
  content: "\ee20";
}
.icon-ibm-calculation:before {
  content: "\ee1e";
}
.icon-ibm-calculation-alt:before {
  content: "\ee1f";
}
.icon-ibm-calculator:before {
  content: "\ee1c";
}
.icon-ibm-calculator_check:before {
  content: "\ee1d";
}
.icon-ibm-calendar:before {
  content: "\ee18";
}
.icon-ibm-calendar_heat_map:before {
  content: "\ee1b";
}
.icon-ibm-calendar_settings:before {
  content: "\ee1a";
}
.icon-ibm-calendar_tools:before {
  content: "\ee19";
}
.icon-ibm-calibrate:before {
  content: "\ee17";
}
.icon-ibm-camera:before {
  content: "\ee15";
}
.icon-ibm-camera_action:before {
  content: "\ee16";
}
.icon-ibm-campsite:before {
  content: "\ee14";
}
.icon-ibm-car:before {
  content: "\ee12";
}
.icon-ibm-car_front:before {
  content: "\ee13";
}
.icon-ibm-carbon:before {
  content: "\ee11";
}
.icon-ibm-caret_down:before {
  content: "\ee0f";
}
.icon-ibm-caret_down-1:before {
  content: "\ee10";
}
.icon-ibm-caret_left:before {
  content: "\ee0e";
}
.icon-ibm-caret_right:before {
  content: "\ee0d";
}
.icon-ibm-caret_sort:before {
  content: "\ee0c";
}
.icon-ibm-caret_up:before {
  content: "\ee0a";
}
.icon-ibm-caret_up-1:before {
  content: "\ee0b";
}
.icon-ibm-carousel_horizontal:before {
  content: "\ee09";
}
.icon-ibm-carousel_vertical:before {
  content: "\ee08";
}
.icon-ibm-catalog:before {
  content: "\ee07";
}
.icon-ibm-categories:before {
  content: "\ee06";
}
.icon-ibm-category:before {
  content: "\ee01";
}
.icon-ibm-category_and:before {
  content: "\ee05";
}
.icon-ibm-category_app:before {
  content: "\ee04";
}
.icon-ibm-category_new:before {
  content: "\ee02";
}
.icon-ibm-category_new_each:before {
  content: "\ee03";
}
.icon-ibm-ccX:before {
  content: "\ee00";
}
.icon-ibm-cd_archive:before {
  content: "\edff";
}
.icon-ibm-cd_create_archive:before {
  content: "\edfe";
}
.icon-ibm-cd_create_exchange:before {
  content: "\edfd";
}
.icon-ibm-center_circle:before {
  content: "\edfb";
}
.icon-ibm-center_square:before {
  content: "\edfa";
}
.icon-ibm-center_to_fit:before {
  content: "\edf9";
}
.icon-ibm-certificate:before {
  content: "\edf7";
}
.icon-ibm-certificate_check:before {
  content: "\edf8";
}
.icon-ibm-change_catalog:before {
  content: "\edf6";
}
.icon-ibm-char_bubble_packed:before {
  content: "\edf5";
}
.icon-ibm-character_patterns:before {
  content: "\edf4";
}
.icon-ibm-charging_station:before {
  content: "\edf2";
}
.icon-ibm-charging_station-filled:before {
  content: "\edf3";
}
.icon-ibm-chart_3D:before {
  content: "\edf1";
}
.icon-ibm-chart_area:before {
  content: "\edee";
}
.icon-ibm-chart_area_smooth:before {
  content: "\edf0";
}
.icon-ibm-chart_area_stepper:before {
  content: "\edef";
}
.icon-ibm-chart_average:before {
  content: "\eded";
}
.icon-ibm-chart_bar:before {
  content: "\ede8";
}
.icon-ibm-chart_bar_floating:before {
  content: "\edec";
}
.icon-ibm-chart_bar_overlay:before {
  content: "\edeb";
}
.icon-ibm-chart_bar_stacked:before {
  content: "\edea";
}
.icon-ibm-chart_bar_target:before {
  content: "\ede9";
}
.icon-ibm-chart_bubble:before {
  content: "\ede7";
}
.icon-ibm-chart_bullet:before {
  content: "\ede6";
}
.icon-ibm-chart_candlestick:before {
  content: "\ede5";
}
.icon-ibm-chart_cluster_bar:before {
  content: "\ede4";
}
.icon-ibm-chart_column:before {
  content: "\ede1";
}
.icon-ibm-chart_column_floating:before {
  content: "\ede3";
}
.icon-ibm-chart_column_target:before {
  content: "\ede2";
}
.icon-ibm-chart_combo:before {
  content: "\eddf";
}
.icon-ibm-chart_combo_stacked:before {
  content: "\ede0";
}
.icon-ibm-chart_custom:before {
  content: "\edde";
}
.icon-ibm-chart_error_bar:before {
  content: "\eddc";
}
.icon-ibm-chart_error_bar-alt:before {
  content: "\eddd";
}
.icon-ibm-chart_evaluation:before {
  content: "\eddb";
}
.icon-ibm-chart_high_low:before {
  content: "\edda";
}
.icon-ibm-chart_histogram:before {
  content: "\edd9";
}
.icon-ibm-chart_line:before {
  content: "\edd6";
}
.icon-ibm-chart_line_data:before {
  content: "\edd8";
}
.icon-ibm-chart_line_smooth:before {
  content: "\edd7";
}
.icon-ibm-chart_marimekko:before {
  content: "\edd5";
}
.icon-ibm-chart_maximum:before {
  content: "\edd4";
}
.icon-ibm-chart_median:before {
  content: "\edd3";
}
.icon-ibm-chart_minimum:before {
  content: "\edd2";
}
.icon-ibm-chart_multi_line:before {
  content: "\edd1";
}
.icon-ibm-chart_multitype:before {
  content: "\edd0";
}
.icon-ibm-chart_network:before {
  content: "\edcf";
}
.icon-ibm-chart_parallel:before {
  content: "\edce";
}
.icon-ibm-chart_pie:before {
  content: "\edcd";
}
.icon-ibm-chart_point:before {
  content: "\edcc";
}
.icon-ibm-chart_population:before {
  content: "\edcb";
}
.icon-ibm-chart_radar:before {
  content: "\edca";
}
.icon-ibm-chart_radial:before {
  content: "\edc9";
}
.icon-ibm-chart_relationship:before {
  content: "\edc8";
}
.icon-ibm-chart_ring:before {
  content: "\edc7";
}
.icon-ibm-chart_river:before {
  content: "\edc6";
}
.icon-ibm-chart_rose:before {
  content: "\edc5";
}
.icon-ibm-chart_scatter:before {
  content: "\edc4";
}
.icon-ibm-chart_spiral:before {
  content: "\edc3";
}
.icon-ibm-chart_stacked:before {
  content: "\edc2";
}
.icon-ibm-chart_stepper:before {
  content: "\edc1";
}
.icon-ibm-chart_sunburst:before {
  content: "\edc0";
}
.icon-ibm-chart_t-sne:before {
  content: "\edbf";
}
.icon-ibm-chart_treemap:before {
  content: "\edbe";
}
.icon-ibm-chart_venn_diagram:before {
  content: "\edbd";
}
.icon-ibm-chart_violin_plot:before {
  content: "\edbc";
}
.icon-ibm-chart_waterfall:before {
  content: "\edbb";
}
.icon-ibm-chart_win_loss:before {
  content: "\edba";
}
.icon-ibm-chat:before {
  content: "\edb7";
}
.icon-ibm-chat_bot:before {
  content: "\edb9";
}
.icon-ibm-chat_lunch:before {
  content: "\edb8";
}
.icon-ibm-checkbox:before {
  content: "\edb2";
}
.icon-ibm-checkbox_checked:before {
  content: "\edb5";
}
.icon-ibm-checkbox_checked-filled:before {
  content: "\edb6";
}
.icon-ibm-checkbox_inderterminate-filled:before {
  content: "\edb4";
}
.icon-ibm-checkbox_indeterminate:before {
  content: "\edb3";
}
.icon-ibm-checkmark:before {
  content: "\edab";
}
.icon-ibm-checkmark-filled:before {
  content: "\edad";
}
.icon-ibm-checkmark-outline:before {
  content: "\edac";
}
.icon-ibm-checkmark_filled_error:before {
  content: "\edb1";
}
.icon-ibm-checkmark_filled_warning:before {
  content: "\edb0";
}
.icon-ibm-checkmark_outline_error:before {
  content: "\edaf";
}
.icon-ibm-checkmark_outline_warning:before {
  content: "\edae";
}
.icon-ibm-chemistry:before {
  content: "\eda9";
}
.icon-ibm-chemistry_reference:before {
  content: "\edaa";
}
.icon-ibm-chevron_down:before {
  content: "\eda8";
}
.icon-ibm-chevron_left:before {
  content: "\eda7";
}
.icon-ibm-chevron_mini:before {
  content: "\eda6";
}
.icon-ibm-chevron_right:before {
  content: "\eda5";
}
.icon-ibm-chevron_short_down:before {
  content: "\eda4";
}
.icon-ibm-chevron_short_up:before {
  content: "\eda3";
}
.icon-ibm-chevron_sort:before {
  content: "\eda2";
}
.icon-ibm-chevron_up:before {
  content: "\eda1";
}
.icon-ibm-chip:before {
  content: "\eda0";
}
.icon-ibm-choices:before {
  content: "\ed9f";
}
.icon-ibm-choropleth_map:before {
  content: "\ed9e";
}
.icon-ibm-circle-filled:before {
  content: "\ed9a";
}
.icon-ibm-circle_dash:before {
  content: "\ed9d";
}
.icon-ibm-circle_measurement:before {
  content: "\ed9c";
}
.icon-ibm-circle_packaging:before {
  content: "\ed9b";
}
.icon-ibm-circuit_composer:before {
  content: "\ed99";
}
.icon-ibm-classification:before {
  content: "\ed98";
}
.icon-ibm-classifier_language:before {
  content: "\ed97";
}
.icon-ibm-clean:before {
  content: "\ed96";
}
.icon-ibm-close:before {
  content: "\ed93";
}
.icon-ibm-close-filled:before {
  content: "\ed95";
}
.icon-ibm-close-outline:before {
  content: "\ed94";
}
.icon-ibm-closed_caption-alt:before {
  content: "\ed92";
}
.icon-ibm-closed_caption-filled:before {
  content: "\ed91";
}
.icon-ibm-closed_caption-outline:before {
  content: "\ed90";
}
.icon-ibm-cloud:before {
  content: "\ed85";
}
.icon-ibm-cloud_data_ops:before {
  content: "\ed8f";
}
.icon-ibm-cloud_download:before {
  content: "\ed8e";
}
.icon-ibm-cloud_foundry_1:before {
  content: "\ed8d";
}
.icon-ibm-cloud_foundry_2:before {
  content: "\ed8c";
}
.icon-ibm-cloud_registry:before {
  content: "\ed8b";
}
.icon-ibm-cloud_satellite .path1:before {
  content: "\ed87";
  color: rgb(0, 0, 0);
}
.icon-ibm-cloud_satellite .path2:before {
  content: "\ed88";
  margin-left: -1em;
  color: rgb(22, 22, 22);
}
.icon-ibm-cloud_satellite .path3:before {
  content: "\ed89";
  margin-left: -1em;
  color: rgb(22, 22, 22);
}
.icon-ibm-cloud_satellite .path4:before {
  content: "\ed8a";
  margin-left: -1em;
  color: rgb(22, 22, 22);
}
.icon-ibm-cloud_upload:before {
  content: "\ed86";
}
.icon-ibm-cloudy:before {
  content: "\ed84";
}
.icon-ibm-cobb_angle:before {
  content: "\ed83";
}
.icon-ibm-code:before {
  content: "\ed80";
}
.icon-ibm-code_hide:before {
  content: "\ed82";
}
.icon-ibm-code_reference:before {
  content: "\ed81";
}
.icon-ibm-cognitive:before {
  content: "\ed7f";
}
.icon-ibm-collaborate:before {
  content: "\ed7e";
}
.icon-ibm-collapse_all:before {
  content: "\ed7d";
}
.icon-ibm-collapse_categories:before {
  content: "\ed7c";
}
.icon-ibm-color_palette:before {
  content: "\ed7b";
}
.icon-ibm-color_switch:before {
  content: "\ed7a";
}
.icon-ibm-column:before {
  content: "\ed76";
}
.icon-ibm-column_delete:before {
  content: "\ed79";
}
.icon-ibm-column_dependency:before {
  content: "\ed78";
}
.icon-ibm-column_insert:before {
  content: "\ed77";
}
.icon-ibm-commit:before {
  content: "\ed75";
}
.icon-ibm-compare:before {
  content: "\ed74";
}
.icon-ibm-compass:before {
  content: "\ed73";
}
.icon-ibm-composer_edit:before {
  content: "\ed72";
}
.icon-ibm-concept:before {
  content: "\ed71";
}
.icon-ibm-condition_point:before {
  content: "\ed70";
}
.icon-ibm-condition_wait_point:before {
  content: "\ed6f";
}
.icon-ibm-conect_source:before {
  content: "\ed6e";
}
.icon-ibm-connect:before {
  content: "\ed6b";
}
.icon-ibm-connect_recursive:before {
  content: "\ed6d";
}
.icon-ibm-connect_target:before {
  content: "\ed6c";
}
.icon-ibm-connection_receive:before {
  content: "\ed6a";
}
.icon-ibm-connection_send:before {
  content: "\ed69";
}
.icon-ibm-connection_signal_off:before {
  content: "\ed68";
}
.icon-ibm-connection_signed:before {
  content: "\ed67";
}
.icon-ibm-connection_two_way:before {
  content: "\ed66";
}
.icon-ibm-construction:before {
  content: "\ed65";
}
.icon-ibm-container_software:before {
  content: "\ed64";
}
.icon-ibm-content_view:before {
  content: "\ed63";
}
.icon-ibm-contour_finding:before {
  content: "\ed62";
}
.icon-ibm-contrast:before {
  content: "\ed61";
}
.icon-ibm-convert_to_cloud:before {
  content: "\ed60";
}
.icon-ibm-copy:before {
  content: "\ed5d";
}
.icon-ibm-copy_file:before {
  content: "\ed5f";
}
.icon-ibm-copy_link:before {
  content: "\ed5e";
}
.icon-ibm-corn:before {
  content: "\ed5c";
}
.icon-ibm-corner:before {
  content: "\ed5b";
}
.icon-ibm-coronavirus:before {
  content: "\ed5a";
}
.icon-ibm-cought:before {
  content: "\ed59";
}
.icon-ibm-coul_ceiling:before {
  content: "\ed58";
}
.icon-ibm-course:before {
  content: "\ed57";
}
.icon-ibm-covariate:before {
  content: "\ed56";
}
.icon-ibm-credentials:before {
  content: "\ed55";
}
.icon-ibm-crop:before {
  content: "\ed52";
}
.icon-ibm-crop_growth:before {
  content: "\ed54";
}
.icon-ibm-crop_health:before {
  content: "\ed53";
}
.icon-ibm-cross_reference:before {
  content: "\ed51";
}
.icon-ibm-cross_tab:before {
  content: "\ed50";
}
.icon-ibm-crossroad:before {
  content: "\ed4f";
}
.icon-ibm-crowd_report:before {
  content: "\ed4d";
}
.icon-ibm-crowd_report-filled:before {
  content: "\ed4e";
}
.icon-ibm-cube:before {
  content: "\ed48";
}
.icon-ibm-cube_view:before {
  content: "\ed49";
}
.icon-ibm-currency:before {
  content: "\ed3d";
}
.icon-ibm-currency_baht:before {
  content: "\ed47";
}
.icon-ibm-currency_dollar:before {
  content: "\ed46";
}
.icon-ibm-currency_euro:before {
  content: "\ed45";
}
.icon-ibm-currency_lira:before {
  content: "\ed44";
}
.icon-ibm-currency_pound:before {
  content: "\ed43";
}
.icon-ibm-currency_ruble:before {
  content: "\ed42";
}
.icon-ibm-currency_rupee:before {
  content: "\ed41";
}
.icon-ibm-currency_shekel:before {
  content: "\ed40";
}
.icon-ibm-currency_won:before {
  content: "\ed3f";
}
.icon-ibm-currency_yen:before {
  content: "\ed3e";
}
.icon-ibm-cursor_1:before {
  content: "\ed3c";
}
.icon-ibm-cursor_2:before {
  content: "\ed3b";
}
.icon-ibm-cut:before {
  content: "\ed38";
}
.icon-ibm-cut_in_half:before {
  content: "\ed3a";
}
.icon-ibm-cut_out:before {
  content: "\ed39";
}
.icon-ibm-cyclist:before {
  content: "\ed36";
}
.icon-ibm-dashboard-1:before {
  content: "\ed34";
}
.icon-ibm-dashboard_referente:before {
  content: "\ed33";
}
.icon-ibm-data_1:before {
  content: "\ed32";
}
.icon-ibm-data_2:before {
  content: "\ed31";
}
.icon-ibm-data_base:before {
  content: "\ed2f";
}
.icon-ibm-data_base-alt:before {
  content: "\ed30";
}
.icon-ibm-data_bin:before {
  content: "\ed2e";
}
.icon-ibm-data_check:before {
  content: "\ed2d";
}
.icon-ibm-data_class:before {
  content: "\ed2c";
}
.icon-ibm-data_collection:before {
  content: "\ed2b";
}
.icon-ibm-data_connected:before {
  content: "\ed2a";
}
.icon-ibm-data_error:before {
  content: "\ed29";
}
.icon-ibm-data_format:before {
  content: "\ed28";
}
.icon-ibm-data_player:before {
  content: "\ed27";
}
.icon-ibm-data_reference:before {
  content: "\ed26";
}
.icon-ibm-data_refinery:before {
  content: "\ed24";
}
.icon-ibm-data_refinery_reference:before {
  content: "\ed25";
}
.icon-ibm-data_set:before {
  content: "\ed23";
}
.icon-ibm-data_share:before {
  content: "\ed22";
}
.icon-ibm-data_structured:before {
  content: "\ed21";
}
.icon-ibm-data_table:before {
  content: "\ed1f";
}
.icon-ibm-data_table_reference:before {
  content: "\ed20";
}
.icon-ibm-data_unstructured:before {
  content: "\ed1e";
}
.icon-ibm-data_view:before {
  content: "\ed1d";
}
.icon-ibm-data_vis_1:before {
  content: "\ed1c";
}
.icon-ibm-data_vis_2:before {
  content: "\ed1b";
}
.icon-ibm-data_vis_3:before {
  content: "\ed1a";
}
.icon-ibm-data_vis_4:before {
  content: "\ed19";
}
.icon-ibm-datastore:before {
  content: "\ed18";
}
.icon-ibm-debug:before {
  content: "\ed17";
}
.icon-ibm-decision_tree:before {
  content: "\ed16";
}
.icon-ibm-delivery_add:before {
  content: "\ed15";
}
.icon-ibm-delivery_truck:before {
  content: "\ed14";
}
.icon-ibm-delivey:before {
  content: "\ed12";
}
.icon-ibm-delivey_parcel:before {
  content: "\ed13";
}
.icon-ibm-denominate:before {
  content: "\ed11";
}
.icon-ibm-departure:before {
  content: "\ed10";
}
.icon-ibm-deploy:before {
  content: "\ed0e";
}
.icon-ibm-deploy_rules:before {
  content: "\ed0f";
}
.icon-ibm-deployment_pattern:before {
  content: "\ed0d";
}
.icon-ibm-deployment_policy:before {
  content: "\ed0c";
}
.icon-ibm-desk_adjustable:before {
  content: "\ed0b";
}
.icon-ibm-development:before {
  content: "\ed0a";
}
.icon-ibm-devices:before {
  content: "\ed09";
}
.icon-ibm-diagram:before {
  content: "\ed07";
}
.icon-ibm-diagram_reference:before {
  content: "\ed08";
}
.icon-ibm-dicom_6000:before {
  content: "\ed06";
}
.icon-ibm-direction-bear_right_01:before {
  content: "\ed02";
}
.icon-ibm-direction-bear_right_01-filled:before {
  content: "\ed03";
}
.icon-ibm-direction-bear_right_02:before {
  content: "\ed00";
}
.icon-ibm-direction-bear_right_02-filled:before {
  content: "\ed01";
}
.icon-ibm-direction-curve:before {
  content: "\ecfe";
}
.icon-ibm-direction-curve-filled:before {
  content: "\ecff";
}
.icon-ibm-direction-fork:before {
  content: "\ecfc";
}
.icon-ibm-direction-fork-filled:before {
  content: "\ecfd";
}
.icon-ibm-direction-loop_left:before {
  content: "\ecfa";
}
.icon-ibm-direction-loop_left-filled:before {
  content: "\ecfb";
}
.icon-ibm-direction-loop_right:before {
  content: "\ecf8";
}
.icon-ibm-direction-loop_right-filled:before {
  content: "\ecf9";
}
.icon-ibm-direction-merge:before {
  content: "\ecf6";
}
.icon-ibm-direction-merge-filled:before {
  content: "\ecf7";
}
.icon-ibm-direction-right_01:before {
  content: "\ecf2";
}
.icon-ibm-direction-right_01-1:before {
  content: "\ecf5";
}
.icon-ibm-direction-right_01-filled:before {
  content: "\ecf3";
}
.icon-ibm-direction-right_01-filled-1:before {
  content: "\ecf4";
}
.icon-ibm-direction-rotary_first_right:before {
  content: "\ecf0";
}
.icon-ibm-direction-rotary_first_right-filled:before {
  content: "\ecf1";
}
.icon-ibm-direction-rotary_right:before {
  content: "\ecee";
}
.icon-ibm-direction-rotary_right-filled:before {
  content: "\ecef";
}
.icon-ibm-direction-rotary_straight:before {
  content: "\ecec";
}
.icon-ibm-direction-rotary_straight-filled:before {
  content: "\eced";
}
.icon-ibm-direction-sharp_turn:before {
  content: "\ecea";
}
.icon-ibm-direction-sharp_turn-filled:before {
  content: "\eceb";
}
.icon-ibm-direction-straight:before {
  content: "\ece6";
}
.icon-ibm-direction-straight-filled:before {
  content: "\ece9";
}
.icon-ibm-direction-straight-right:before {
  content: "\ece7";
}
.icon-ibm-direction-straight-right-filled:before {
  content: "\ece8";
}
.icon-ibm-direction_U_turn:before {
  content: "\ed04";
}
.icon-ibm-direction_U_turn-filled:before {
  content: "\ed05";
}
.icon-ibm-discom_overlay:before {
  content: "\ece5";
}
.icon-ibm-distribute_horizontal_center:before {
  content: "\ece4";
}
.icon-ibm-distribute_horizontal_left:before {
  content: "\ece3";
}
.icon-ibm-distribute_horizontal_right:before {
  content: "\ece2";
}
.icon-ibm-distribute_vertical_bottom:before {
  content: "\ece1";
}
.icon-ibm-distribute_vertical_center:before {
  content: "\ece0";
}
.icon-ibm-distribute_vertical_top:before {
  content: "\ecdf";
}
.icon-ibm-dna:before {
  content: "\ecde";
}
.icon-ibm-document:before {
  content: "\ecc9";
}
.icon-ibm-document_add:before {
  content: "\ecdc";
}
.icon-ibm-document_attachment:before {
  content: "\ecdb";
}
.icon-ibm-document_audio:before {
  content: "\ecda";
}
.icon-ibm-document_blank:before {
  content: "\ecd9";
}
.icon-ibm-document_download:before {
  content: "\ecd8";
}
.icon-ibm-document_export:before {
  content: "\ecd7";
}
.icon-ibm-document_horizontal:before {
  content: "\ecd6";
}
.icon-ibm-document_import:before {
  content: "\ecd5";
}
.icon-ibm-document_pdf:before {
  content: "\ecd4";
}
.icon-ibm-document_sentiment:before {
  content: "\ecd3";
}
.icon-ibm-document_sketch:before {
  content: "\ecd2";
}
.icon-ibm-document_subtract:before {
  content: "\ecd1";
}
.icon-ibm-document_tasks:before {
  content: "\ecd0";
}
.icon-ibm-document_unkown:before {
  content: "\eccf";
}
.icon-ibm-document_vertical:before {
  content: "\ecce";
}
.icon-ibm-document_video:before {
  content: "\eccd";
}
.icon-ibm-document_view:before {
  content: "\eccc";
}
.icon-ibm-document_word_processor:before {
  content: "\ecca";
}
.icon-ibm-document_word_processor_reference:before {
  content: "\eccb";
}
.icon-ibm-donwload_study:before {
  content: "\ecc8";
}
.icon-ibm-dot_mark:before {
  content: "\ecc7";
}
.icon-ibm-double_integer:before {
  content: "\ecc6";
}
.icon-ibm-down_to_bottom:before {
  content: "\ecc5";
}
.icon-ibm-download:before {
  content: "\ecc4";
}
.icon-ibm-drag_horizontal:before {
  content: "\ecc3";
}
.icon-ibm-drag_vertical:before {
  content: "\ecc2";
}
.icon-ibm-draggable:before {
  content: "\ecc1";
}
.icon-ibm-draw:before {
  content: "\ecc0";
}
.icon-ibm-drew_point:before {
  content: "\ecbe";
}
.icon-ibm-drew_point-filled:before {
  content: "\ecbf";
}
.icon-ibm-drill_back:before {
  content: "\ecbd";
}
.icon-ibm-drill_down:before {
  content: "\ecbc";
}
.icon-ibm-drill_through:before {
  content: "\ecbb";
}
.icon-ibm-driver_analysis:before {
  content: "\ecba";
}
.icon-ibm-drone:before {
  content: "\ecb9";
}
.icon-ibm-drop_photo:before {
  content: "\ecb7";
}
.icon-ibm-drop_photo-filled:before {
  content: "\ecb8";
}
.icon-ibm-drought:before {
  content: "\ecb6";
}
.icon-ibm-earth:before {
  content: "\ecb1";
}
.icon-ibm-earth-filled:before {
  content: "\ecb2";
}
.icon-ibm-earth_southeast_asia:before {
  content: "\ecb3";
}
.icon-ibm-earth_southeast_asia-filled:before {
  content: "\ecb4";
}
.icon-ibm-earthquake:before {
  content: "\ecb0";
}
.icon-ibm-edge_cluster:before {
  content: "\ecaf";
}
.icon-ibm-edge_device:before {
  content: "\ecae";
}
.icon-ibm-edge_enhancement:before {
  content: "\ecaa";
}
.icon-ibm-edge_enhancement_01:before {
  content: "\ecad";
}
.icon-ibm-edge_enhancement_02:before {
  content: "\ecac";
}
.icon-ibm-edge_enhancement_03:before {
  content: "\ecab";
}
.icon-ibm-edge_node:before {
  content: "\eca9";
}
.icon-ibm-edge_service:before {
  content: "\eca8";
}
.icon-ibm-edit:before {
  content: "\eca6";
}
.icon-ibm-edit_off:before {
  content: "\eca7";
}
.icon-ibm-education:before {
  content: "\eca4";
}
.icon-ibm-email:before {
  content: "\eca2";
}
.icon-ibm-email_new:before {
  content: "\eca3";
}
.icon-ibm-enterprise:before {
  content: "\eca1";
}
.icon-ibm-equalizer:before {
  content: "\eca0";
}
.icon-ibm-erase:before {
  content: "\ec9e";
}
.icon-ibm-erase_3D:before {
  content: "\ec9f";
}
.icon-ibm-error:before {
  content: "\ec9b";
}
.icon-ibm-error-filled:before {
  content: "\ec9d";
}
.icon-ibm-error-outline:before {
  content: "\ec9c";
}
.icon-ibm-event:before {
  content: "\ec99";
}
.icon-ibm-event_schedule:before {
  content: "\ec9a";
}
.icon-ibm-events:before {
  content: "\ec97";
}
.icon-ibm-events-alt:before {
  content: "\ec98";
}
.icon-ibm-exam_mode:before {
  content: "\ec96";
}
.icon-ibm-exit:before {
  content: "\ec95";
}
.icon-ibm-expand_all:before {
  content: "\ec94";
}
.icon-ibm-expand_categories:before {
  content: "\ec93";
}
.icon-ibm-explore:before {
  content: "\ec92";
}
.icon-ibm-export:before {
  content: "\ec91";
}
.icon-ibm-eye_dropper:before {
  content: "\ec90";
}
.icon-ibm-face_activated:before {
  content: "\ec8d";
}
.icon-ibm-face_activated-filled:before {
  content: "\ec8e";
}
.icon-ibm-face_activated_add:before {
  content: "\ec8f";
}
.icon-ibm-face_add:before {
  content: "\ec8c";
}
.icon-ibm-face_cool:before {
  content: "\ec8b";
}
.icon-ibm-face_dissatisfied:before {
  content: "\ec89";
}
.icon-ibm-face_dissatisfied-filled:before {
  content: "\ec8a";
}
.icon-ibm-face_dizzy:before {
  content: "\ec87";
}
.icon-ibm-face_dizzy-filled:before {
  content: "\ec88";
}
.icon-ibm-face_mask:before {
  content: "\ec86";
}
.icon-ibm-face_neutral:before {
  content: "\ec84";
}
.icon-ibm-face_neutral-filled:before {
  content: "\ec85";
}
.icon-ibm-face_pending:before {
  content: "\ec82";
}
.icon-ibm-face_pending-filled:before {
  content: "\ec83";
}
.icon-ibm-face_satisfied-filled:before {
  content: "\ec80";
}
.icon-ibm-face_satisfied-filled-1:before {
  content: "\ec81";
}
.icon-ibm-face_wink:before {
  content: "\ec7e";
}
.icon-ibm-face_wink-filled:before {
  content: "\ec7f";
}
.icon-ibm-factor:before {
  content: "\ec7d";
}
.icon-ibm-fade:before {
  content: "\ec7c";
}
.icon-ibm-favorite:before {
  content: "\ec79";
}
.icon-ibm-favorite-filled:before {
  content: "\ec7a";
}
.icon-ibm-favorite_half:before {
  content: "\ec7b";
}
.icon-ibm-fetch_upload:before {
  content: "\ec77";
}
.icon-ibm-fetch_upload_cloud:before {
  content: "\ec78";
}
.icon-ibm-fight_roster:before {
  content: "\ec76";
}
.icon-ibm-filter:before {
  content: "\ec72";
}
.icon-ibm-filter-edit:before {
  content: "\ec73";
}
.icon-ibm-filter_remove:before {
  content: "\ec75";
}
.icon-ibm-filter_reset:before {
  content: "\ec74";
}
.icon-ibm-finance:before {
  content: "\ec71";
}
.icon-ibm-fingerprint_recognition:before {
  content: "\ec70";
}
.icon-ibm-fire:before {
  content: "\ec6f";
}
.icon-ibm-fish:before {
  content: "\ec6d";
}
.icon-ibm-fish_multiple:before {
  content: "\ec6e";
}
.icon-ibm-fit_to_screen:before {
  content: "\ec6c";
}
.icon-ibm-flag:before {
  content: "\ec6a";
}
.icon-ibm-flag-filled:before {
  content: "\ec6b";
}
.icon-ibm-flagging_taxi:before {
  content: "\ec69";
}
.icon-ibm-flash:before {
  content: "\ec65";
}
.icon-ibm-flash_filled:before {
  content: "\ec68";
}
.icon-ibm-flash_off:before {
  content: "\ec66";
}
.icon-ibm-flash_off-filled:before {
  content: "\ec67";
}
.icon-ibm-flight_international:before {
  content: "\ec64";
}
.icon-ibm-flight_schedule:before {
  content: "\ec63";
}
.icon-ibm-flood:before {
  content: "\ec61";
}
.icon-ibm-flood_alert:before {
  content: "\ec62";
}
.icon-ibm-flow:before {
  content: "\ec55";
}
.icon-ibm-flow_connection:before {
  content: "\ec60";
}
.icon-ibm-flow_data:before {
  content: "\ec5f";
}
.icon-ibm-flow_modeler:before {
  content: "\ec5e";
}
.icon-ibm-flow_stream .path1:before {
  content: "\ec56";
  color: rgb(2, 2, 2);
}
.icon-ibm-flow_stream .path2:before {
  content: "\ec57";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-flow_stream .path3:before {
  content: "\ec58";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-flow_stream_reference .path1:before {
  content: "\ec59";
  color: rgb(0, 0, 0);
}
.icon-ibm-flow_stream_reference .path2:before {
  content: "\ec5a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-flow_stream_reference .path3:before {
  content: "\ec5b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-flow_stream_reference .path4:before {
  content: "\ec5c";
  margin-left: -1em;
  color: rgb(2, 2, 2);
}
.icon-ibm-flow_stream_reference .path5:before {
  content: "\ec5d";
  margin-left: -1em;
  color: rgb(2, 2, 2);
}
.icon-ibm-fog:before {
  content: "\ec54";
}
.icon-ibm-folder:before {
  content: "\ec4b";
}
.icon-ibm-folder_app:before {
  content: "\ec53";
}
.icon-ibm-folder_details:before {
  content: "\ec51";
}
.icon-ibm-folder_details_reference:before {
  content: "\ec52";
}
.icon-ibm-folder_move_to:before {
  content: "\ec50";
}
.icon-ibm-folder_off:before {
  content: "\ec4f";
}
.icon-ibm-folder_open:before {
  content: "\ec4e";
}
.icon-ibm-folder_parent:before {
  content: "\ec4d";
}
.icon-ibm-folder_shared:before {
  content: "\ec4c";
}
.icon-ibm-folders:before {
  content: "\ec4a";
}
.icon-ibm-forecast_hail:before {
  content: "\ec48";
}
.icon-ibm-forecast_hail_30:before {
  content: "\ec49";
}
.icon-ibm-forecast_lighting:before {
  content: "\ec47";
}
.icon-ibm-forecast_lightning_30:before {
  content: "\ec46";
}
.icon-ibm-fork:before {
  content: "\ec45";
}
.icon-ibm-forum:before {
  content: "\ec44";
}
.icon-ibm-forward_10:before {
  content: "\ec42";
}
.icon-ibm-forward_30:before {
  content: "\ec41";
}
.icon-ibm-forward_5:before {
  content: "\ec43";
}
.icon-ibm-fragile:before {
  content: "\ec40";
}
.icon-ibm-fruit_bowl:before {
  content: "\ec3f";
}
.icon-ibm-function .path1:before {
  content: "\ec3c";
  color: rgb(22, 22, 22);
}
.icon-ibm-function .path2:before {
  content: "\ec3d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-function-1:before {
  content: "\ec3e";
}
.icon-ibm-fusion_blender:before {
  content: "\ec3b";
}
.icon-ibm-game_console:before {
  content: "\ec3a";
}
.icon-ibm-game_wireless:before {
  content: "\ec39";
}
.icon-ibm-gamification:before {
  content: "\ec38";
}
.icon-ibm-gas_station:before {
  content: "\ec36";
}
.icon-ibm-gas_station-filled:before {
  content: "\ec37";
}
.icon-ibm-gender_female:before {
  content: "\ec35";
}
.icon-ibm-gender_mail:before {
  content: "\ec34";
}
.icon-ibm-generate_pdf:before {
  content: "\ec33";
}
.icon-ibm-gift:before {
  content: "\ec31";
}
.icon-ibm-globe:before {
  content: "\ec30";
}
.icon-ibm-glyph_caution:before {
  content: "\ec2e";
}
.icon-ibm-glyph_caution_inverted:before {
  content: "\ec2f";
}
.icon-ibm-glyph_circle-fill:before {
  content: "\ec2c";
}
.icon-ibm-glyph_circle_stroke:before {
  content: "\ec2d";
}
.icon-ibm-glyph_critical:before {
  content: "\ec2b";
}
.icon-ibm-glyph_incomplete:before {
  content: "\ec2a";
}
.icon-ibm-glyph_square-fill:before {
  content: "\ec29";
}
.icon-ibm-glyph_undefined:before {
  content: "\ec28";
}
.icon-ibm-gradient:before {
  content: "\ec27";
}
.icon-ibm-graphical_data_flow:before {
  content: "\ec26";
}
.icon-ibm-grid:before {
  content: "\ec25";
}
.icon-ibm-group:before {
  content: "\ec23";
}
.icon-ibm-group_presentation:before {
  content: "\ec24";
}
.icon-ibm-growth:before {
  content: "\ec22";
}
.icon-ibm-hail:before {
  content: "\ec20";
}
.icon-ibm-hanging_protocol:before {
  content: "\ec1f";
}
.icon-ibm-harbor:before {
  content: "\ec1e";
}
.icon-ibm-hashtag:before {
  content: "\ec1d";
}
.icon-ibm-haze:before {
  content: "\ec1b";
}
.icon-ibm-haze_night:before {
  content: "\ec1c";
}
.icon-ibm-headphones:before {
  content: "\ec17";
}
.icon-ibm-headset:before {
  content: "\ec16";
}
.icon-ibm-health_cross:before {
  content: "\ec15";
}
.icon-ibm-hearing:before {
  content: "\ec14";
}
.icon-ibm-heat_map:before {
  content: "\ec10";
}
.icon-ibm-heat_map_02:before {
  content: "\ec13";
}
.icon-ibm-heat_map_03:before {
  content: "\ec12";
}
.icon-ibm-heat_map_stocks:before {
  content: "\ec11";
}
.icon-ibm-helicopter:before {
  content: "\ec0f";
}
.icon-ibm-help:before {
  content: "\ec0c";
}
.icon-ibm-help-filled:before {
  content: "\ec0d";
}
.icon-ibm-help_desk:before {
  content: "\ec0e";
}
.icon-ibm-hinton_plot:before {
  content: "\ec0b";
}
.icon-ibm-hole_filing_cursor:before {
  content: "\ec09";
}
.icon-ibm-hole_filling:before {
  content: "\ec08";
}
.icon-ibm-home:before {
  content: "\ec07";
}
.icon-ibm-hospital:before {
  content: "\ec05";
}
.icon-ibm-hospital_bed:before {
  content: "\ec06";
}
.icon-ibm-hotel:before {
  content: "\ec04";
}
.icon-ibm-hourglass:before {
  content: "\ec03";
}
.icon-ibm-humidity:before {
  content: "\ebff";
}
.icon-ibm-humidity-alt:before {
  content: "\ec00";
}
.icon-ibm-hurricane:before {
  content: "\ebfe";
}
.icon-ibm-hybrid_networking:before {
  content: "\ebfd";
}
.icon-ibm-iCA_2D:before {
  content: "\ebf6";
}
.icon-ibm-iD:before {
  content: "\ebf3";
}
.icon-ibm-ibm-earth_america:before {
  content: "\ebf9";
}
.icon-ibm-ibm-earth_america-filled:before {
  content: "\ebfa";
}
.icon-ibm-ibm-earth_europa_africa:before {
  content: "\ebf7";
}
.icon-ibm-ibm-earth_europa_africa-filled:before {
  content: "\ebf8";
}
.icon-ibm-ibm_cloud:before {
  content: "\ebfc";
}
.icon-ibm-ibm_security:before {
  content: "\ebfb";
}
.icon-ibm-ice_accretion:before {
  content: "\ebf5";
}
.icon-ibm-ice_vision:before {
  content: "\ebf4";
}
.icon-ibm-idea:before {
  content: "\ebf2";
}
.icon-ibm-identification:before {
  content: "\ebf1";
}
.icon-ibm-image:before {
  content: "\ebec";
}
.icon-ibm-image_copy:before {
  content: "\ebf0";
}
.icon-ibm-image_medical:before {
  content: "\ebef";
}
.icon-ibm-image_reference:before {
  content: "\ebee";
}
.icon-ibm-image_search:before {
  content: "\ebed";
}
.icon-ibm-improve_relevence:before {
  content: "\ebeb";
}
.icon-ibm-in_progress:before {
  content: "\ebe8";
}
.icon-ibm-in_progress_error:before {
  content: "\ebea";
}
.icon-ibm-in_progress_warning:before {
  content: "\ebe9";
}
.icon-ibm-incomplete:before {
  content: "\ebe4";
}
.icon-ibm-incomplete_cancel:before {
  content: "\ebe7";
}
.icon-ibm-incomplete_error:before {
  content: "\ebe6";
}
.icon-ibm-incomplete_warning:before {
  content: "\ebe5";
}
.icon-ibm-increase_level:before {
  content: "\ebe3";
}
.icon-ibm-industry:before {
  content: "\ebe2";
}
.icon-ibm-information:before {
  content: "\ebde";
}
.icon-ibm-information-filled:before {
  content: "\ebdf";
}
.icon-ibm-information_square:before {
  content: "\ebe0";
}
.icon-ibm-information_square-filled:before {
  content: "\ebe1";
}
.icon-ibm-insert:before {
  content: "\ebdb";
}
.icon-ibm-insert_page:before {
  content: "\ebdd";
}
.icon-ibm-insert_syntax:before {
  content: "\ebdc";
}
.icon-ibm-integration:before {
  content: "\ebda";
}
.icon-ibm-interactions:before {
  content: "\ebd9";
}
.icon-ibm-interactive_segmentation_cursor:before {
  content: "\ebd8";
}
.icon-ibm-intersect:before {
  content: "\ebd7";
}
.icon-ibm-inventory_management:before {
  content: "\ebd6";
}
.icon-ibm-iot_conect:before {
  content: "\ebd5";
}
.icon-ibm-iot_platform:before {
  content: "\ebd4";
}
.icon-ibm-join_full:before {
  content: "\ebd0";
}
.icon-ibm-join_inner:before {
  content: "\ebcf";
}
.icon-ibm-join_outer:before {
  content: "\ebce";
}
.icon-ibm-join_right:before {
  content: "\ebcd";
}
.icon-ibm-joing_left:before {
  content: "\ebcc";
}
.icon-ibm-jump_link:before {
  content: "\ebc8";
}
.icon-ibm-keep_dry:before {
  content: "\ebc7";
}
.icon-ibm-keyboard:before {
  content: "\ebc6";
}
.icon-ibm-language:before {
  content: "\ebc5";
}
.icon-ibm-laptop:before {
  content: "\ebc4";
}
.icon-ibm-lasso:before {
  content: "\ebc2";
}
.icon-ibm-lasso_polygon:before {
  content: "\ebc3";
}
.icon-ibm-launch:before {
  content: "\ebbe";
}
.icon-ibm-launch_study_03:before {
  content: "\ebbf";
}
.icon-ibm-launch_study_1:before {
  content: "\ebc1";
}
.icon-ibm-launch_study_2:before {
  content: "\ebc0";
}
.icon-ibm-layers:before {
  content: "\ebbd";
}
.icon-ibm-legend:before {
  content: "\ebbc";
}
.icon-ibm-letter_Aa:before {
  content: "\ebbb";
}
.icon-ibm-letter_Bb:before {
  content: "\ebba";
}
.icon-ibm-letter_Cc:before {
  content: "\ebb9";
}
.icon-ibm-letter_Dd:before {
  content: "\ebb8";
}
.icon-ibm-letter_Ee:before {
  content: "\ebb7";
}
.icon-ibm-letter_Ff:before {
  content: "\ebb6";
}
.icon-ibm-letter_Gg:before {
  content: "\ebb5";
}
.icon-ibm-letter_Hh:before {
  content: "\ebb4";
}
.icon-ibm-letter_Ii:before {
  content: "\ebb3";
}
.icon-ibm-letter_Jj:before {
  content: "\ebb2";
}
.icon-ibm-letter_Kk:before {
  content: "\ebb1";
}
.icon-ibm-letter_Ll:before {
  content: "\ebb0";
}
.icon-ibm-letter_Mm:before {
  content: "\ebaf";
}
.icon-ibm-letter_Nn:before {
  content: "\ebae";
}
.icon-ibm-letter_Oo:before {
  content: "\ebad";
}
.icon-ibm-letter_Pp:before {
  content: "\ebac";
}
.icon-ibm-letter_Qq:before {
  content: "\ebab";
}
.icon-ibm-letter_Rr:before {
  content: "\ebaa";
}
.icon-ibm-letter_Ss:before {
  content: "\eba9";
}
.icon-ibm-letter_Tt:before {
  content: "\eba8";
}
.icon-ibm-letter_Uu:before {
  content: "\eba7";
}
.icon-ibm-letter_Vv:before {
  content: "\eba6";
}
.icon-ibm-letter_Ww:before {
  content: "\eba5";
}
.icon-ibm-letter_Xx:before {
  content: "\eba4";
}
.icon-ibm-letter_Yy:before {
  content: "\eba3";
}
.icon-ibm-letter_Zz:before {
  content: "\eba2";
}
.icon-ibm-license:before {
  content: "\eb9b";
}
.icon-ibm-license_draft:before {
  content: "\eba1";
}
.icon-ibm-license_global:before {
  content: "\eba0";
}
.icon-ibm-license_maintenance:before {
  content: "\eb9e";
}
.icon-ibm-license_maintenance_draft:before {
  content: "\eb9f";
}
.icon-ibm-license_third_party:before {
  content: "\eb9c";
}
.icon-ibm-license_third_party_draft:before {
  content: "\eb9d";
}
.icon-ibm-lifesaver:before {
  content: "\eb9a";
}
.icon-ibm-light:before {
  content: "\eb98";
}
.icon-ibm-light-filled:before {
  content: "\eb99";
}
.icon-ibm-lightning:before {
  content: "\eb97";
}
.icon-ibm-link:before {
  content: "\eb96";
}
.icon-ibm-linux:before {
  content: "\eb94";
}
.icon-ibm-linux-alt:before {
  content: "\eb95";
}
.icon-ibm-list:before {
  content: "\eb8e";
}
.icon-ibm-list_boxes:before {
  content: "\eb93";
}
.icon-ibm-list_bullet:before {
  content: "\eb92";
}
.icon-ibm-list_checked:before {
  content: "\eb91";
}
.icon-ibm-list_dropdown:before {
  content: "\eb90";
}
.icon-ibm-list_numbered:before {
  content: "\eb8f";
}
.icon-ibm-location:before {
  content: "\eb81";
}
.icon-ibm-location-filled:before {
  content: "\eb82";
}
.icon-ibm-location_company:before {
  content: "\eb8c";
}
.icon-ibm-location_company-filled:before {
  content: "\eb8d";
}
.icon-ibm-location_current:before {
  content: "\eb8b";
}
.icon-ibm-location_hazard:before {
  content: "\eb89";
}
.icon-ibm-location_hazard-filled:before {
  content: "\eb8a";
}
.icon-ibm-location_heart:before {
  content: "\eb87";
}
.icon-ibm-location_heart-filled:before {
  content: "\eb88";
}
.icon-ibm-location_person:before {
  content: "\eb85";
}
.icon-ibm-location_person-filled:before {
  content: "\eb86";
}
.icon-ibm-location_star:before {
  content: "\eb83";
}
.icon-ibm-location_star-filled:before {
  content: "\eb84";
}
.icon-ibm-locked:before {
  content: "\eb80";
}
.icon-ibm-login:before {
  content: "\eb7f";
}
.icon-ibm-logo_digg:before {
  content: "\eb7e";
}
.icon-ibm-logo_discord:before {
  content: "\eb7d";
}
.icon-ibm-logo_facebook:before {
  content: "\eb7c";
}
.icon-ibm-logo_flickr:before {
  content: "\eb7b";
}
.icon-ibm-logo_github:before {
  content: "\eb7a";
}
.icon-ibm-logo_glassdoor:before {
  content: "\eb79";
}
.icon-ibm-logo_instagram:before {
  content: "\eb78";
}
.icon-ibm-logo_jupyter:before {
  content: "\eb77";
}
.icon-ibm-logo_keybase:before {
  content: "\eb76";
}
.icon-ibm-logo_linkedin:before {
  content: "\eb75";
}
.icon-ibm-logo_livestream:before {
  content: "\eb74";
}
.icon-ibm-logo_medium:before {
  content: "\eb73";
}
.icon-ibm-logo_openshift:before {
  content: "\eb72";
}
.icon-ibm-logo_pinterest:before {
  content: "\eb71";
}
.icon-ibm-logo_python:before {
  content: "\eb70";
}
.icon-ibm-logo_quora:before {
  content: "\eb6f";
}
.icon-ibm-logo_r_script:before {
  content: "\eb6e";
}
.icon-ibm-logo_skype:before {
  content: "\eb6d";
}
.icon-ibm-logo_slack:before {
  content: "\eb6c";
}
.icon-ibm-logo_snapchat:before {
  content: "\eb6b";
}
.icon-ibm-logo_tumblr:before {
  content: "\eb6a";
}
.icon-ibm-logo_twitter:before {
  content: "\eb69";
}
.icon-ibm-logo_vmware:before {
  content: "\eb68";
}
.icon-ibm-logo_xing:before {
  content: "\eb67";
}
.icon-ibm-logo_yelp:before {
  content: "\eb66";
}
.icon-ibm-logo_youtube:before {
  content: "\eb65";
}
.icon-ibm-logout:before {
  content: "\eb64";
}
.icon-ibm-loop:before {
  content: "\eb63";
}
.icon-ibm-mac_command:before {
  content: "\eb62";
}
.icon-ibm-mac_option:before {
  content: "\eb61";
}
.icon-ibm-mac_shift:before {
  content: "\eb60";
}
.icon-ibm-machine_learning:before {
  content: "\eb5e";
}
.icon-ibm-machine_learning_model:before {
  content: "\eb5f";
}
.icon-ibm-magic_wand:before {
  content: "\eb5c";
}
.icon-ibm-magic_wand-filled:before {
  content: "\eb5d";
}
.icon-ibm-magnify:before {
  content: "\eb5b";
}
.icon-ibm-mail-alt:before {
  content: "\eb59";
}
.icon-ibm-mail_reply:before {
  content: "\eb5a";
}
.icon-ibm-mammogram:before {
  content: "\eb58";
}
.icon-ibm-managed_solutions:before {
  content: "\eb57";
}
.icon-ibm-map:before {
  content: "\eb55";
}
.icon-ibm-map_boundary:before {
  content: "\eb56";
}
.icon-ibm-marine_alert:before {
  content: "\eb54";
}
.icon-ibm-math_curve:before {
  content: "\eb53";
}
.icon-ibm-matrix:before {
  content: "\eb52";
}
.icon-ibm-maximize:before {
  content: "\eb51";
}
.icon-ibm-media_library:before {
  content: "\eb50";
}
.icon-ibm-medication:before {
  content: "\eb4d";
}
.icon-ibm-medication_alert:before {
  content: "\eb4f";
}
.icon-ibm-medication_reminder:before {
  content: "\eb4e";
}
.icon-ibm-menu:before {
  content: "\eb4c";
}
.icon-ibm-meter:before {
  content: "\eb4a";
}
.icon-ibm-meter-alt:before {
  content: "\eb4b";
}
.icon-ibm-microphone:before {
  content: "\eb46";
}
.icon-ibm-microphone-filled:before {
  content: "\eb47";
}
.icon-ibm-microphone_off:before {
  content: "\eb48";
}
.icon-ibm-microphone_off-filled:before {
  content: "\eb49";
}
.icon-ibm-microscope:before {
  content: "\eb45";
}
.icon-ibm-migrate:before {
  content: "\eb43";
}
.icon-ibm-migrate_all:before {
  content: "\eb44";
}
.icon-ibm-milestone:before {
  content: "\eb42";
}
.icon-ibm-minimize:before {
  content: "\eb41";
}
.icon-ibm-misuse:before {
  content: "\eb3f";
}
.icon-ibm-misuse-outline:before {
  content: "\eb40";
}
.icon-ibm-mixed_rain_hail:before {
  content: "\eb3e";
}
.icon-ibm-mobile:before {
  content: "\eb32";
}
.icon-ibm-mobile_add:before {
  content: "\eb3d";
}
.icon-ibm-mobile_audio .path1:before {
  content: "\eb36";
  color: rgb(0, 0, 0);
}
.icon-ibm-mobile_audio .path2:before {
  content: "\eb37";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-mobile_audio .path3:before {
  content: "\eb38";
  margin-left: -1em;
  color: rgb(23, 23, 23);
}
.icon-ibm-mobile_audio .path4:before {
  content: "\eb39";
  margin-left: -1em;
  color: rgb(23, 23, 23);
}
.icon-ibm-mobile_audio .path5:before {
  content: "\eb3a";
  margin-left: -1em;
  color: rgb(23, 23, 23);
}
.icon-ibm-mobile_audio .path6:before {
  content: "\eb3b";
  margin-left: -1em;
  color: rgb(23, 23, 23);
}
.icon-ibm-mobile_audio .path7:before {
  content: "\eb3c";
  margin-left: -1em;
  color: rgb(23, 23, 23);
}
.icon-ibm-mobile_check:before {
  content: "\eb35";
}
.icon-ibm-mobile_download:before {
  content: "\eb34";
}
.icon-ibm-mobile_landscape:before {
  content: "\eb33";
}
.icon-ibm-model:before {
  content: "\eb2d";
}
.icon-ibm-model-alt:before {
  content: "\eb2e";
}
.icon-ibm-model_builder:before {
  content: "\eb30";
}
.icon-ibm-model_builder_reference:before {
  content: "\eb31";
}
.icon-ibm-model_reference:before {
  content: "\eb2f";
}
.icon-ibm-money:before {
  content: "\eb2c";
}
.icon-ibm-monster:before {
  content: "\eb2b";
}
.icon-ibm-monument:before {
  content: "\eb2a";
}
.icon-ibm-moon:before {
  content: "\eb29";
}
.icon-ibm-moonrise:before {
  content: "\eb28";
}
.icon-ibm-moonset:before {
  content: "\eb27";
}
.icon-ibm-mostly_cloudy:before {
  content: "\eb25";
}
.icon-ibm-mostly_cloudy_night:before {
  content: "\eb26";
}
.icon-ibm-mountain:before {
  content: "\eb24";
}
.icon-ibm-move:before {
  content: "\eb22";
}
.icon-ibm-movement:before {
  content: "\eb21";
}
.icon-ibm-music:before {
  content: "\eb17";
}
.icon-ibm-music_add:before {
  content: "\eb19";
}
.icon-ibm-music_remove:before {
  content: "\eb18";
}
.icon-ibm-name_space:before {
  content: "\eb16";
}
.icon-ibm-navaid_civil:before {
  content: "\eb15";
}
.icon-ibm-navaid_dme:before {
  content: "\eb14";
}
.icon-ibm-navaid_helipad:before {
  content: "\eb13";
}
.icon-ibm-navaid_military:before {
  content: "\eb11";
}
.icon-ibm-navaid_military_civil:before {
  content: "\eb12";
}
.icon-ibm-navaid_ndb:before {
  content: "\eafa";
}
.icon-ibm-navaid_ndb_dme .path1:before {
  content: "\eafb";
  color: rgb(23, 23, 23);
}
.icon-ibm-navaid_ndb_dme .path2:before {
  content: "\eafc";
  margin-left: -1em;
  color: rgb(23, 23, 23);
}
.icon-ibm-navaid_ndb_dme .path3:before {
  content: "\eafd";
  margin-left: -1em;
  color: rgb(23, 23, 23);
}
.icon-ibm-navaid_ndb_dme .path4:before {
  content: "\eafe";
  margin-left: -1em;
  color: rgb(23, 23, 23);
}
.icon-ibm-navaid_ndb_dme .path5:before {
  content: "\eaff";
  margin-left: -1em;
  color: rgb(23, 23, 23);
}
.icon-ibm-navaid_ndb_dme .path6:before {
  content: "\eb00";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path7:before {
  content: "\eb01";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path8:before {
  content: "\eb02";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path9:before {
  content: "\eb03";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path10:before {
  content: "\eb04";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path11:before {
  content: "\eb05";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path12:before {
  content: "\eb06";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path13:before {
  content: "\eb07";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path14:before {
  content: "\eb08";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path15:before {
  content: "\eb09";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path16:before {
  content: "\eb0a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path17:before {
  content: "\eb0b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path18:before {
  content: "\eb0c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path19:before {
  content: "\eb0d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path20:before {
  content: "\eb0e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path21:before {
  content: "\eb0f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_ndb_dme .path22:before {
  content: "\eb10";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_private:before {
  content: "\eaf9";
}
.icon-ibm-navaid_seaplane:before {
  content: "\eaf8";
}
.icon-ibm-navaid_tacan:before {
  content: "\eaf7";
}
.icon-ibm-navaid_vhfor:before {
  content: "\eaf6";
}
.icon-ibm-navaid_vor .path1:before {
  content: "\eaf4";
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_vor .path2:before {
  content: "\eaf5";
  margin-left: -1em;
  color: rgb(23, 23, 23);
}
.icon-ibm-navaid_vordme .path1:before {
  content: "\eaf2";
  color: rgb(0, 0, 0);
}
.icon-ibm-navaid_vordme .path2:before {
  content: "\eaf3";
  margin-left: -1em;
  color: rgb(23, 23, 23);
}
.icon-ibm-navaid_vortac:before {
  content: "\eaf1";
}
.icon-ibm-network_1:before {
  content: "\eaf0";
}
.icon-ibm-network_2:before {
  content: "\eaef";
}
.icon-ibm-network_3:before {
  content: "\eaed";
}
.icon-ibm-network_3_reference:before {
  content: "\eaee";
}
.icon-ibm-network_4:before {
  content: "\eaec";
}
.icon-ibm-new_tab:before {
  content: "\eaeb";
}
.icon-ibm-next-filled:before {
  content: "\eaea";
}
.icon-ibm-next-outline:before {
  content: "\eae9";
}
.icon-ibm-no_image:before {
  content: "\eae8";
}
.icon-ibm-no_ticket:before {
  content: "\eae7";
}
.icon-ibm-nominal:before {
  content: "\eae6";
}
.icon-ibm-nominate:before {
  content: "\eae5";
}
.icon-ibm-non_certified:before {
  content: "\eae4";
}
.icon-ibm-noodle_bowl:before {
  content: "\eae3";
}
.icon-ibm-not-sent:before {
  content: "\eae0";
}
.icon-ibm-not_available:before {
  content: "\eae2";
}
.icon-ibm-not_sent-filled:before {
  content: "\eae1";
}
.icon-ibm-notebook:before {
  content: "\eade";
}
.icon-ibm-notebook_reference:before {
  content: "\eadf";
}
.icon-ibm-notification:before {
  content: "\ead9";
}
.icon-ibm-notification-filled:before {
  content: "\eada";
}
.icon-ibm-notification_new:before {
  content: "\eadd";
}
.icon-ibm-notification_off:before {
  content: "\eadb";
}
.icon-ibm-notification_off-filled:before {
  content: "\eadc";
}
.icon-ibm-number_-7:before {
  content: "\ead8";
}
.icon-ibm-number_0:before {
  content: "\ead7";
}
.icon-ibm-number_1 .path1:before {
  content: "\ead5";
  color: rgb(255, 255, 255);
}
.icon-ibm-number_1 .path2:before {
  content: "\ead6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-number_2:before {
  content: "\ead4";
}
.icon-ibm-number_3:before {
  content: "\ead3";
}
.icon-ibm-number_4 .path1:before {
  content: "\ead1";
  color: rgb(255, 255, 255);
}
.icon-ibm-number_4 .path2:before {
  content: "\ead2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-number_5:before {
  content: "\ead0";
}
.icon-ibm-number_6:before {
  content: "\eacf";
}
.icon-ibm-number_8:before {
  content: "\eace";
}
.icon-ibm-number_9:before {
  content: "\eacd";
}
.icon-ibm-number_small_0:before {
  content: "\eacc";
}
.icon-ibm-number_small_1:before {
  content: "\eacb";
}
.icon-ibm-number_small_2:before {
  content: "\eaca";
}
.icon-ibm-number_small_3:before {
  content: "\eac9";
}
.icon-ibm-number_small_4:before {
  content: "\eac8";
}
.icon-ibm-number_small_5:before {
  content: "\eac7";
}
.icon-ibm-number_small_6:before {
  content: "\eac6";
}
.icon-ibm-number_small_7:before {
  content: "\eac5";
}
.icon-ibm-number_small_8:before {
  content: "\eac4";
}
.icon-ibm-number_small_9:before {
  content: "\eac3";
}
.icon-ibm-object_storage:before {
  content: "\eac2";
}
.icon-ibm-observed_hail:before {
  content: "\eac1";
}
.icon-ibm-observed_lightning:before {
  content: "\eac0";
}
.icon-ibm-omega:before {
  content: "\eabf";
}
.icon-ibm-opacity:before {
  content: "\eabe";
}
.icon-ibm-open_panel-filled-bottom:before {
  content: "\eab9";
}
.icon-ibm-open_panel-filled-left:before {
  content: "\eab8";
}
.icon-ibm-open_panel-filled-right:before {
  content: "\eab7";
}
.icon-ibm-open_panel-filled-top:before {
  content: "\eab6";
}
.icon-ibm-open_panel_bottom:before {
  content: "\eabd";
}
.icon-ibm-open_panel_left:before {
  content: "\eabc";
}
.icon-ibm-open_panel_right:before {
  content: "\eabb";
}
.icon-ibm-open_panel_top:before {
  content: "\eaba";
}
.icon-ibm-operation:before {
  content: "\eab3";
}
.icon-ibm-operation_gauge:before {
  content: "\eab5";
}
.icon-ibm-operation_if:before {
  content: "\eab4";
}
.icon-ibm-operations_filled:before {
  content: "\eab2";
}
.icon-ibm-operations_record:before {
  content: "\eab1";
}
.icon-ibm-ordinal:before {
  content: "\eab0";
}
.icon-ibm-outage:before {
  content: "\eaaf";
}
.icon-ibm-outlook_severe:before {
  content: "\eaae";
}
.icon-ibm-overflow_menu_horizontal:before {
  content: "\eaad";
}
.icon-ibm-overflow_menu_vertical:before {
  content: "\eaac";
}
.icon-ibm-overlay:before {
  content: "\eaab";
}
.icon-ibm-package:before {
  content: "\eaaa";
}
.icon-ibm-page_break:before {
  content: "\eaa9";
}
.icon-ibm-page_first:before {
  content: "\eaa8";
}
.icon-ibm-page_last:before {
  content: "\eaa7";
}
.icon-ibm-page_number:before {
  content: "\eaa6";
}
.icon-ibm-page_scroll:before {
  content: "\eaa5";
}
.icon-ibm-paint_brush:before {
  content: "\eaa3";
}
.icon-ibm-paint_brush-alt:before {
  content: "\eaa4";
}
.icon-ibm-palm_tree:before {
  content: "\eaa2";
}
.icon-ibm-pan_horizontal:before {
  content: "\eaa1";
}
.icon-ibm-pan_vertical:before {
  content: "\eaa0";
}
.icon-ibm-panel_expansion:before {
  content: "\ea9f";
}
.icon-ibm-panel_scane:before {
  content: "\ea9e";
}
.icon-ibm-paragraph:before {
  content: "\ea9d";
}
.icon-ibm-parameter:before {
  content: "\ea9c";
}
.icon-ibm-parent_child:before {
  content: "\ea9b";
}
.icon-ibm-partly_cloudy_night:before {
  content: "\ea9a";
}
.icon-ibm-partnership:before {
  content: "\ea99";
}
.icon-ibm-party_cloudy:before {
  content: "\ea98";
}
.icon-ibm-passenger_drinks:before {
  content: "\ea97";
}
.icon-ibm-passenger_plus:before {
  content: "\ea96";
}
.icon-ibm-password:before {
  content: "\ea95";
}
.icon-ibm-paste:before {
  content: "\ea94";
}
.icon-ibm-pause:before {
  content: "\ea8e";
}
.icon-ibm-pause-filled:before {
  content: "\ea91";
}
.icon-ibm-pause-outline:before {
  content: "\ea8f";
}
.icon-ibm-pause-outline-filled:before {
  content: "\ea90";
}
.icon-ibm-pause_future:before {
  content: "\ea93";
}
.icon-ibm-pause_past:before {
  content: "\ea92";
}
.icon-ibm-pedestrian:before {
  content: "\ea89";
}
.icon-ibm-pedestrian_child:before {
  content: "\ea8b";
}
.icon-ibm-pedestrian_family:before {
  content: "\ea8a";
}
.icon-ibm-pen:before {
  content: "\ea87";
}
.icon-ibm-pen_fountain:before {
  content: "\ea88";
}
.icon-ibm-pending:before {
  content: "\ea85";
}
.icon-ibm-pending-filled:before {
  content: "\ea86";
}
.icon-ibm-percentage:before {
  content: "\ea83";
}
.icon-ibm-percentage-filled:before {
  content: "\ea84";
}
.icon-ibm-person:before {
  content: "\ea81";
}
.icon-ibm-person_favorite:before {
  content: "\ea82";
}
.icon-ibm-pest:before {
  content: "\ea80";
}
.icon-ibm-pet_image_0:before {
  content: "\ea7f";
}
.icon-ibm-pet_image_B:before {
  content: "\ea7e";
}
.icon-ibm-phone:before {
  content: "\ea6d";
}
.icon-ibm-phone-filled:before {
  content: "\ea6e";
}
.icon-ibm-phone_block:before {
  content: "\ea7c";
}
.icon-ibm-phone_block-filled:before {
  content: "\ea7d";
}
.icon-ibm-phone_incoming .path1:before {
  content: "\ea78";
  color: rgb(0, 0, 0);
}
.icon-ibm-phone_incoming .path2:before {
  content: "\ea79";
  margin-left: -1em;
  color: rgb(34, 31, 32);
}
.icon-ibm-phone_incoming-filled .path1:before {
  content: "\ea7a";
  color: rgb(0, 0, 0);
}
.icon-ibm-phone_incoming-filled .path2:before {
  content: "\ea7b";
  margin-left: -1em;
  color: rgb(34, 31, 32);
}
.icon-ibm-phone_ip:before {
  content: "\ea77";
}
.icon-ibm-phone_off:before {
  content: "\ea75";
}
.icon-ibm-phone_off-filled:before {
  content: "\ea76";
}
.icon-ibm-phone_outgoing .path1:before {
  content: "\ea71";
  color: rgb(0, 0, 0);
}
.icon-ibm-phone_outgoing .path2:before {
  content: "\ea72";
  margin-left: -1em;
  color: rgb(34, 31, 32);
}
.icon-ibm-phone_outgoing-filled .path1:before {
  content: "\ea73";
  color: rgb(0, 0, 0);
}
.icon-ibm-phone_outgoing-filled .path2:before {
  content: "\ea74";
  margin-left: -1em;
  color: rgb(34, 31, 32);
}
.icon-ibm-phone_voice:before {
  content: "\ea6f";
}
.icon-ibm-phone_voice-filled:before {
  content: "\ea70";
}
.icon-ibm-phrase_sentiment:before {
  content: "\ea6c";
}
.icon-ibm-picnic_area:before {
  content: "\ea6b";
}
.icon-ibm-piggy_bank:before {
  content: "\ea69";
}
.icon-ibm-piggy_bank_slot:before {
  content: "\ea6a";
}
.icon-ibm-pils:before {
  content: "\ea66";
}
.icon-ibm-pils_add:before {
  content: "\ea68";
}
.icon-ibm-pils_substract:before {
  content: "\ea67";
}
.icon-ibm-pin:before {
  content: "\ea64";
}
.icon-ibm-pin-filled:before {
  content: "\ea65";
}
.icon-ibm-plane:before {
  content: "\ea61";
}
.icon-ibm-plane_private:before {
  content: "\ea63";
}
.icon-ibm-plane_sea:before {
  content: "\ea62";
}
.icon-ibm-play:before {
  content: "\ea5c";
}
.icon-ibm-play-filled:before {
  content: "\ea5f";
}
.icon-ibm-play-filled-1:before {
  content: "\ea60";
}
.icon-ibm-play-outline:before {
  content: "\ea5d";
}
.icon-ibm-play-outline-filled:before {
  content: "\ea5e";
}
.icon-ibm-playlist:before {
  content: "\ea5b";
}
.icon-ibm-plug:before {
  content: "\ea59";
}
.icon-ibm-plug_filled:before {
  content: "\ea5a";
}
.icon-ibm-pointer_text:before {
  content: "\ea57";
}
.icon-ibm-police:before {
  content: "\ea56";
}
.icon-ibm-policy:before {
  content: "\ea55";
}
.icon-ibm-popup:before {
  content: "\ea54";
}
.icon-ibm-portfolio:before {
  content: "\ea53";
}
.icon-ibm-power:before {
  content: "\ea52";
}
.icon-ibm-presentation_file:before {
  content: "\ea50";
}
.icon-ibm-pressure:before {
  content: "\ea4e";
}
.icon-ibm-pressure-filled:before {
  content: "\ea4f";
}
.icon-ibm-previous-filled:before {
  content: "\ea4d";
}
.icon-ibm-previous-outline:before {
  content: "\ea4c";
}
.icon-ibm-printer:before {
  content: "\ea4b";
}
.icon-ibm-product:before {
  content: "\ea4a";
}
.icon-ibm-progress_bar:before {
  content: "\ea48";
}
.icon-ibm-progress_bar_round:before {
  content: "\ea49";
}
.icon-ibm-purchase:before {
  content: "\ea47";
}
.icon-ibm-qq_plot:before {
  content: "\ea46";
}
.icon-ibm-qr_code:before {
  content: "\ea45";
}
.icon-ibm-quadrant_plot:before {
  content: "\ea44";
}
.icon-ibm-query:before {
  content: "\ea42";
}
.icon-ibm-query_queue:before {
  content: "\ea43";
}
.icon-ibm-queued:before {
  content: "\ea41";
}
.icon-ibm-quotes:before {
  content: "\ea40";
}
.icon-ibm-radar:before {
  content: "\ea3d";
}
.icon-ibm-radar_enhanced:before {
  content: "\ea3f";
}
.icon-ibm-radar_weather:before {
  content: "\ea3e";
}
.icon-ibm-radio:before {
  content: "\ea3a";
}
.icon-ibm-radio_button:before {
  content: "\ea3b";
}
.icon-ibm-radio_button_checked:before {
  content: "\ea3c";
}
.icon-ibm-rain:before {
  content: "\ea34";
}
.icon-ibm-rain_drizzle:before {
  content: "\ea39";
}
.icon-ibm-rain_drop:before {
  content: "\ea38";
}
.icon-ibm-rain_heavy:before {
  content: "\ea37";
}
.icon-ibm-rain_scattered:before {
  content: "\ea35";
}
.icon-ibm-rain_scattered_night:before {
  content: "\ea36";
}
.icon-ibm-receipt:before {
  content: "\ea32";
}
.icon-ibm-recommend:before {
  content: "\ea31";
}
.icon-ibm-recording:before {
  content: "\ea2e";
}
.icon-ibm-recording-filled:before {
  content: "\ea2f";
}
.icon-ibm-recording-filled-alt:before {
  content: "\ea30";
}
.icon-ibm-redo:before {
  content: "\ea2d";
}
.icon-ibm-ref_evapotranspiration:before {
  content: "\ea2c";
}
.icon-ibm-reflect_horizontal:before {
  content: "\ea2b";
}
.icon-ibm-reflect_vertical:before {
  content: "\ea2a";
}
.icon-ibm-region_analysis_area:before {
  content: "\ea29";
}
.icon-ibm-region_analysis_volume .path1:before {
  content: "\ea27";
  color: rgb(35, 31, 32);
}
.icon-ibm-region_analysis_volume .path2:before {
  content: "\ea28";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-registration:before {
  content: "\ea26";
}
.icon-ibm-reminder:before {
  content: "\ea24";
}
.icon-ibm-reminder_medical:before {
  content: "\ea25";
}
.icon-ibm-rencently_viewed:before {
  content: "\ea23";
}
.icon-ibm-renew:before {
  content: "\ea22";
}
.icon-ibm-repeat:before {
  content: "\ea20";
}
.icon-ibm-repeat_one:before {
  content: "\ea21";
}
.icon-ibm-reply:before {
  content: "\ea1e";
}
.icon-ibm-reply_all:before {
  content: "\ea1f";
}
.icon-ibm-report:before {
  content: "\ea1c";
}
.icon-ibm-report_data:before {
  content: "\ea1d";
}
.icon-ibm-request_note:before {
  content: "\ea1b";
}
.icon-ibm-reset:before {
  content: "\ea1a";
}
.icon-ibm-rest-alt:before {
  content: "\ea19";
}
.icon-ibm-restart:before {
  content: "\ea18";
}
.icon-ibm-restaurant:before {
  content: "\ea16";
}
.icon-ibm-restaurant_fine:before {
  content: "\ea17";
}
.icon-ibm-rewind_10:before {
  content: "\ea14";
}
.icon-ibm-rewind_30:before {
  content: "\ea13";
}
.icon-ibm-rewind_5:before {
  content: "\ea15";
}
.icon-ibm-road:before {
  content: "\ea11";
}
.icon-ibm-road_weather:before {
  content: "\ea12";
}
.icon-ibm-roadmap:before {
  content: "\ea10";
}
.icon-ibm-rocket:before {
  content: "\ea0f";
}
.icon-ibm-rotate:before {
  content: "\ea04";
}
.icon-ibm-rotate_180:before {
  content: "\ea0e";
}
.icon-ibm-rotate_360:before {
  content: "\ea0d";
}
.icon-ibm-rotate_clockwise:before {
  content: "\ea09";
}
.icon-ibm-rotate_clockwise-alt:before {
  content: "\ea0b";
}
.icon-ibm-rotate_clockwise-alt-filled:before {
  content: "\ea0c";
}
.icon-ibm-rotate_clockwise-filled:before {
  content: "\ea0a";
}
.icon-ibm-rotate_counterclockwise:before {
  content: "\ea05";
}
.icon-ibm-rotate_counterclockwise-alt:before {
  content: "\ea07";
}
.icon-ibm-rotate_counterclockwise-alt-filled:before {
  content: "\ea08";
}
.icon-ibm-rotate_counterclockwise-filled:before {
  content: "\ea06";
}
.icon-ibm-row:before {
  content: "\e9ff";
}
.icon-ibm-row_collapse:before {
  content: "\ea03";
}
.icon-ibm-row_delete:before {
  content: "\ea02";
}
.icon-ibm-row_expand:before {
  content: "\ea01";
}
.icon-ibm-row_insert:before {
  content: "\ea00";
}
.icon-ibm-rss:before {
  content: "\e9fe";
}
.icon-ibm-rule:before {
  content: "\e9fd";
}
.icon-ibm-ruler:before {
  content: "\e9fb";
}
.icon-ibm-ruler-alt:before {
  content: "\e9fc";
}
.icon-ibm-run:before {
  content: "\e9fa";
}
.icon-ibm-sailboat_coastal:before {
  content: "\e9f7";
}
.icon-ibm-sailboat_offshore:before {
  content: "\e9f6";
}
.icon-ibm-sankey_diagram:before {
  content: "\e9f4";
}
.icon-ibm-sankey_diagram-alt:before {
  content: "\e9f5";
}
.icon-ibm-satellite:before {
  content: "\e9f1";
}
.icon-ibm-satellite_radar:before {
  content: "\e9f3";
}
.icon-ibm-satellite_weather:before {
  content: "\e9f2";
}
.icon-ibm-save:before {
  content: "\e9ec";
}
.icon-ibm-save_annotation:before {
  content: "\e9f0";
}
.icon-ibm-save_image:before {
  content: "\e9ef";
}
.icon-ibm-save_model:before {
  content: "\e9ee";
}
.icon-ibm-save_series:before {
  content: "\e9ed";
}
.icon-ibm-scales:before {
  content: "\e9ea";
}
.icon-ibm-scales_tipped:before {
  content: "\e9eb";
}
.icon-ibm-scalpel:before {
  content: "\e9e6";
}
.icon-ibm-scalpel_cursor:before {
  content: "\e9e9";
}
.icon-ibm-scalpel_lasso:before {
  content: "\e9e8";
}
.icon-ibm-scalpel_select:before {
  content: "\e9e7";
}
.icon-ibm-scan:before {
  content: "\e9e4";
}
.icon-ibm-scan_disabled:before {
  content: "\e9e5";
}
.icon-ibm-scatter_matrix:before {
  content: "\e9e3";
}
.icon-ibm-schematics:before {
  content: "\e9e2";
}
.icon-ibm-scooter:before {
  content: "\e9e0";
}
.icon-ibm-scooter_front:before {
  content: "\e9e1";
}
.icon-ibm-screen:before {
  content: "\e9de";
}
.icon-ibm-screen_off:before {
  content: "\e9df";
}
.icon-ibm-script:before {
  content: "\e9dc";
}
.icon-ibm-script_reference:before {
  content: "\e9dd";
}
.icon-ibm-search:before {
  content: "\e9d9";
}
.icon-ibm-search_locate:before {
  content: "\e9da";
}
.icon-ibm-security:before {
  content: "\e9d8";
}
.icon-ibm-select_01:before {
  content: "\e9d7";
}
.icon-ibm-select_02:before {
  content: "\e9d6";
}
.icon-ibm-select_window:before {
  content: "\e9d5";
}
.icon-ibm-send:before {
  content: "\e9d0";
}
.icon-ibm-send-alt:before {
  content: "\e9d3";
}
.icon-ibm-send-filled:before {
  content: "\e9d1";
}
.icon-ibm-send-filled-1:before {
  content: "\e9d2";
}
.icon-ibm-send_to_back:before {
  content: "\e9d4";
}
.icon-ibm-service_desk:before {
  content: "\e9cf";
}
.icon-ibm-settings:before {
  content: "\e9cc";
}
.icon-ibm-settings_adjust:before {
  content: "\e9ce";
}
.icon-ibm-settings_check:before {
  content: "\e9cd";
}
.icon-ibm-shape_except:before {
  content: "\e9cb";
}
.icon-ibm-shape_exclude:before {
  content: "\e9ca";
}
.icon-ibm-shape_intersect:before {
  content: "\e9c9";
}
.icon-ibm-shape_join:before {
  content: "\e9c8";
}
.icon-ibm-shape_unite:before {
  content: "\e9c7";
}
.icon-ibm-share:before {
  content: "\e9c5";
}
.icon-ibm-share_knowledge:before {
  content: "\e9c6";
}
.icon-ibm-shopping_bag:before {
  content: "\e9c4";
}
.icon-ibm-shopping_cart:before {
  content: "\e9bd";
}
.icon-ibm-shopping_cart_arrow_down:before {
  content: "\e9c3";
}
.icon-ibm-shopping_cart_arrow_up:before {
  content: "\e9c2";
}
.icon-ibm-shopping_cart_clear:before {
  content: "\e9c1";
}
.icon-ibm-shopping_cart_error:before {
  content: "\e9c0";
}
.icon-ibm-shopping_cart_minus:before {
  content: "\e9bf";
}
.icon-ibm-shopping_cart_plus:before {
  content: "\e9be";
}
.icon-ibm-shopping_catalog:before {
  content: "\e9bc";
}
.icon-ibm-shrink_screen:before {
  content: "\e9ba";
}
.icon-ibm-shrink_screen-filled:before {
  content: "\e9bb";
}
.icon-ibm-shuffle:before {
  content: "\e9b9";
}
.icon-ibm-shuttle:before {
  content: "\e9b8";
}
.icon-ibm-sight:before {
  content: "\e9b7";
}
.icon-ibm-sigma:before {
  content: "\e9b6";
}
.icon-ibm-signal_strength:before {
  content: "\e9b5";
}
.icon-ibm-skill_level:before {
  content: "\e9b1";
}
.icon-ibm-skill_level_advanced:before {
  content: "\e9b4";
}
.icon-ibm-skill_level_basic:before {
  content: "\e9b3";
}
.icon-ibm-skill_level_intermediate:before {
  content: "\e9b2";
}
.icon-ibm-skip_back:before {
  content: "\e9ab";
}
.icon-ibm-skip_back-filled:before {
  content: "\e9b0";
}
.icon-ibm-skip_back-outline:before {
  content: "\e9ae";
}
.icon-ibm-skip_back-outline-filled:before {
  content: "\e9af";
}
.icon-ibm-skip_back-solid:before {
  content: "\e9ac";
}
.icon-ibm-skip_back-solid-filled:before {
  content: "\e9ad";
}
.icon-ibm-skip_forward-outline:before {
  content: "\e9a9";
}
.icon-ibm-skip_forward-outline-filled:before {
  content: "\e9aa";
}
.icon-ibm-skip_forward-solid:before {
  content: "\e9a7";
}
.icon-ibm-skip_forward-solid-filled:before {
  content: "\e9a8";
}
.icon-ibm-skip_foward:before {
  content: "\e9a5";
}
.icon-ibm-skip_foward-filled:before {
  content: "\e9a6";
}
.icon-ibm-sleet:before {
  content: "\e9a4";
}
.icon-ibm-smell:before {
  content: "\e9a3";
}
.icon-ibm-smoke:before {
  content: "\e9a2";
}
.icon-ibm-smoothing .path1:before {
  content: "\e996";
  color: rgb(0, 0, 0);
}
.icon-ibm-smoothing .path2:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(2, 2, 2);
}
.icon-ibm-smoothing .path3:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(2, 2, 2);
}
.icon-ibm-smoothing .path4:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(2, 2, 2);
}
.icon-ibm-smoothing .path5:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(2, 2, 2);
}
.icon-ibm-smoothing .path6:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(2, 2, 2);
}
.icon-ibm-smoothing .path7:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(2, 2, 2);
}
.icon-ibm-smoothing .path8:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(2, 2, 2);
}
.icon-ibm-smoothing .path9:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(2, 2, 2);
}
.icon-ibm-smoothing .path10:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(2, 2, 2);
}
.icon-ibm-smoothing .path11:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(2, 2, 2);
}
.icon-ibm-smoothing_cursor:before {
  content: "\e9a1";
}
.icon-ibm-snooze:before {
  content: "\e995";
}
.icon-ibm-snow:before {
  content: "\e98f";
}
.icon-ibm-snow_blizzard:before {
  content: "\e994";
}
.icon-ibm-snow_density:before {
  content: "\e993";
}
.icon-ibm-snow_heavy:before {
  content: "\e992";
}
.icon-ibm-snow_scattered:before {
  content: "\e990";
}
.icon-ibm-snow_scattered_night:before {
  content: "\e991";
}
.icon-ibm-snowflake:before {
  content: "\e98e";
}
.icon-ibm-soccer:before {
  content: "\e98d";
}
.icon-ibm-soil_moisture:before {
  content: "\e989";
}
.icon-ibm-soil_moisture_field:before {
  content: "\e98b";
}
.icon-ibm-soil_moisture_field-1:before {
  content: "\e98c";
}
.icon-ibm-soil_moisture_global:before {
  content: "\e98a";
}
.icon-ibm-soil_temperature:before {
  content: "\e987";
}
.icon-ibm-soil_temperature_global:before {
  content: "\e988";
}
.icon-ibm-sort_ascending:before {
  content: "\e986";
}
.icon-ibm-sort_descending:before {
  content: "\e985";
}
.icon-ibm-spell_check:before {
  content: "\e984";
}
.icon-ibm-spine_label:before {
  content: "\e983";
}
.icon-ibm-split:before {
  content: "\e980";
}
.icon-ibm-split_discard:before {
  content: "\e982";
}
.icon-ibm-split_screen:before {
  content: "\e981";
}
.icon-ibm-spray_paint:before {
  content: "\e97f";
}
.icon-ibm-sprout:before {
  content: "\e97e";
}
.icon-ibm-stack_limitation:before {
  content: "\e97c";
}
.icon-ibm-stacked_move:before {
  content: "\e97b";
}
.icon-ibm-stacked_srolling_01:before {
  content: "\e97a";
}
.icon-ibm-stacked_srolling_02:before {
  content: "\e979";
}
.icon-ibm-stamp:before {
  content: "\e978";
}
.icon-ibm-star:before {
  content: "\e974";
}
.icon-ibm-star-filled:before {
  content: "\e975";
}
.icon-ibm-star_half:before {
  content: "\e977";
}
.icon-ibm-star_review:before {
  content: "\e976";
}
.icon-ibm-stay_inside:before {
  content: "\e973";
}
.icon-ibm-stem_leaf_plot:before {
  content: "\e972";
}
.icon-ibm-stethoscope:before {
  content: "\e971";
}
.icon-ibm-stop:before {
  content: "\e96a";
}
.icon-ibm-stop-filled:before {
  content: "\e96d";
}
.icon-ibm-stop-filled-alt:before {
  content: "\e96e";
}
.icon-ibm-stop-outline:before {
  content: "\e96b";
}
.icon-ibm-stop-outline-filled:before {
  content: "\e96c";
}
.icon-ibm-stop_sign:before {
  content: "\e96f";
}
.icon-ibm-stop_sign-filled:before {
  content: "\e970";
}
.icon-ibm-storage_pool:before {
  content: "\e969";
}
.icon-ibm-storage_request:before {
  content: "\e968";
}
.icon-ibm-store:before {
  content: "\e967";
}
.icon-ibm-storm_tracker:before {
  content: "\e966";
}
.icon-ibm-strawberry:before {
  content: "\e965";
}
.icon-ibm-stress_breath_editor:before {
  content: "\e964";
}
.icon-ibm-string_integer:before {
  content: "\e963";
}
.icon-ibm-string_text:before {
  content: "\e962";
}
.icon-ibm-study_next:before {
  content: "\e961";
}
.icon-ibm-study_previous:before {
  content: "\e960";
}
.icon-ibm-study_skip:before {
  content: "\e95f";
}
.icon-ibm-sub_volume:before {
  content: "\e95e";
}
.icon-ibm-subtract:before {
  content: "\e95c";
}
.icon-ibm-subtract-alt:before {
  content: "\e95d";
}
.icon-ibm-summary_KPI:before {
  content: "\e95b";
}
.icon-ibm-sun:before {
  content: "\e95a";
}
.icon-ibm-sunrise:before {
  content: "\e959";
}
.icon-ibm-sunset:before {
  content: "\e958";
}
.icon-ibm-swim:before {
  content: "\e956";
}
.icon-ibm-switcher:before {
  content: "\e955";
}
.icon-ibm-sys_provision:before {
  content: "\e954";
}
.icon-ibm-table:before {
  content: "\e94e";
}
.icon-ibm-table_built:before {
  content: "\e951";
}
.icon-ibm-table_of_contents:before {
  content: "\e950";
}
.icon-ibm-table_split:before {
  content: "\e94f";
}
.icon-ibm-tablet:before {
  content: "\e94c";
}
.icon-ibm-tablet_landscape:before {
  content: "\e94d";
}
.icon-ibm-tag:before {
  content: "\e949";
}
.icon-ibm-tag_edit:before {
  content: "\e94b";
}
.icon-ibm-tag_group:before {
  content: "\e94a";
}
.icon-ibm-task:before {
  content: "\e947";
}
.icon-ibm-task_view:before {
  content: "\e948";
}
.icon-ibm-taste:before {
  content: "\e946";
}
.icon-ibm-taxi:before {
  content: "\e945";
}
.icon-ibm-temperature:before {
  content: "\e93d";
}
.icon-ibm-temperature_feels_like:before {
  content: "\e944";
}
.icon-ibm-temperature_frigid:before {
  content: "\e943";
}
.icon-ibm-temperature_hot:before {
  content: "\e942";
}
.icon-ibm-temperature_inversion:before {
  content: "\e941";
}
.icon-ibm-temperature_max:before {
  content: "\e940";
}
.icon-ibm-temperature_min:before {
  content: "\e93f";
}
.icon-ibm-temperature_water:before {
  content: "\e93e";
}
.icon-ibm-template:before {
  content: "\e93c";
}
.icon-ibm-tennis:before {
  content: "\e93a";
}
.icon-ibm-tennis_ball:before {
  content: "\e93b";
}
.icon-ibm-term:before {
  content: "\e939";
}
.icon-ibm-terminal:before {
  content: "\e938";
}
.icon-ibm-text-_highlight:before {
  content: "\e937";
}
.icon-ibm-text_align_center:before {
  content: "\e936";
}
.icon-ibm-text_align_justify:before {
  content: "\e935";
}
.icon-ibm-text_align_left:before {
  content: "\e934";
}
.icon-ibm-text_align_mixed:before {
  content: "\e933";
}
.icon-ibm-text_align_right:before {
  content: "\e932";
}
.icon-ibm-text_all_caps:before {
  content: "\e931";
}
.icon-ibm-text_annotation_toggle:before {
  content: "\e930";
}
.icon-ibm-text_bold:before {
  content: "\e92f";
}
.icon-ibm-text_clear_format:before {
  content: "\e92e";
}
.icon-ibm-text_color:before {
  content: "\e92d";
}
.icon-ibm-text_creation:before {
  content: "\e92c";
}
.icon-ibm-text_fill:before {
  content: "\e92b";
}
.icon-ibm-text_font:before {
  content: "\e92a";
}
.icon-ibm-text_footnote:before {
  content: "\e929";
}
.icon-ibm-text_indent:before {
  content: "\e926";
}
.icon-ibm-text_indent_less:before {
  content: "\e928";
}
.icon-ibm-text_indent_more:before {
  content: "\e927";
}
.icon-ibm-text_italic:before {
  content: "\e925";
}
.icon-ibm-text_kerning:before {
  content: "\e924";
}
.icon-ibm-text_leading:before {
  content: "\e923";
}
.icon-ibm-text_line_sparcing:before {
  content: "\e922";
}
.icon-ibm-text_link:before {
  content: "\e920";
}
.icon-ibm-text_link_analysis:before {
  content: "\e921";
}
.icon-ibm-text_mining:before {
  content: "\e91e";
}
.icon-ibm-text_mining_applier:before {
  content: "\e91f";
}
.icon-ibm-text_new_line:before {
  content: "\e91d";
}
.icon-ibm-text_scale:before {
  content: "\e91c";
}
.icon-ibm-text_selection:before {
  content: "\e91b";
}
.icon-ibm-text_small_caps:before {
  content: "\e91a";
}
.icon-ibm-text_stikethrough:before {
  content: "\e919";
}
.icon-ibm-text_subcript:before {
  content: "\e918";
}
.icon-ibm-text_superscript:before {
  content: "\e917";
}
.icon-ibm-text_tracking:before {
  content: "\e916";
}
.icon-ibm-text_underline:before {
  content: "\e915";
}
.icon-ibm-text_vertical_alignment:before {
  content: "\e914";
}
.icon-ibm-text_wrap:before {
  content: "\e913";
}
.icon-ibm-theater:before {
  content: "\e912";
}
.icon-ibm-this_side_up:before {
  content: "\e911";
}
.icon-ibm-threshold:before {
  content: "\e910";
}
.icon-ibm-thumbnail-1:before {
  content: "\e90f";
}
.icon-ibm-thumbnail-2:before {
  content: "\e90e";
}
.icon-ibm-thumbs_down:before {
  content: "\e90c";
}
.icon-ibm-thumbs_down-filled:before {
  content: "\e90d";
}
.icon-ibm-thumbs_up:before {
  content: "\e90a";
}
.icon-ibm-thumbs_up-filled:before {
  content: "\e90b";
}
.icon-ibm-thunderstorm:before {
  content: "\e905";
}
.icon-ibm-thunderstorm_scattered:before {
  content: "\e908";
}
.icon-ibm-thunderstorm_scattered_night:before {
  content: "\e909";
}
.icon-ibm-thunderstorm_severe:before {
  content: "\e907";
}
.icon-ibm-thunderstorm_strong:before {
  content: "\e906";
}
.icon-ibm-ticket:before {
  content: "\e904";
}
.icon-ibm-tides:before {
  content: "\e903";
}
.icon-ibm-time:before {
  content: "\e900";
}
.icon-ibm-time_plot:before {
  content: "\e901";
}
.icon-ibm-timer:before {
  content: "\eede";
}
.icon-ibm-tool_box:before {
  content: "\eedf";
}
.icon-ibm-tools:before {
  content: "\eee0";
}
.icon-ibm-tools-alt:before {
  content: "\eee1";
}
.icon-ibm-tornado:before {
  content: "\eee2";
}
.icon-ibm-tornado_warning:before {
  content: "\eee3";
}
.icon-ibm-touch_1:before {
  content: "\eee4";
}
.icon-ibm-touch_1-filled:before {
  content: "\eee5";
}
.icon-ibm-touch_1_down:before {
  content: "\eee6";
}
.icon-ibm-touch_1_down-filled:before {
  content: "\eee7";
}
.icon-ibm-touch_2:before {
  content: "\eee8";
}
.icon-ibm-touch_2-filled:before {
  content: "\eee9";
}
.icon-ibm-touch_interaction:before {
  content: "\eeea";
}
.icon-ibm-traffic_cone:before {
  content: "\eeeb";
}
.icon-ibm-traffic_event:before {
  content: "\eeec";
}
.icon-ibm-traffic_flow:before {
  content: "\eeed";
}
.icon-ibm-traffic_flow_incident:before {
  content: "\eeee";
}
.icon-ibm-traffic_incident:before {
  content: "\eeef";
}
.icon-ibm-traffic_weather_incident:before {
  content: "\eef0";
}
.icon-ibm-train:before {
  content: "\eef1";
}
.icon-ibm-train_heart:before {
  content: "\eef2";
}
.icon-ibm-train_ticket:before {
  content: "\eef3";
}
.icon-ibm-train_time:before {
  content: "\eef4";
}
.icon-ibm-tram:before {
  content: "\eef5";
}
.icon-ibm-transgender:before {
  content: "\eef6";
}
.icon-ibm-translate:before {
  content: "\eef7";
}
.icon-ibm-transpose:before {
  content: "\eef8";
}
.icon-ibm-trash_can:before {
  content: "\eef9";
}
.icon-ibm-tree:before {
  content: "\eefa";
}
.icon-ibm-tree_view:before {
  content: "\eefb";
}
.icon-ibm-tree_view-alt:before {
  content: "\eefc";
}
.icon-ibm-trophy:before {
  content: "\eefd";
}
.icon-ibm-trophy-filled:before {
  content: "\eefe";
}
.icon-ibm-tropical_storm:before {
  content: "\eeff";
}
.icon-ibm-tropical_storm_model_tracks:before {
  content: "\ef00";
}
.icon-ibm-tropical_storm_tracks:before {
  content: "\ef01";
}
.icon-ibm-tropical_warning:before {
  content: "\ef02";
}
.icon-ibm-tsunami:before {
  content: "\ef03";
}
.icon-ibm-tumbnail_preview:before {
  content: "\ef04";
}
.icon-ibm-two_person_lift:before {
  content: "\ef05";
}
.icon-ibm-type_pattern:before {
  content: "\ef06";
}
.icon-ibm-types:before {
  content: "\ef07";
}
.icon-ibm-umbrella:before {
  content: "\ef08";
}
.icon-ibm-undefined:before {
  content: "\ef09";
}
.icon-ibm-undefined-filled:before {
  content: "\ef0a";
}
.icon-ibm-undo:before {
  content: "\ef0b";
}
.icon-ibm-unknown-filled:before {
  content: "\ef0c";
}
.icon-ibm-unkonwn:before {
  content: "\ef0d";
}
.icon-ibm-unlink:before {
  content: "\ef0e";
}
.icon-ibm-unlocked:before {
  content: "\ef0f";
}
.icon-ibm-up_to_top:before {
  content: "\ef10";
}
.icon-ibm-upgrade:before {
  content: "\ef11";
}
.icon-ibm-upload:before {
  content: "\ef12";
}
.icon-ibm-user:before {
  content: "\ef13";
}
.icon-ibm-user-admin:before {
  content: "\ef14";
}
.icon-ibm-user-favorite:before {
  content: "\ef15";
}
.icon-ibm-user-favorite-alt:before {
  content: "\ef16";
}
.icon-ibm-user-favorite-alt-filled:before {
  content: "\ef17";
}
.icon-ibm-user-filled:before {
  content: "\ef18";
}
.icon-ibm-user-follow:before {
  content: "\ef19";
}
.icon-ibm-user-identification:before {
  content: "\ef1a";
}
.icon-ibm-user-online:before {
  content: "\ef1b";
}
.icon-ibm-user-profile:before {
  content: "\ef1c";
}
.icon-ibm-user-role:before {
  content: "\ef1d";
}
.icon-ibm-user-x-ray:before {
  content: "\ef1e";
}
.icon-ibm-user_access:before {
  content: "\ef1f";
}
.icon-ibm-user_activity:before {
  content: "\ef20";
}
.icon-ibm-user_avatar:before {
  content: "\ef21";
}
.icon-ibm-user_avatar-filled:before {
  content: "\ef22";
}
.icon-ibm-user_avatar-filled-alt:before {
  content: "\ef23";
}
.icon-ibm-user_cerrtification:before {
  content: "\ef24";
}
.icon-ibm-user_data:before {
  content: "\ef25";
}
.icon-ibm-user_multiple:before {
  content: "\ef26";
}
.icon-ibm-user_profile-alt:before {
  content: "\ef27";
}
.icon-ibm-user_simulation:before {
  content: "\ef28";
}
.icon-ibm-user_speaker:before {
  content: "\ef29";
}
.icon-ibm-uv_index:before {
  content: "\ef2a";
}
.icon-ibm-uv_index-alt:before {
  content: "\ef2b";
}
.icon-ibm-uv_index-filled:before {
  content: "\ef2c";
}
.icon-ibm-van:before {
  content: "\ef2d";
}
.icon-ibm-video:before {
  content: "\ef2e";
}
.icon-ibm-video-filled:before {
  content: "\ef2f";
}
.icon-ibm-video_add:before {
  content: "\ef30";
}
.icon-ibm-video_chat:before {
  content: "\ef31";
}
.icon-ibm-video_off:before {
  content: "\ef32";
}
.icon-ibm-video_off-filled:before {
  content: "\ef33";
}
.icon-ibm-view:before {
  content: "\ef34";
}
.icon-ibm-view-filled:before {
  content: "\ef35";
}
.icon-ibm-view_mode_1:before {
  content: "\ef36";
}
.icon-ibm-view_mode_2:before {
  content: "\ef37";
}
.icon-ibm-view_next:before {
  content: "\ef38";
}
.icon-ibm-view_off:before {
  content: "\ef39";
}
.icon-ibm-view_off-filled:before {
  content: "\ef3a";
}
.icon-ibm-virtual_cloumn:before {
  content: "\ef3b";
}
.icon-ibm-virtual_column_key:before {
  content: "\ef3c";
}
.icon-ibm-virtual_machine:before {
  content: "\ef3d";
}
.icon-ibm-virtual_private_cloud:before {
  content: "\ef3e";
}
.icon-ibm-virtual_private_cloud-alt:before {
  content: "\ef3f";
}
.icon-ibm-visual_recognition:before {
  content: "\ef40";
}
.icon-ibm-vmdk_disk:before {
  content: "\ef41";
}
.icon-ibm-voice_activate:before {
  content: "\ef42";
}
.icon-ibm-voicemail:before {
  content: "\ef43";
}
.icon-ibm-volume_down:before {
  content: "\ef44";
}
.icon-ibm-volume_down-filled:before {
  content: "\ef45";
}
.icon-ibm-volume_down-filled-alt:before {
  content: "\ef46";
}
.icon-ibm-volume_down_alt:before {
  content: "\ef47";
}
.icon-ibm-volume_mute:before {
  content: "\ef48";
}
.icon-ibm-volume_mute-filled:before {
  content: "\ef49";
}
.icon-ibm-volume_up:before {
  content: "\ef4a";
}
.icon-ibm-volume_up-filled .path1:before {
  content: "\ef4b";
  color: rgb(0, 0, 0);
}
.icon-ibm-volume_up-filled .path2:before {
  content: "\ef4c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-volume_up-filled .path3:before {
  content: "\ef4d";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-ibm-volume_up-filled-alt:before {
  content: "\ef4e";
}
.icon-ibm-volume_up_alt:before {
  content: "\ef4f";
}
.icon-ibm-wallet:before {
  content: "\ef50";
}
.icon-ibm-warning:before {
  content: "\ef51";
}
.icon-ibm-warning-filled:before {
  content: "\ef52";
}
.icon-ibm-warning_alt:before {
  content: "\ef53";
}
.icon-ibm-warning_alt-filled:before {
  content: "\ef54";
}
.icon-ibm-warning_alt_inverted:before {
  content: "\ef55";
}
.icon-ibm-warning_alt_inverted-filled:before {
  content: "\ef56";
}
.icon-ibm-warning_hex:before {
  content: "\ef57";
}
.icon-ibm-warning_hex-filled:before {
  content: "\ef58";
}
.icon-ibm-warning_other:before {
  content: "\ef59";
}
.icon-ibm-warning_square:before {
  content: "\ef5a";
}
.icon-ibm-warning_square-filled:before {
  content: "\ef5b";
}
.icon-ibm-watch:before {
  content: "\ef5c";
}
.icon-ibm-watson:before {
  content: "\ef5d";
}
.icon-ibm-watson_matchine_learning:before {
  content: "\ef5e";
}
.icon-ibm-wave_direction:before {
  content: "\ef5f";
}
.icon-ibm-wave_height:before {
  content: "\ef60";
}
.icon-ibm-wave_period:before {
  content: "\ef61";
}
.icon-ibm-weather_front_cold:before {
  content: "\ef62";
}
.icon-ibm-weather_front_stationary:before {
  content: "\ef63";
}
.icon-ibm-weather_front_warm:before {
  content: "\ef64";
}
.icon-ibm-weather_station:before {
  content: "\ef65";
}
.icon-ibm-websheet:before {
  content: "\ef66";
}
.icon-ibm-wheat:before {
  content: "\ef67";
}
.icon-ibm-wifi:before {
  content: "\ef68";
}
.icon-ibm-wifi_off:before {
  content: "\ef69";
}
.icon-ibm-wikis:before {
  content: "\ef6a";
}
.icon-ibm-wind_gusts:before {
  content: "\ef6b";
}
.icon-ibm-wind_stream:before {
  content: "\ef6c";
}
.icon-ibm-window_auto:before {
  content: "\ef6d";
}
.icon-ibm-window_base:before {
  content: "\ef6e";
}
.icon-ibm-window_overlay:before {
  content: "\ef6f";
}
.icon-ibm-window_preset .path1:before {
  content: "\ef70";
  color: rgb(35, 31, 32);
}
.icon-ibm-window_preset .path2:before {
  content: "\ef71";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-window_preset .path3:before {
  content: "\ef72";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-window_preset .path4:before {
  content: "\ef73";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-window_preset .path5:before {
  content: "\ef74";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-window_preset .path6:before {
  content: "\ef75";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-window_preset .path7:before {
  content: "\ef76";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-window_preset .path8:before {
  content: "\ef77";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-window_preset .path9:before {
  content: "\ef78";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-window_preset .path10:before {
  content: "\ef79";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ibm-windy:before {
  content: "\ef7a";
}
.icon-ibm-windy_dust:before {
  content: "\ef7b";
}
.icon-ibm-windy_snow:before {
  content: "\ef7c";
}
.icon-ibm-windy_strong:before {
  content: "\ef7d";
}
.icon-ibm-winter_warning:before {
  content: "\ef7e";
}
.icon-ibm-wintry_mix:before {
  content: "\ef7f";
}
.icon-ibm-wireless_checkout:before {
  content: "\ef80";
}
.icon-ibm-word_cloud:before {
  content: "\ef81";
}
.icon-ibm-workspace:before {
  content: "\ef82";
}
.icon-ibm-workspace_import:before {
  content: "\ef83";
}
.icon-ibm-worship:before {
  content: "\ef84";
}
.icon-ibm-worship_christian:before {
  content: "\ef85";
}
.icon-ibm-worship_jewish:before {
  content: "\ef86";
}
.icon-ibm-worship_muslim:before {
  content: "\ef87";
}
.icon-ibm-x_axis:before {
  content: "\ef88";
}
.icon-ibm-y_axis:before {
  content: "\ef89";
}
.icon-ibm-z_axis:before {
  content: "\ef8a";
}
.icon-ibm-zoom_area:before {
  content: "\ef8b";
}
.icon-ibm-zoom_fit:before {
  content: "\ef8c";
}
.icon-ibm-zoom_in:before {
  content: "\ef8d";
}
.icon-ibm-zoom_in_area:before {
  content: "\ef8e";
}
.icon-ibm-zoom_out:before {
  content: "\ef8f";
}
.icon-ibm-zoom_out_area:before {
  content: "\ef90";
}
.icon-ibm-zoom_pan:before {
  content: "\ef91";
}
.icon-ibm-zoom_reset:before {
  content: "\ef92";
}
.icon-mdi-outline--arrow_back_ios:before {
  content: "\ef93";
}
.icon-mdi-outline--arrow_forward_ios:before {
  content: "\ef94";
}
.icon-mdi-outline--run:before {
  content: "\ef95";
}
.icon-mdi-round--arrow_drop_down:before {
  content: "\ef96";
}
.icon-mdi-round--arrow_drop_up:before {
  content: "\ef97";
}
