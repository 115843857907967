@import "utilities";



:root {
  --viewportWidth: 100vw;
  --viewportHeight: 100vh;
}

.mainContent {
  overflow: hidden;
}
.center-block {
  @include center-block;
}

// CONTAINERs, ROWs y COLs
.container-fluid {
  /*width: 100%; - no es necesario establecer el width ya que un div, salvo que se indique lo contrario ocupa el 100% de su padre*/
  @include container;
}
.container {
  @include container-lg;
  @include container-md;
  @include container-sm;
  @include container-xs;
}
.row {
  @include row;
}

@for $i from 1 through 12 {
  .col-#{$i} {
    @include col($i);
  }
  .inset-#{$i} {
    @include inset($i);
  }
  .offset-#{$i} {
    @include offset($i);
  }
}
// tablet vertical
@media ($mediaFromTabletV) {
  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      @include col($i);
    }
    .inset-sm-#{$i} {
      @include inset($i);
    }
    .offset-sm-#{$i} {
      @include offset($i);
    }
  }
}
// tablet horizontal
@media ($mediaFromTabletH) {
  @for $i from 1 through 12 {
    .col-md-#{$i} {
      @include col($i);
    }
    .inset-md-#{$i} {
      @include inset($i);
    }
    .offset-md-#{$i} {
      @include offset($i);
    }
  }
}
// desktop
@media ($mediaDesktop) {
  @for $i from 1 through 12 {
    .col-lg-#{$i} {
      @include col($i);
    }
    .inset-lg-#{$i} {
      @include inset($i);
    }
    .offset-lg-#{$i} {
      @include offset($i);
    }
  }
}
