@use '~@angular/material' as mat;
@import "variables/variables";
@import "mixins/flex.scss";

.ngx-mi-avatar {
  .avatar-content {
    background-color: mat.get-color-from-palette($mat-primary, 50) !important;
    color: $primaryColor !important;
    font-family: $primaryFont !important;
    font-weight: 700 !important;
    font-size: 28px !important;
  }
}

.avatar-txt,
.lnk-avatar {
  font-family: $primaryFont !important;
  @include flex;
  @include align-items-center;

  span {
    margin-left: 8px;
    // white-space: nowrap;
    display: block;
  }
}

.lnk-avatar {
  @include justify-content-center;
}

