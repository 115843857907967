@import "variables/colors";

.mat-drawer-container {
  background-color: #fafafa;
  color: $dark-primary-text;
}
.mat-drawer {
  background-color: $white;
  color: $dark-primary-text;
  &.mat-drawer-push {
    background-color: $white;
  }
  &:not(.mat-drawer-side) {
    box-shadow: 0px 8px 10px -5px rgba($black, 0.2), 0px 16px 24px 2px rgba($black, 0.14), 0px 6px 30px 5px rgba($black, 0.12);
  }
}

.mat-drawer-side {
  border-right: solid 1px $dark-dividers;
  &.mat-drawer-end {
    border-left: solid 1px $dark-dividers;
    border-right: none;
  }
}

[dir=rtl] {
  .mat-drawer-side {
    border-left: solid 1px $dark-dividers;
    border-right: none;
    &.mat-drawer-end {
      border-left: none;
      border-right:solid 1px $dark-dividers;
    }
  }
}
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba($black, 0.6);
}
