@import "variables/colors";
@import "mixins/flex";

.mat-pseudo-checkbox {
  width: 16px;
  height: 16px;
  padding: 0;
  margin-right: 20px;
  // border: 2px solid $black;
  border: 1px solid $black;
  position: relative;

  /*font-family: $primaryFont;*/

  &.mat-pseudo-checkbox-checked {
    border-width: 0;
    
    &:after {
      content: "\e948";
      width: 16px;
      height: 16px;
      border: 0;
      @include icono;
      font-size: 14px;
      @include inline-flex;
      @include justify-content-center;
      @include align-items-center;
      position: absolute;
      left: 0;
      top: 0; 
      transform: none;
      transition: none;
    }
  }

  .mat-option + .mat-option {
    margin-top: 1px;
  }

  & + .mat-option-text {
    @include text-p6;
  }
}
// .mat-pseudo-checkbox-checkmark {
//   fill: #fafafa;
// }
// .mat-pseudo-checkbox-checkmark-path {
//   stroke: #fafafa !important;
// }
// .mat-pseudo-checkbox-mixedmark {
//   background-color: #fafafa;
// }
.mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked {
  .mat-pseudo-checkbox-background,
  //por defecto toma como color el primary, si se quiere establecer el negro introducir por ejemplo [color]="'black'" en <mat-pseudo-checkbox>
  //generalmente este componente no se usa solo, se inyecta como parte de un select, por lo que tomará el color que se haya establecido en el select
  &:not([class*=mat-primary]):not([class*=mat-accent]):not([class*=mat-warn]),
  &.mat-black {
    .mat-pseudo-checkbox-background {
      background-color: $black;
    }
  }
  &.mat-primary {
    .mat-pseudo-checkbox-background {
      background-color: $primaryColor;
    }
  }
  &.mat-accent {
    .mat-pseudo-checkbox-background {
      background-color: $secondaryColor;
    }
  }
  &.mat-warn {
    .mat-pseudo-checkbox-background {
      background-color: $danger;
    }
  }
}
.mat-pseudo-checkbox-disabled {
  &.mat-pseudo-checkbox-checked,
  &.mat-pseudo-checkbox-indeterminate {
    .mat-pseudo-checkbox-background {
      background-color: #b0b0b0;
    }
  }
  &:not(.mat-pseudo-checkbox-checked) {
    .mat-pseudo-checkbox-frame {
      border-color: #b0b0b0;
    }
  }
  .mat-pseudo-checkbox-label {
    color: $dark-secondary-text;
  }
}

.mat-primary {
  .mat-option.mat-selected:not(.mat-option-disabled) {
    .mat-pseudo-checkbox-checked {
      background-color: $primaryColor;
      color: $white;
    }
  }
} 
.mat-accent {
  .mat-option.mat-selected:not(.mat-option-disabled) {
    .mat-pseudo-checkbox-checked {
      background-color: $secondaryColor;
      color: $white;
    }
  }
} 
.mat-warn {
  .mat-option.mat-selected:not(.mat-option-disabled) {
    .mat-pseudo-checkbox-checked {
      background-color: $danger;
      color: $white;
    }
  }
} 

// .mat-pseudo-checkbox-checked,
// .mat-pseudo-checkbox:active {
//   &:not(.mat-pseudo-checkbox-disabled) {
//     &.mat-primary {
//       .mat-ripple-element {
//         background: $primaryColor;
//       }
//     }
//     &.mat-accent {
//       .mat-ripple-element {
//         background: $secondaryColor;
//       }
//     }
//     &.mat-warn {
//       .mat-ripple-element {
//         background: $danger;
//       }
//     }
//   }
// }

// .mat-pseudo-checkbox {
//   color: rgba($black, 0.54);
//   &::after {
//     color: #fafafa;
//   }
// }
// .mat-pseudo-checkbox-disabled {
//   color: #b0b0b0;
// }
// .mat-primary {
//   .mat-pseudo-checkbox-checked,
//   .mat-pseudo-checkbox-indeterminate {
//     background: $primaryColor;
//   }
// }
// .mat-pseudo-checkbox-checked,
// .mat-pseudo-checkbox-indeterminate,
// .mat-accent .mat-pseudo-checkbox-checked,
// .mat-accent .mat-pseudo-checkbox-indeterminate {
//   background: $secondaryColor;
// }
// .mat-warn {
//   .mat-pseudo-checkbox-checked,
//   .mat-pseudo-checkbox-indeterminate {
//     background: $danger;
//   }
// }
// .mat-pseudo-checkbox-checked,
// .mat-pseudo-checkbox-indeterminate {
//   &.mat-pseudo-checkbox-disabled {
//     background: #b0b0b0;
//   }
// }