@import "variables/colors";
@import "mixins/flex";
@import "mixins/tipography.scss";

.mat-checkbox {
  /*font-family: $primaryFont;*/
  .mat-checkbox-layout {
    .mat-checkbox-inner-container {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      line-height: 0;
      order: 0;
      position: relative;
      vertical-align: middle;
      white-space: nowrap;

      .mat-checkbox-frame {
        border-color: $black;
        border-width: 1px;
        border-radius: 2px;
      }
    }
    .mat-checkbox-label {
      // @include text-h4;
      @include text-p5;
    }
    .mat-ripple-element {
      background-color: $black;
    }

    .mat-checkbox-background {
      border-radius: 2px;
    }
  }
  &,
  &.mat-checkbox-start {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        margin-left: 0;
        margin-right: 5px;
        order: 0;
      }
    }
  }
  &.mat-checkbox-end {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        margin-left: 5px;
        margin-right: 0;
        order: 1;
      }
    }
  }
  &.mat-checkboxlg- {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        width: 16px;
        height: 16px;
        margin-right: 12px;

        // .mat-checkbox-frame {
        //   border-width: 2px;
        // }
      }

      .mat-checkbox-label {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  &.checkbox-with-icon {
    .mat-checkbox-layout {
      .mat-checkbox-label {
        @include inline-flex;
        @include align-items-center;
        [class^="icon-"], [class*=" icon-"] {
          margin-left: 10px;
          margin-right: 6px;
        }
      }
    }
  }

  &.mat-checkbox-indeterminate {

    .mat-checkbox-background {
      width: 100%;
      height: 100%;
      position: relative;

      &:after {
        content: "\e90f";
        @include icono;
        font-size: 14px;
        position: absolute;
        left: -1;
        top: -1;
      }

      .mat-checkbox-checkmark {
        display: none;
      }

      &.mat-checkbox-lg {
        .mat-checkbox-background {    
          &:after {
            font-size: 18px;
          }
        }
      }
    }

    &:not([class*=mat-primary]):not([class*=mat-accent]):not([class*=mat-warn]),
    &.mat-black{
      .mat-checkbox-frame {
        border-color: $black;
      }
      .mat-checkbox-background {
        background-color: transparent;
        &:after {
          color: $black;
        }
      }
    }    
    &.mat-primary {
      .mat-checkbox-frame {
        border-color: $primaryColor;
      }
      .mat-checkbox-background {
        background-color: transparent;
        &:after {
          color: $primaryColor;
        }
      }
    }
    &.mat-accent {
      .mat-checkbox-frame {
        border-color: $secondaryColor;
      }
      .mat-checkbox-background {
        background-color: transparent;
        &:after {
          color: $secondaryColor;
        }
      }
    }
    &.mat-warn {
      .mat-checkbox-frame {
        border-color: $danger;
      }
      .mat-checkbox-background {
        background-color: transparent;
        &:after {
          color: $danger;
        }
      }
    }
  }
}
// .mat-checkbox-checkmark {
//   fill: #fafafa;
// }
// .mat-checkbox-checkmark-path {
//   stroke: #fafafa !important;
// }
// .mat-checkbox-mixedmark {
//   background-color: #fafafa;
// }
.mat-checkbox-indeterminate,
.mat-checkbox-checked {
  .mat-checkbox-background,
  //por defecto toma como color el primary, si se quiere establecer el negro introducir por ejemplo [color]="'black'" en <mat-checkbox>
  &:not([class*=mat-primary]):not([class*=mat-accent]):not([class*=mat-warn]),
  &.mat-black{
    .mat-checkbox-frame {
      border-color: $black;
    }
    .mat-checkbox-background {
      background-color: $black;
    }
  }
  &.mat-primary {
    .mat-checkbox-frame {
      border-color: $primaryColor;
    }
    .mat-checkbox-background {
      background-color: $primaryColor;
    }
  }
  &.mat-accent {
    .mat-checkbox-frame {
      border-color: $secondaryColor;
    }
    .mat-checkbox-background {
      background-color: $secondaryColor;
    }
  }
  &.mat-warn {
    .mat-checkbox-frame {
      border-color: $danger;
    }
    .mat-checkbox-background {
      background-color: $danger;
    }
  }
}
.mat-checkbox-disabled {
  &.mat-checkbox-checked,
  &.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background-color: #b0b0b0;
    }
  }
  &:not(.mat-checkbox-checked) {
    .mat-checkbox-frame {
      border-color: #b0b0b0;
    }
  }
  .mat-checkbox-label {
    color: $dark-secondary-text;
  }
}

.mat-checkbox-checked,
.mat-checkbox:active {
  &:not(.mat-checkbox-disabled) {
    &.mat-primary {
      .mat-ripple-element {
        background: $primaryColor;
      }
    }
    &.mat-accent {
      .mat-ripple-element {
        background: $secondaryColor;
      }
    }
    &.mat-warn {
      .mat-ripple-element {
        background: $danger;
      }
    }
  }
}

// ::ng-deep form {
//   .form-campo-checkbox {
//     margin-bottom: 10px;
//   }
// }