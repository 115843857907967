@import "variables/colors";

.mat-ripple {
  overflow: hidden;
  position: relative;
  &:not(:empty) {
    transform: translateZ(0);
  }
  &.mat-ripple-unbounded {
    overflow: visible;
  }
}
.mat-ripple-element {
  background-color: rgba($black, 0.1);
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  transition: opacity,transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active {
  .mat-ripple-element {
    display: none;
  }
}
