@import "variables/colors";

.mat-table {
  background: $white;
  /*font-family: $primaryFont;*/
}
.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.mat-cell,
.mat-footer-cell {
  color: rgba($black, 0.87);
  font-size: 14px;
}
.mat-table thead,
.mat-table tbody,
.mat-table tfoot,
.mat-table-sticky {
  background: inherit;
}
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-color: rgba($black, 0.12);
}
.mat-header-cell {
  color: rgba($black, 0.54);
}