@use '~@angular/material' as mat;
@import "variables/colors";

.mat-slide-toggle {

  height: auto;

  /* configuración general */
  .mat-slide-toggle-bar {
    width: 70px;
    height: 40px;
    background-color: $grey2;
    border-radius: 20px;
  }
  .mat-slide-toggle-thumb-container {
    width: 32px;
    height: 32px;
    top: 4px;
    left: 4px;

    .mat-slide-toggle-thumb {
      width: 100%;
      height: 100%;
      background-color: $white;
      box-shadow: 0px 2px 1px -1px rgba($black, 0.2),0px 1px 1px 0px rgba($black, 0.14),0px 1px 3px 0px rgba($black, 0.12);
    }
  }

  &.mat-checked {
    .mat-slide-toggle-thumb-container {
      transform: translateX(30px);

      .mat-slide-toggle-thumb {
        background-color: $grey3;
      }
    }
  }

  /* sombreado en active- hover */
  .mat-ripple-element {
    background-color: $grey4;
  }

  /* colores - general */
  &.mat-primary {
    &.mat-checked {
      .mat-slide-toggle-thumb,
      .mat-ripple-element {
        background-color: $primaryColor;
      }
      .mat-slide-toggle-bar {
        background-color: mat.get-color-from-palette($mat-primary, 50);
      }
    }
  }
  &.mat-accent {
    &.mat-checked {
      .mat-slide-toggle-thumb,
      .mat-ripple-element {
        background-color: $secondaryColor;
      }
      .mat-slide-toggle-bar {
        background-color: mat.get-color-from-palette($mat-secondary, 50);
      }
    }
  }
  &.mat-warn {
    &.mat-checked {
      .mat-slide-toggle-thumb,
      .mat-ripple-element {
        background-color: $danger;
      }
      .mat-slide-toggle-bar {
        background-color: mat.get-color-from-palette($mat-danger, 50);
      }
    }
  }

  /* toggle-xs */
  &.toggle-xs {
    .mat-slide-toggle-bar {
      width: 54px;
      height: 24px;
      border-radius: 12px;
    }
    .mat-slide-toggle-thumb-container {
      width: 20px;
      height: 20px;
      top: 2px;
      left: 2px;
    }
    &.mat-checked {
      .mat-slide-toggle-thumb-container {
        transform: translateX(30px);
      }
    }
  }

  /* toggle-on-off */
  &.toggle-on-off {
    .mat-slide-toggle-bar:after {
      content: 'off';
      @include text-p7;
      text-transform: uppercase;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0,-50%);
    }

    &.mat-checked {
      .mat-slide-toggle-bar:after {
        content: 'on';
        transform: translate(-34px,-50%);
      }
    }

    &.mat-primary {
      &.mat-checked {
        .mat-slide-toggle-bar:after {
          color: $primaryColor;
        }
      }
    }
    &.mat-accent {
      &.mat-checked {
        .mat-slide-toggle-bar:after {
          color: $secondaryColor;
        }
      }
    }
    &.mat-warn {
      &.mat-checked {
        .mat-slide-toggle-bar:after {
          color: $danger;
        }
      }
    }

    &.toggle-xs {
      .mat-slide-toggle-bar:after {
        @include text-h4;
        right: 6px;
      }

      &.mat-checked {
        .mat-slide-toggle-bar:after {
          transform: translate(-25px,-50%);
        }
      }
    }
  }

  /* toggle-icon */
  &.toggle-icon {

    .mat-slide-toggle-bar:after {
      @include icono;
      font-size: 16px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0,-50%);
    }
    &.mat-checked {
      .mat-slide-toggle-bar:after {
        right: auto;
        left: 10px;
      }
    }

    &.toggle-list-table {
      .mat-slide-toggle-bar:after {
        content: "\e937";
      }
      .mat-slide-toggle-thumb-container {
        .mat-slide-toggle-thumb:after {
          content: "\e938";
          color: $white;
          @include icono;
          font-size: 16px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &.mat-checked {
        .mat-slide-toggle-bar:after {
          content: "\e938";
        }

        .mat-slide-toggle-thumb-container {
          .mat-slide-toggle-thumb:after {
            content: "\e937";
          }
        }
      }
    }

    &.mat-primary {
      .mat-slide-toggle-bar {
        background-color: mat.get-color-from-palette($mat-primary, 50);
        &:after {
          color: $primaryColor;
        }
      }
      .mat-slide-toggle-thumb {
        background-color: $primaryColor;

        &:after {
          color: $primaryColor;
        }
      }
      .mat-ripple-element {
        background-color: $primaryColor;
      }
    }
    &.mat-accent {
      .mat-slide-toggle-bar {
        background-color: mat.get-color-from-palette($mat-secondary, 50);
        &:after {
          color: $secondaryColor;
        }
      }
      .mat-slide-toggle-thumb {
        background-color: $secondaryColor;

        &:after {
          color: $secondaryColor;
        }
      }
      .mat-ripple-element {
        background-color: $secondaryColor;
      }
    }
    &.mat-warn {
      .mat-slide-toggle-bar {
        background-color: mat.get-color-from-palette($mat-danger, 50);
        &:after {
          color: $danger;
        }
      }
      .mat-slide-toggle-thumb {
        background-color: $danger;
        &:after {
          color: $danger;
        }
      }
      .mat-ripple-element {
        background-color: $danger;
      }
    }
  }
}
