@use '~@angular/material' as mat;
@import "variables/colors";
@import "mixins/flex.scss";

.mat-autocomplete-panel {
  padding: 10px;
  background: $white;

  &:not([class*=mat-elevation-z]) {
    box-shadow: 0px 2px 4px -1px rgba($black, 0.2), 0px 4px 5px 0px rgba($black, 0.14), 0px 1px 10px 0px rgba($black, 0.12);
  }

  .mat-option {
    height: auto;
    min-height: 57px;
    padding: 8px 10px;
    border-radius: 4px;
    color: $black;
    font-size: 16px;

    & + .mat-option {
      margin-top: 1px;
    }

    &:hover,
    &:focus {
      &:not(.mat-option-disabled) {
        background: rgba($black, 0.04);
      }
    }
    &.mat-selected {
      &:not(.mat-option-multiple) {
        background: rgba($black, 0.12);

        &:not(.mat-option-disabled) {
          background: rgba($black, 0.04)
        }
      }

      &:not(.mat-active):not(:hover) {
        background: $white;
  
        &:not(.mat-option-disabled) {
          color: $black;
        }
      }
    }

    &.mat-active {
      background: rgba($black, 0.04);
      color: rgba($black, 0.87);
    }
    &.mat-option-disabled {
      color: rgba($black, 0.38);
    }
  }
}
  
.mat-primary {
  .mat-option.mat-selected:not(.mat-option-disabled) {    
    background-color: mat.get-color-from-palette($mat-primary, 50);
    color: $primaryColor;
  }
}
.mat-accent {
  .mat-option.mat-selected:not(.mat-option-disabled) {
    background-color: mat.get-color-from-palette($mat-secondary, 50);
    color: $secondaryColor;
  }
}
.mat-warn {
  .mat-option.mat-selected:not(.mat-option-disabled) {
    background-color: mat.get-color-from-palette($mat-danger, 50);
    color: $danger;
  }
}

.mat-optgroup-label {
  color: $black;
  /*font-family: $primaryFont;*/
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: normal;
}
.mat-optgroup-disabled {
  .mat-optgroup-label {
    color: rgba($black, 0.38);
  }
}
