@import "variables/colors";
@import "mixins/tipography.scss";


.mat-paginator {
  background: $white;

  &,
  .mat-paginator-page-size .mat-select-trigger {
    color: rgba($black, 0.54);
    /*font-family: $primaryFont;*/
    font-size: 14px;
    line-height: 20px;
  }

  .mat-paginator-container {
    min-height: 56px;
    @include justify-content-center;

    .mat-form-field-infix {
      width: 50px;
      padding: 2px;
    }

    .mat-paginator-decrement,
    .mat-paginator-increment {
      border-top: 2px solid rgba($black, 0.54);
      border-right: 2px solid rgba($black, 0.54);
    }
    .mat-paginator-first,
    .mat-paginator-last {
      border-top: 2px solid rgba($black, 0.54);
    }
    .mat-icon-button[disabled] {
      .mat-paginator-decrement,
      .mat-paginator-increment,
      .mat-paginator-first,
      .mat-paginator-last {
        border-color: rgba($black, 0.38);
      }
    }

    .mat-paginator-page-size-label {
      white-space: nowrap;
    }
  }

}

