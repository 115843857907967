@use '~@angular/material' as mat;
@import "variables/colors";

/*.mat-calendar {
  font-family: $primaryFont;
}*/
.mat-calendar-body {
  font-size: 14px;
}
.mat-calendar-arrow {
  border-top-color: rgba($black, 0.54);
  width: auto;
  height: auto;
  border: 0;

  &:before {
    content: "\e900";
    @include icono;
    font-size: 20px;
  }
}
.mat-calendar-arrow {
  border-top-color: rgba($black, 0.54);
}
.mat-calendar-body-label {
  opacity: 0;
}
.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.mat-calendar-table-header th {
  color: $grey3;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.mat-calendar-body-in-preview {
  color: rgba($black, 0.24);
}
.mat-calendar-body-today {
  &:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: rgba($black, 0.38);
  }
  &.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px $white;
  }
}
.mat-calendar-body-disabled {
  & > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: rgba($black, 0.18);
  }
  & > .mat-calendar-body-selected {
    background-color: rgba(mat.get-color-from-palette($mat-primary, 900), 0.4);
  }
  & > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: $dark-disabled-text;
  }
}
.mat-calendar-body-in-range::before {
  background: rgba(mat.get-color-from-palette($mat-primary, 900), 0.2);
}
.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(mat.get-color-from-palette($mat-warning, 900), 0.2);
}
.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background:linear-gradient(to right, rgba(mat.get-color-from-palette($mat-primary, 900), 0.2) 50%, rgba(mat.get-color-from-palette($mat-warning, 900), 0.2) 50%);
}
.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background:linear-gradient(to left, rgba(mat.get-color-from-palette($mat-primary, 900), 0.2) 50%, rgba(mat.get-color-from-palette($mat-warning, 900), 0.2) 50%);
}
.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: mat.get-color-from-palette($mat-success, 200);
}
.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: $success;
}
.mat-calendar-body-selected {
  background-color: $primaryColor;
  color: $white!important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover,
.cdk-keyboard-focused .mat-calendar-body-active,
.cdk-program-focused .mat-calendar-body-active {
  & > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(mat.get-color-from-palette($mat-primary, 900), 0.3);
  }
}
.mat-calendar-table-header,
.mat-calendar-body-label {
  color: $dark-disabled-text;
}
.mat-calendar-table-header-divider::after {
  background: $dark-dividers;
}

.mat-calendar-body-cell-content {
  border-color: transparent;
  color: $dark-primary-text;
}
