@font-face {
  font-family: 'icomoon';
  src:  url('../../assets/fonts/icomoon/fonts/icomoon.eot?fviuat');
  src:  url('../../assets/fonts/icomoon/fonts/icomoon.eot?fviuat#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icomoon/fonts/icomoon.ttf?fviuat') format('truetype'),
    url('../../assets/fonts/icomoon/fonts/icomoon.woff?fviuat') format('woff'),
    url('../../assets/fonts/icomoon/fonts/icomoon.svg?fviuat#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ibm--blocked:before {
  content: "\e94d";
}
.icon-ibm--circle--solid:before {
  content: "\e94c";
}
.icon-ibm--warning--filled:before {
  content: "\e94b";
}
.icon-dua--caret-sort:before {
  content: "\e94a";
}
.icon-ibm--notification:before {
  content: "\e949";
}
.icon-ibm--checkmark:before {
  content: "\e948";
}
.icon-ibm--copy:before {
  content: "\e947";
}
.icon-ibm--currency--euro:before {
  content: "\e945";
}
.icon-ibm--chevron--sort:before {
  content: "\e946";
}
.icon-ibm--time:before {
  content: "\e944";
}
.icon-ibm--calendar:before {
  content: "\e93f";
}
.icon-ibm--up-to-top:before {
  content: "\e940";
}
.icon-ibm--down-to-bottom:before {
  content: "\e941";
}
.icon-ibm--chevron--up:before {
  content: "\e942";
}
.icon-ibm--chevron--down:before {
  content: "\e943";
}
.icon-ibm--page--last:before {
  content: "\e93b";
}
.icon-ibm--page--first:before {
  content: "\e93c";
}
.icon-ibm--chevron--right:before {
  content: "\e93d";
}
.icon-ibm--chevron--left:before {
  content: "\e93e";
}
.icon-ibm--document--blank:before {
  content: "\e93a";
}
.icon-ibm--help--filled:before {
  content: "\e92e";
}
.icon-ibm--close--outline:before {
  content: "\e92f";
}
.icon-ibm--warning--alt:before {
  content: "\e930";
}
.icon-ibm--clean:before {
  content: "\e931";
}
.icon-ibm--checkmark--outline:before {
  content: "\e932";
}
.icon-ibm--magic-wand:before {
  content: "\e933";
}
.icon-ibm--upload:before {
  content: "\e934";
}
.icon-ibm--add:before {
  content: "\e935";
}
.icon-ibm--information:before {
  content: "\e936";
}
.icon-ibm--workspace:before {
  content: "\e937";
}
.icon-ibm--list:before {
  content: "\e938";
}
.icon-ibm--warning--alt--filled:before {
  content: "\e939";
}
.icon-mdi--arrow_drop_down:before {
  content: "\e900";
}
.icon-mdi--arrow_drop_up:before {
  content: "\e901";
}
.icon-mdi--arrow_left:before {
  content: "\e902";
}
.icon-mdi--arrow_right:before {
  content: "\e903";
}
.icon-mdi--menu-down:before {
  content: "\e904";
}
.icon-mdi--select-all:before {
  content: "\e905";
}
.icon-ion--information-circle:before {
  content: "\e906";
}
.icon-dua--double-arrow-left:before {
  content: "\e907";
}
.icon-dua--double-arrow-right:before {
  content: "\e908";
}
.icon-ibm--camera:before {
  content: "\e928";
}
.icon-ibm--save:before {
  content: "\e929";
}
.icon-ibm--document--add:before {
  content: "\e92a";
}
.icon-ibm--password:before {
  content: "\e92b";
}
.icon-ibm--user--avatar:before {
  content: "\e92c";
}
.icon-ibm--logout:before {
  content: "\e92d";
}
.icon-ibm--restart:before {
  content: "\e927";
}
.icon-ibm--3D-curve--manual:before {
  content: "\e909";
}
.icon-ibm--agriculture-analytics:before {
  content: "\e90a";
}
.icon-ibm--ai-results--low:before {
  content: "\e90b";
}
.icon-ibm--align-box--middle-center:before {
  content: "\e90c";
}
.icon-ibm--cabin-care--alert:before {
  content: "\e90d";
}
.icon-ibm--checkbox--checked--filled:before {
  content: "\e90e";
}
.icon-ibm--checkbox--indeterminate--filled:before {
  content: "\e90f";
}
.icon-ibm--checkmark--filled:before {
  content: "\e910";
}
.icon-ibm--close:before {
  content: "\e911";
}
.icon-ibm--collapse-all:before {
  content: "\e912";
}
.icon-ibm--document--pdf:before {
  content: "\e913";
}
.icon-ibm--document:before {
  content: "\e914";
}
.icon-ibm--dot-mark:before {
  content: "\e915";
}
.icon-ibm--draggable:before {
  content: "\e916";
}
.icon-ibm--edit:before {
  content: "\e917";
}
.icon-ibm--filter:before {
  content: "\e918";
}
.icon-ibm--folder:before {
  content: "\e919";
}
.icon-ibm--image:before {
  content: "\e91a";
}
.icon-ibm--layers:before {
  content: "\e91b";
}
.icon-ibm--letter--Aa:before {
  content: "\e91c";
}
.icon-ibm--menu:before {
  content: "\e91d";
}
.icon-ibm--operations--record:before {
  content: "\e91e";
}
.icon-ibm--overflow-menu--vertical:before {
  content: "\e91f";
}
.icon-ibm--radio-button:before {
  content: "\e920";
}
.icon-ibm--search:before {
  content: "\e921";
}
.icon-ibm--settings:before {
  content: "\e922";
}
.icon-ibm--trash-can:before {
  content: "\e923";
}
.icon-ibm--view--off:before {
  content: "\e924";
}
.icon-ibm--view:before {
  content: "\e925";
}
.icon-ibm-accessibility:before {
  content: "\e926";
}
.icon-ibm-phone:before {
  content: "\ea6d";
}
