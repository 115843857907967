@use 'node_modules/@angular/material/index' as mat;
/*@import '~@angular/neoMaterial/neoCore/theming/_palette';*/

// $primaryColor: #5A85D7;
// $secondaryColor: #DA1884;
// $tertiaryColor: #FFD436;
$primaryColor: #6A8DFF;
$secondaryColor: #7B858A;
$tertiaryColor: #FFD436;
$gray: #ACB5BD;

$grey5: #f5f5f5;
$grey4: #495057;
$grey3: #ACB5BD;
$grey2: #DDE2E5;
$grey1: #F8F9FA;

$black: #212429;
$info: #3f51b5;
$white: #ffffff;
$success: #00D385;
$warning: #FFC107;
$danger: #b71c1c;

$colors: (
  "primary": #5A85D7,
  "secondary": #DA1884,
  "tertiary": #FFD436,
  "red": #f44336,
  "pink": #e91e63,
  "purple": #9c27b0,
  "deep-purple": #673ab7,
  "indigo": #3f51b5,
  "blue": #2196f3,
  "light-blue": #03a9f4,
  "cyan": #00bcd4,
  "teal": #009688,
  "green": #4caf50,
  "light-green": #8bc34a,
  "lime": #cddc39,
  "yellow": #ffe821,
  "amber": #ffc107,
  "orange": #ff9800,
  "deep-orange": #ff5722,
  "brown": #795548,
  "gray": #ACB5BD,
  "blue-grey": #607d8b,
  "black": #212429,
  "white": #ffffff,
  "dark-gray": #888888,
  "info": #3f51b5,
  "success": #00D385,
  "warning": #8a6d3b,
  "danger": #a94442,
) !default;

$linear-colors: (
  "green": linear-gradient(45deg, #9ce89d, #cdfa7e),
  "orange": linear-gradient(135deg, #ffc480, #ff763b),
  "cyan": linear-gradient(45deg, #72c2ff, #86f0ff),
  "red": linear-gradient(316deg, #fc5286, #fbaaa2),
  "purple": linear-gradient(230deg, #759bff, #843cf6),
  "purple-dark": linear-gradient(45deg, #a52dd8, #e29bf1)
);

/* shades of text */
$dark-primary-text: rgba($black, 0.87);
$dark-secondary-text: rgba($black, 0.54);
$dark-disabled-text: rgba($black, 0.38);
$dark-dividers: rgba($black, 0.12);
$dark-focused: rgba($black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

// customized palettes - definir los diferentes tonos a través de las herramientas:
// https://material.io/design/color/the-color-system.html#tools-for-picking-colors
// https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=00bc90
// http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5
$mat-primary: (
  50: #EBF0FA,
  100: #CEDBF3,
  200: #ADC3EB,
  300: #8CAAE3,
  400: #7398DD,
  500: $primaryColor, //primary color
  600: #527ED3,
  700: #4873CD,
  800: #3F69C7,
  900: #2E56BE,
  A100: #FFFFFF,
  A200: #CEDBFF,
  A400: #9BB5FF,
  A700: #81A2FF,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
$mat-secondary: (
  50: #FBE3F0,
  100: #F4BADA,
  200: #ED8CC1,
  300: #E55DA8,
  400: #E03B96,
  500: $secondaryColor, //secondary color
  600: #D6157B,
  700: #D01170,
  800: #CB0E66,
  900: #C20853,
  A100: #FFECF3,
  A200: #FFB9D2,
  A400: #FF86B1,
  A700: #FF6DA0,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
$mat-tertiary: (
  50: #FFFAE7,
  100: #FFF2C3,
  200: #FFEA9B,
  300: #FFE172,
  400: #FFDA54,
  500: $tertiaryColor, // tertiary color
  600: #FFCF30,
  700: #FFC929,
  800: #FFC322,
  900: #FFB916,
  A100: #FFFFFF,
  A200: #FFFEFC,
  A400: #FFEDC9,
  A700: #FFE5AF,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
$mat-gray: (
  50: #F8F9FA,
  100: #E6E9EB,
  200: #D6DADE,
  300: #C5CBD1,
  400: #B8C0C7,
  500: $gray, //gris por defecto
  600: #A5AEB7,
  700: #9BA5AE,
  800: #929DA6,
  900: #828D98,
  A100: #FFFFFF,
  A200: #F4F9FE,
  A400: #C0E0FF,
  A700: #A7D3FF,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
$mat-danger: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: $danger,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
$mat-warning: (
  50: #FFF8E1,
  100: #FFECB5,
  200: #FFE083,
  300: #FFD451,
  400: #FFCA2C,
  500: $warning,
  600: #FFBB06,
  700: #FFB305,
  800: #FFAB04,
  900: #FF9E02,
  A100: #FFFFFF,
  A200: #FFFAF2,
  A400: #FFE4BF,
  A700: #FFD9A6,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
$mat-success: (
  50: #E8EFE8,
  100: #C5D6C5,
  200: #9EBB9E,
  300: #779F77,
  400: #598B5A,
  500: $success,
  600: #366E37,
  700: #2E632F,
  800: #275927,
  900: #1A461A,
  A100: #86FF86,
  A200: #53FF53,
  A400: #20FF20,
  A700: #07FF07,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
