@import "variables/colors";

.mat-stepper-vertical,
.mat-stepper-horizontal {
  background-color: $white;
  /*font-family: $primaryFont;*/
}
.mat-step-label {
  @include text-p6;
  font-weight: 400;
}
.mat-step-sub-label-error {
  font-weight: 400;
}
.mat-step-label-error {
  @include text-p6;
}
.mat-step-label-selected {
  @include text-p6;
}

.mat-step-header {
  // &.cdk-keyboard-focused,
  // &.cdk-program-focused,
  // &:hover {
  //   background-color: rgba($black, 0.04);
  // }

  .mat-step-label,
  .mat-step-optional {
    color: $grey3;
  }
  .mat-step-icon {
    width: 32px;
    height: 32px;
    background-color: transparent;
    border: 1px solid $grey3;
    color: $grey3;
    @include text-p6;

    &.mat-step-icon-selected {
      background-color: $grey3;
      color: $white;
      font-weight: 700;
    }
    &.mat-step-icon-state-done,
    &.mat-step-icon-state-edit {
      opacity: 0.6;
    }
  }
  &.mat-primary {
    .mat-step-icon-selected {
      color: $white;
    }
    .mat-step-icon-selected,
    .mat-step-icon-state-done,
    .mat-step-icon-state-edit {
      border-color: $primaryColor;
      background-color: $primaryColor;
      color: $white;
    }
  }

  &.mat-accent {
    .mat-step-icon {
      color: $white;
    }
    .mat-step-icon-selected,
    .mat-step-icon-state-done,
    .mat-step-icon-state-edit {
      border-color: $secondaryColor;
      background-color: $secondaryColor;
      color: $white;
    }
  }
  &.mat-warn {
    .mat-step-icon-selected {
      color: $white;
    }
    .mat-step-icon-selected,
    .mat-step-icon-state-done,
    .mat-step-icon-state-edit {
      border-color: $danger;
      background-color: $danger;
      color: $white;
    }
  }
  .mat-step-icon-state-error {
    background-color:transparent;
    color: $danger;
  }
  .mat-step-label {

    &.mat-step-label-active {
      color: $grey3;
    }
    &.mat-step-label-error {
      color: $danger;
    }
  }
  &.mat-primary {
    .mat-step-label {
      &.mat-step-label-selected {
        color: $primaryColor;
      }
    }

    .mat-step-icon-state-done,
    .mat-step-icon-state-edit {
      + .mat-step-label-active {
        color: $primaryColor;
      }
    }
  }
  &.mat-accent {
    .mat-step-label {
      &.mat-step-label-selected {
        color: $secondaryColor;
      }
    }

    .mat-step-icon-state-done,
    .mat-step-icon-state-edit {
      + .mat-step-label-active {
        color: $secondaryColor;
      }
    }
  }
  &.mat-warn {
    .mat-step-label {
      &.mat-step-label-selected {
        color: $danger;
      }
    }

    .mat-step-icon-state-done,
    .mat-step-icon-state-edit {
      + .mat-step-label-active {
        color: $danger;
      }
    }
  }

  &.previous {

    .mat-step-icon {
      border-color: $grey3;
      background-color: transparent;
      color: $grey3;
      opacity: 0.6;

      + .mat-step-label-active {
        color: $grey3; 
        opacity: 0.6;       
      }
    }

    &.mat-primary {
      .mat-step-icon {
        border-color: $primaryColor;
        background-color: transparent;
        color: $primaryColor;

        + .mat-step-label-active {
          color: $primaryColor;
        }
      }
    }

    &.mat-accent {
      .mat-step-icon {
        border-color: $secondaryColor;
        background-color: transparent;
        color: $secondaryColor;

        + .mat-step-label-active {
          color: $secondaryColor;
        }
      }
    }

    &.mat-warn {
      .mat-step-icon {
        border-color: $danger;
        background-color: transparent;
        color: $danger;

        + .mat-step-label-active {
          color: $danger;
        }
      }
    }
  }
}
@media(hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom {

  .mat-horizontal-stepper-header {
    padding: 10px;

    &::after,
    &::before {
      width: calc(50% - 10px);
      border-style: dashed;
      border-top-color: $grey3;
      top: 26px;
    }

    .mat-step-label {
      padding-top: 5px;
    }

    &:not(:first-child)::before,
    &:not(:last-child)::after {
      width: calc(50% - 16px);
      border-top-style: dashed;
    }

    &.previous {
      &::after {
        border-style: solid;
        opacity: 0.6;
      }  
      
      + .mat-stepper-horizontal-line {
        border-style: solid;
        opacity: 0.6;

        + .mat-horizontal-stepper-header {
          &::before {
             border-style: solid;
             opacity: 0.6;
          }

        }
      }

      &.mat-primary {
        &::after {
          border-top-color: $primaryColor;
        }  
        
        + .mat-stepper-horizontal-line {
          border-top-color: $primaryColor;
  
          + .mat-horizontal-stepper-header {
            &::before {
              border-top-color: $primaryColor;
            }
  
          }
        }
      }

      &.mat-accent {
        &::after {
          border-top-color: $secondaryColor;
        }  
        
        + .mat-stepper-horizontal-line {
          border-top-color: $secondaryColor;
  
          + .mat-horizontal-stepper-header {
            &::before {
              border-top-color: $secondaryColor;
            }
  
          }
        }
      }

      &.mat-warn {
        &::after {
          border-top-color: $danger;
        }  
        
        + .mat-stepper-horizontal-line {
          border-top-color: $danger;
  
          + .mat-horizontal-stepper-header {
            &::before {
              border-top-color: $danger;
            }
  
          }
        }
      }
    }
  }
  .mat-stepper-horizontal-line {
    border-style: dashed;
    border-top-color: $grey3;
    top: 26px;
  }
}


.mat-vertical-stepper-header {
  padding: 10px;
}
.mat-stepper-vertical-line::before {
  border-left-color: rgba($black, 0.12);
  top: -16px;
  bottom: -16px;
}
