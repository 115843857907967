@mixin flex {
  display: -webkit-flex;
  display: flex;
}
@mixin inline-flex {
  display: -webkit-inline-flex;
  display: inline-flex;
}
@mixin flex-row {
  -webkit-flex-direction: row;
  flex-direction: row;
}
@mixin flex-row-reverse {
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
@mixin flex-column {
  -webkit-flex-direction: column;
  flex-direction: column;
}
@mixin flex-column-reverse {
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
@mixin flex-wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
@mixin flex-grow($i) {
  -webkit-flex-grow: $i;
  flex-grow: $i;
}
@mixin flex-shrink($i) {
  -webkit-flex-shrink: $i;
  flex-shrink: $i;
}
@mixin flex-basis($i) {
  -webkit-flex-basis: $i;
  flex-basis: $i;
}
@mixin align-items-stretch {
  -webkit-align-items: stretch;
  align-items: stretch;
}
@mixin align-items-start {
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
@mixin align-items-center {
  -webkit-align-items: center;
  align-items: center;
}
@mixin align-items-end {
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
@mixin align-self-stretch {
  -webkit-align-self: stretch;
  align-self: stretch;
}
@mixin align-self-start {
  -webkit-align-self: flex-start;
  align-self: flex-start;
}
@mixin align-self-center {
  -webkit-align-self: center;
  align-self: center;
}
@mixin align-self-end {
  -webkit-align-self: flex-end;
  align-self: flex-end;
}
@mixin justify-content-start {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
@mixin justify-content-center {
  -webkit-justify-content: center;
  justify-content: center;
}
@mixin justify-content-end {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
@mixin justify-content-around {
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
@mixin justify-content-between {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@mixin justify-content-evenly {
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
}
@mixin flex-order($i) {
  -webkit-order: $i;
  order: $i;
}
