@import "variables/colors";

.mat-input-element {
  caret-color: $primaryColor;
  &::placeholder {
    color: rgba($black, 0.42);
  }
  &::-moz-placeholder {
    color: rgba($black, 0.42);
  }
  &::-webkit-input-placeholder {
    color: rgba($black, 0.42);
  }
  &:-ms-input-placeholder {
    color: rgba($black, 0.42)
  }
}
.mat-form-field.mat-accent {
  .mat-input-element {
    caret-color: $secondaryColor;
  }
}
input.mat-input-element {
  margin-top: -0.0625em;
}
