@import "variables/colors";

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba($black, 0.2), 0px 16px 24px 2px rgba($black, 0.14), 0px 6px 30px 5px rgba($black, 0.12);
  background: $white;
  color: rgba($black, 0.87);
  /*font-family: $primaryFont;*/
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: normal;
}
