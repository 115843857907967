@import "variables/colors";

.mat-tab-group {
  // font-family: $primaryFont;

  .mat-tab-nav-bar,
  .mat-tab-header .mat-tab-label-container {
    border-bottom: 1px solid $grey2;    
  }
  &.no-border {
    .mat-tab-nav-bar,
    .mat-tab-header {
      border-bottom: 0;
    }
  }

  &.mat-tab-group-inverted-header {
    .mat-tab-nav-bar,
    .mat-tab-header {
      border-top: 1px solid $grey2;
      border-bottom: none;
    }
  }

  .mat-tab-label,
  .mat-tab-link {
    color: $grey3;
    /*font-family: $primaryFont;*/
    @include text-h3;
    font-weight: 400;
    opacity: 1;

    &.mat-tab-disabled {
      color: $grey2;
    }
  }

  .mat-tab-label-active { //pestaña activa
    font-weight: 700;
  }
  .mat-ink-bar { //línea que marca la pestaña activa
    height: 4px;
  } 

  .mat-tab-header {
    border-top-left-radius: 4px;  
    border-top-right-radius: 4px;

    .mat-tab-label {
      min-width: auto;
      height: 62px;
      padding: 0 20px;    
    }

    .mat-tab-header-pagination-chevron {
      width: 16px;
      height: 16px;
      border-width: 0;
      border-color: $grey3;
      transform: rotate(0);
      position: relative;

      &:after {
        content: "\e93e"; //chevron left
        @include icono;
        font-size: 16px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .mat-tab-header-pagination-after {
      .mat-tab-header-pagination-chevron {
        transform: rotate(180deg); //girar el chevron para next
      }
    } 
    .mat-tab-header-pagination-disabled {
      .mat-tab-header-pagination-chevron {
        border-color: $grey3;
      }
    }
  }

  &[class*=mat-background-] .mat-tab-header,
  .mat-tab-nav-bar[class*=mat-background-] {
    border-bottom: none;
    border-top: none;
  }
  &:not([class*=mat-background-]) .mat-tab-header {
    background-color: $white;
  }


  //por defecto toma como color el primary, si se quiere establecer el negro introducir por ejemplo [color]="'black'" en <mat-tab-group>
  &:not([class*=mat-primary]):not([class*=mat-accent]):not([class*=mat-warn]),
  &.mat-black { 
    .mat-tab-label-active { //pestaña activa
      color: $black;
    }
    .mat-ink-bar { //línea que marca la pestaña activa
      background-color: $black;
    }
  }

  &,
  .mat-tab-nav-bar {
    &.mat-primary {
      .mat-tab-label {
        &.cdk-keyboard-focused,
        &.cdk-program-focused {
          &:not(.mat-tab-disabled) {
            background-color: rgba(197,202,233, 0.3);
          }
        }
        &.mat-tab-label-active { //color de texto de la pestaña activa
          color: $primaryColor;
        }
      }
      
      .mat-ink-bar { //línea que marca la pestaña activa
        background-color: $primaryColor;
      }
      &.mat-background-primary { //toda la barra de pestañas tiene fondo de color primary, por eso el ink-bar (línea que marca el activo) es blanca
        & > .mat-tab-header,
        & > .mat-tab-link-container {
          .mat-ink-bar {
            background-color: $white;
          }
        }
      }    
    }

    &.mat-accent {
      .mat-tab-label {
        &.cdk-keyboard-focused,
        &.cdk-program-focused {
          &:not(.mat-tab-disabled) {
            background-color: rgba(255,128,171, 0.3);
          }
        }
        &.mat-tab-label-active {
          color: $secondaryColor;
        }
      }
      .mat-ink-bar {
        background-color: $secondaryColor;
      }
      &.mat-background-accent {
        & > .mat-tab-header,
        & > .mat-tab-link-container {
          .mat-ink-bar {
            background-color: $white;
          }
        }
      }
    }

    &.mat-warn {
      .mat-ink-bar {
        background-color: $danger;
      }
      &.mat-background-warn {
        & > .mat-tab-header,
        & > .mat-tab-link-container {
          .mat-ink-bar {
            background-color: $white;
          }
        }
      }
      .mat-tab-label,
      .mat-tab-link {
        &.cdk-keyboard-focused,
        &.cdk-program-focused {
          &:not(.mat-tab-disabled) {
            background-color: rgba(255,205,210, 0.3);
          }
        }
      }
      .mat-tab-label-active {
        color: $danger;
      }
    }

    &.mat-background-primary {
      .mat-tab-label {
        &.cdk-keyboard-focused,
        &.cdk-program-focused {
          :not(.mat-tab-disabled) {
            background-color: rgba(197,202,233, 0.3)
          }
        }
      }
      & > .mat-tab-header,
      & > .mat-tab-link-container,
      & > .mat-tab-header-pagination {
        background-color: $primaryColor;
      }
      & > .mat-tab-header .mat-tab-label,
      & > .mat-tab-link-container .mat-tab-link {
        color:#fff;

        &.mat-tab-disabled {
          color: rgba($white, 0.4);
        }
      }
      & >.mat-tab-header-pagination .mat-tab-header-pagination-chevron,
      & >.mat-tab-links .mat-focus-indicator::before,
      & >.mat-tab-header .mat-focus-indicator::before {
        border-color: $white;
      }
      & > .mat-tab-header-pagination-disabled {
        .mat-tab-header-pagination-chevron {
          border-color: rgba($white, 0.4)
        }
      }
      & > .mat-tab-header,
      & > .mat-tab-link-container {
        .mat-ripple-element {
          background-color: rgba($white, 0.12);
        }
      }
    }

    &.mat-background-accent {
      .mat-tab-label {
        &.cdk-keyboard-focused,
        &.cdk-program-focused {
          &:not(.mat-tab-disabled) {
            background-color:rgba(255,128,171,.3);
          }
        }
      }
      & > .mat-tab-header,
      & > .mat-tab-link-container,
      & > .mat-tab-header-pagination {
        background-color: $secondaryColor;
      }
      & > .mat-tab-header .mat-tab-label,
      & > .mat-tab-link-container .mat-tab-link {
        color: $white;

        &.mat-tab-disabled {
          color: rgba($white, 0.4);
        }
      }
      & > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
      & > .mat-tab-links .mat-focus-indicator::before,
      & > .mat-tab-header .mat-focus-indicator::before {
        border-color: $white;
      }
      & > .mat-tab-header-pagination-disabled {
        .mat-tab-header-pagination-chevron {
          border-color: rgba($white, 0.4);
        }
      }
      & > .mat-tab-header,
      & > .mat-tab-link-container {
        .mat-ripple-element {
          background-color:rgba($white, 0.12);
        }
      }
    }

    &.mat-background-warn {
      .mat-tab-label {
        &.cdk-keyboard-focused,
        &.cdk-program-focused {
          &:not(.mat-tab-disabled) {
            background-color:rgba(255,205,210,.3);
          }
        }
      }
      & > .mat-tab-header,
      & > .mat-tab-link-container,
      & > .mat-tab-header-pagination {
        background-color: $danger;
      }
      & > .mat-tab-header .mat-tab-label,
      & > .mat-tab-link-container .mat-tab-link {
        color: $white;

        &.mat-tab-disabled {
          color: rgba($white, 0.4);
        }
      }
      & >.mat-tab-header-pagination .mat-tab-header-pagination-chevron,
      & >.mat-tab-links .mat-focus-indicator::before,
      & >.mat-tab-header .mat-focus-indicator::before {
        border-color: $white;
      }
      & > .mat-tab-header-pagination-disabled {
        .mat-tab-header-pagination-chevron {
          border-color: rgba($white, 0.4);
        }
      }
      & > .mat-tab-header,
      & > .mat-tab-link-container {
        .mat-ripple-element {
          background-color: rgba($white, 0.12);
        }
      }
    }
  }

  .mat-tab-body {
    min-height: 342px;
    // padding: 18px 0 30px;
    margin-top: 10px;

    .mat-tab-body-content {
      overflow: hidden;
    }
  }

}




@media ($mediaDesktop) {
  .mat-tab-group {
  // *:not([class*=blq-card]) {
    //   .mat-tab-header {
    //     padding: 0 20px;
    //   }
    // }  
    .mat-tab-header {
      .mat-tab-label-container {
        padding: 0 20px;
      }      
    }
  }
  .blq-card {
    .mat-tab-group {
      .mat-tab-header {
        padding: 0;
      }
    }    
  }
  
}
@media ($mediaToTabletH) {
  .mat-tab-group {
  // *:not([class*=blq-card]) {
    //   .mat-tab-header {
    //     padding: 0 10px;
    //   }
    // }
    .mat-tab-header {
      .mat-tab-label-container {
        margin: 0 10px;
      }      
    }    
  }  
  .blq-card {
    .mat-tab-group {
      .mat-tab-header {
        margin: 0;
      }
    }    
  }
}
