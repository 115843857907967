@use 'node_modules/@angular/material/index' as mat;
@import "variables/colors";

//INI CSS fechaHora para fecha con solucion ngx-mat-datetime - ICONOS
.mat-calendar-arrow,
.mat-calendar-next-button, // activar propios selectores mes anterior posterior (mat-calendar-next-button)
.mat-calendar-previous-button {
  width: auto;
  height: auto;
  border: 0;
  @include inline-flex;
  @include justify-content-center;
  @include align-items-center;

  &:before {
    @include icono;
    font-size: 20px;
  }
}
.mat-calendar-arrow {
  &:before {
    content: "\e900";
  }
}
.mat-calendar-next-button,
.mat-calendar-previous-button {

  &:before {
    padding: 10px;
  }
}
.mat-calendar-next-button {
  &:before {
    content: "\e93d";
  }
}
.mat-calendar-previous-button {
  &:before {
    content: "\e93e";
  }
}

// anular selectores mes
.mat-calendar-previous-button::after,
.mat-calendar-next-button::after {
  border: 0;
}


ngx-mat-datetime-content {

  .ngx-mat-calendar-body {
    font-size: 14px;
  }

  .actions {
    padding: 0;

    .mat-button {
      width: 100%;
      height: 40px;
      border-color: $primaryColor;
      background-color: $primaryColor;
      color: $white;
      font-size: 16px;

      &[disabled] {
        opacity: 0.5;
      }
    }
  }

  ngx-mat-timepicker {

    .ngx-mat-timepicker-table {
      margin-left: auto;
      margin-right: auto;
    }

    .mat-button-wrapper { // anular selectores hora en fechaHora
      display: none;
    }

    // configuración selectores hora en fechaHora
    > form {
      > table {
        > tbody {
          > tr:nth-child(1),
          > tr:nth-child(3) {

            > td:nth-child(1),
            > td:nth-child(3) {

              > button {
                width: auto;
                height: auto;
                border: 0;
                @include inline-flex;
                @include justify-content-center;
                @include align-items-center;

                &:before {
                  padding: 5px;
                  font-size: 20px;
                  @include icono;
                }
              }
            }
          }

          > tr:nth-child(1) {
            > td:nth-child(1),
            > td:nth-child(3) {
              > button:before {
                content: "\e942"; // up hora
              }
            }
          }
          > tr:nth-child(3) {
            > td:nth-child(1),
            > td:nth-child(3) {
              > button:before {
                content: "\e943"; // down hora
              }
            }
          }
        }
      }
    }

    /* hora, cifra */
    > form {
      > table {
        > tbody {
          > tr:nth-child(2) {
            > td:nth-child(1),
            > td:nth-child(3) {
              .mat-form-field {
                width: 50px;
                max-width: 50px;
                height: 50px;
                border: 1px solid $grey3;
                border-radius: 4px;
                font-size: 20px;

                .mat-form-field-infix {
                  padding-top: 14px;
                  border-top: 0;
                }

                .mat-form-field-underline {
                  display: none;
                }
              }
            }
            > td:nth-child(2) { //separador puntos entre horas y minutos
              width: 20px;
            }
          }
        }
      }
    }
  }
}
// FIN CSS fechaHora para fecha con solucion ngx-mat-datetime  - ICONOS

// INI CSS hora para hora con solucion owl-date-time - ICONOS
owl-date-time-timer {

  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0px 2px 4px -1px rgba($black, 0.2),0px 4px 5px 0px rgba($black, 0.14),0px 1px 10px 0px rgba($black, 0.12);

  > owl-date-time-timer-box {
    button {
      .owl-dt-control-button-content{
        display: none;
      }
    }

    .owl-dt-timer-content {

      .owl-dt-timer-input {
        width: 50px;
        height: 50px;
        border-color: $grey3;
        font-size: 20px;
      }
    }

    &:first-child {
      margin-right: 20px;
      position: relative;

      &:after {
        content: ':';
        position: absolute;
        left: calc(100% + 10px);
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }

    // configuración selectores hora en hora
    &:nth-child(1) > button:nth-child(1),
    &:nth-child(1) > button:nth-child(3),
    &:nth-child(2) > button:nth-child(2),
    &:nth-child(2) > button:nth-child(4) {
      width: auto;
      height: auto;
      border: 0;
      @include inline-flex;
      @include justify-content-center;
      @include align-items-center;

      &:before {
        padding: 5px;
        font-size: 20px;
        @include icono;
      }
    }
    &:nth-child(1) > button:nth-child(1), // up hora
    &:nth-child(2) > button:nth-child(2) { // up minutos
      &:before {
        content: "\e942";
      }
    }
    &:nth-child(1) > button:nth-child(3), // down hora
    &:nth-child(2) > button:nth-child(4) { // down minutos
      &:before {
        content: "\e943";
      }
    }
  }
}
.owl-dt-container-buttons {
  border-radius: 4px;
  box-shadow: 0px 2px 4px -1px rgba($black, 0.2),0px 4px 5px 0px rgba($black, 0.14),0px 1px 10px 0px rgba($black, 0.12);
  overflow: hidden;
}
// FIN CSS hora para hora con solucion owl-date-time - ICONOS

// Time Picker
.field-hora-panel {
  width: 180px;
  background: $white;
  margin-left: -10px;
  margin-top: 10px;

  .owl-dt-container-buttons {
    height: 40px;

    button:first-child {
      display: none;
    }
    button:nth-child(2) {
      height: 100%;
      width: 100%;
      background: $primaryColor;
      color: $white;
    }
  }
}
// FIN CSS Time Picker


// COLORES
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba($black, 0.2),0px 4px 5px 0px rgba($black, 0.14),0px 1px 10px 0px rgba($black, 0.12);
  background-color: $white;
  color: $black;

  &.mat-accent {
    .mat-calendar-body-in-range::before {
      background: rgba(mat.get-color-from-palette($mat-secondary, 900), 0.2);
    }
    .mat-calendar-body-comparison-identical,
    .mat-calendar-body-in-comparison-range::before {
      background: rgba(mat.get-color-from-palette($mat-warning, 900), 0.2);
    }
    .mat-calendar-body-comparison-bridge-start::before,
    [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
      background:linear-gradient(to right, rgba(mat.get-color-from-palette($mat-secondary, 900), 0.2) 50%, rgba(mat.get-color-from-palette($mat-warning, 900), 0.2) 50%)
    }
    .mat-calendar-body-comparison-bridge-end::before,
    [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
      background:linear-gradient(to left, rgba(mat.get-color-from-palette($mat-secondary, 900), 0.2) 50%, rgba(mat.get-color-from-palette($mat-warning, 900), 0.2) 50%)
    }
    .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
    .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
      background: mat.get-color-from-palette($mat-success, 200);
    }
    .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
    .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
      background: $success;
    }
    .mat-calendar-body-selected {
      background-color: $secondaryColor;
      color: $white;
    }
    .mat-calendar-body-disabled {
      & > .mat-calendar-body-selected {
        background-color: rgba(mat.get-color-from-palette($mat-secondary, 200), 0.4);
      }
    }
    .mat-calendar-body-today {
      &.mat-calendar-body-selected {
        box-shadow:inset 0 0 0 1px $white;
      }
    }
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover,
    .cdk-keyboard-focused .mat-calendar-body-active,
    .cdk-program-focused .mat-calendar-body-active {
      & > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        background-color: rgba(mat.get-color-from-palette($mat-secondary, 900), 0.3);
      }
    }
  }

  &.mat-warn {
    .mat-calendar-body-in-range::before {
      background:rgba(mat.get-color-from-palette($mat-danger, 900), 0.2)
    }
    .mat-calendar-body-comparison-identical,
    .mat-calendar-body-in-comparison-range::before {
      background:rgba(mat.get-color-from-palette($mat-warning, 900), 0.2)
    }
    .mat-calendar-body-comparison-bridge-start::before,
    [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
      background:linear-gradient(to right, rgba(mat.get-color-from-palette($mat-danger, 900), 0.2) 50%, rgba(mat.get-color-from-palette($mat-warning, 900), 0.2) 50%)
    }
    .mat-calendar-body-comparison-bridge-end::before,
    [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
      background:linear-gradient(to left, rgba(mat.get-color-from-palette($mat-danger, 900), 0.2) 50%, rgba(mat.get-color-from-palette($mat-warning, 900), 0.2) 50%)
    }
    .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
    .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
      background: mat.get-color-from-palette($mat-success, 200);
    }
    .mat-calendar-body-comparison-identical {
      &.mat-calendar-body-selected,
      & > .mat-calendar-body-selected {
        background: $success;
      }
    }
    .mat-calendar-body-selected {
      background-color: $danger;
      color: $white;
    }
    .mat-calendar-body-disabled {
      & > .mat-calendar-body-selected {
        background-color: rgba(mat.get-color-from-palette($mat-danger, 900), 0.4);
      }
    }
    .mat-calendar-body-today.mat-calendar-body-selected {
      box-shadow:inset 0 0 0 1px $white;
    }
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover,
    .cdk-keyboard-focused .mat-calendar-body-active,
    .cdk-program-focused .mat-calendar-body-active {
      & > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        background-color:rgba(mat.get-color-from-palette($mat-danger, 900), 0.3);
      }
    }
  }
}
.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba($black, 0.2),0px 0px 0px 0px rgba($black, 0.14),0px 0px 0px 0px rgba($black, 0.12);
}
.mat-datepicker-toggle-active {
  color: $primaryColor;
  &.mat-accent {
    color: $secondaryColor;
  }
  &.mat-warn {
    color: $danger;
  }
}
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: $black;
}
// FIN COLORES
