/*@font-face {
  font-family: "Plex Sans Condensed";
  src: url("IBMPlexSansCondensed-Thin.eot");
  src: local('☺'), url("IBMPlexSansCondensed-Thin.eot?#iefix") format("embedded-opentype"),
  url("IBMPlexSansCondensed-Thin.woff") format("woff"),
  url("IBMPlexSansCondensed-Thin.ttf") format("truetype"),
  url("IBMPlexSansCondensed-Thin.svg") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Plex Sans Condensed";
  src: url("IBMPlexSansCondensed-ThinItalic.eot");
  src: local('☺'), url("IBMPlexSansCondensed-ThinItalic.eot?#iefix") format("embedded-opentype"),
  url("IBMPlexSansCondensed-ThinItalic.woff") format("woff"),
  url("IBMPlexSansCondensed-ThinItalic.ttf") format("truetype"),
  url("IBMPlexSansCondensed-ThinItalic.svg") format("svg");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Plex Sans Condensed";
  src: url("IBMPlexSansCondensed-ExtraLight.eot");
  src: local('☺'), url("IBMPlexSansCondensed-ExtraLight.eot?#iefix") format("embedded-opentype"),
  url("IBMPlexSansCondensed-ExtraLight.woff") format("woff"),
  url("IBMPlexSansCondensed-ExtraLight.ttf") format("truetype"),
  url("IBMPlexSansCondensed-ExtraLight.svg") format("svg");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Plex Sans Condensed";
  src: url("IBMPlexSansCondensed-ExtraLightItalic.eot");
  src: local('☺'), url("IBMPlexSansCondensed-ExtraLightItalic.eot?#iefix") format("embedded-opentype"),
  url("IBMPlexSansCondensed-ExtraLightItalic.woff") format("woff"),
  url("IBMPlexSansCondensed-ExtraLightItalic.ttf") format("truetype"),
  url("IBMPlexSansCondensed-ExtraLightItalic.svg") format("svg");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Plex Sans Condensed";
  src: url("IBMPlexSansCondensed-Light.eot");
  src: local('☺'), url("IBMPlexSansCondensed-Light.eot?#iefix") format("embedded-opentype"),
  url("IBMPlexSansCondensed-Light.woff") format("woff"),
  url("IBMPlexSansCondensed-Light.ttf") format("truetype"),
  url("IBMPlexSansCondensed-Light.svg") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Plex Sans Condensed";
  src: url("IBMPlexSansCondensed-LightItalic.eot");
  src: local('☺'), url("IBMPlexSansCondensed-LightItalic.eot?#iefix") format("embedded-opentype"),
  url("IBMPlexSansCondensed-LightItalic.woff") format("woff"),
  url("IBMPlexSansCondensed-LightItalic.ttf") format("truetype"),
  url("IBMPlexSansCondensed-LightItalic.svg") format("svg");
  font-weight: 300;
  font-style: italic;
}*/
@font-face {
  font-family: "Plex Sans Condensed";
  src: url("src/assets/fonts/plex-sans-condensed/IBMPlexSansCondensed-Regular.eot");
  src: local('☺'), url("src/assets/fonts/plex-sans-condensed/IBMPlexSansCondensed-Regular.eot?#iefix") format("embedded-opentype"),
  url("src/assets/fonts/plex-sans-condensed/IBMPlexSansCondensed-Regular.woff") format("woff"),
  url("src/assets/fonts/plex-sans-condensed/IBMPlexSansCondensed-Regular.ttf") format("truetype"),
  url("src/assets/fonts/plex-sans-condensed/IBMPlexSansCondensed-Regular.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Plex Sans Condensed";
  src: url("src/assets/fonts/plex-sans-condensed/IBMPlexSansCondensed-Italic.eot");
  src: local('☺'), url("src/assets/fonts/plex-sans-condensed/IBMPlexSansCondensed-Italic.eot?#iefix") format("embedded-opentype"),
  url("src/assets/fonts/plex-sans-condensed/IBMPlexSansCondensed-Italic.woff") format("woff"),
  url("src/assets/fonts/plex-sans-condensed/IBMPlexSansCondensed-Italic.ttf") format("truetype"),
  url("src/assets/fonts/plex-sans-condensed/IBMPlexSansCondensed-Italic.svg") format("svg");
  font-weight: 400;
  font-style: italic;
}
/*@font-face {
  font-family: "Plex Sans Condensed";
  src: url("IBMPlexSansCondensed-Medium.eot");
  src: local('☺'), url("IBMPlexSansCondensed-Medium.eot?#iefix") format("embedded-opentype"),
  url("IBMPlexSansCondensed-Medium.woff") format("woff"),
  url("IBMPlexSansCondensed-Medium.ttf") format("truetype"),
  url("IBMPlexSansCondensed-Medium.svg") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Plex Sans Condensed";
  src: url("IBMPlexSansCondensed-MediumItalic.eot");
  src: local('☺'), url("IBMPlexSansCondensed-MediumItalic.eot?#iefix") format("embedded-opentype"),
  url("IBMPlexSansCondensed-MediumItalic.woff") format("woff"),
  url("IBMPlexSansCondensed-MediumItalic.ttf") format("truetype"),
  url("IBMPlexSansCondensed-MediumItalic.svg") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Plex Sans Condensed";
  src: url("IBMPlexSansCondensed-SemiBold.eot");
  src: local('☺'), url("IBMPlexSansCondensed-SemiBold.eot?#iefix") format("embedded-opentype"),
  url("IBMPlexSansCondensed-SemiBold.woff") format("woff"),
  url("IBMPlexSansCondensed-SemiBold.ttf") format("truetype"),
  url("IBMPlexSansCondensed-SemiBold.svg") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Plex Sans Condensed";
  src: url("IBMPlexSansCondensed-SemiBoldItalic.eot");
  src: local('☺'), url("IBMPlexSansCondensed-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
  url("IBMPlexSansCondensed-SemiBoldItalic.woff") format("woff"),
  url("IBMPlexSansCondensed-SemiBoldItalic.ttf") format("truetype"),
  url("IBMPlexSansCondensed-SemiBoldItalic.svg") format("svg");
  font-weight: 600;
  font-style: italic;
}*/
@font-face {
  font-family: "Plex Sans Condensed";
  src: url("src/assets/fonts/plex-sans-condensed/IBMPlexSansCondensed-Bold.eot");
  src: local('☺'), url("src/assets/fonts/plex-sans-condensed/IBMPlexSansCondensed-Bold.eot?#iefix") format("embedded-opentype"),
  url("src/assets/fonts/plex-sans-condensed/IBMPlexSansCondensed-Bold.woff") format("woff"),
  url("src/assets/fonts/plex-sans-condensed/IBMPlexSansCondensed-Bold.ttf") format("truetype"),
  url("src/assets/fonts/plex-sans-condensed/IBMPlexSansCondensed-Bold.svg") format("svg");
  font-weight: 700;
  font-style: normal;
}
/*
@font-face {
  font-family: "Plex Sans Condensed";
  src: url("IBMPlexSansCondensed-BoldItalic.eot");
  src: local('☺'), url("IBMPlexSansCondensed-BoldItalic.eot?#iefix") format("embedded-opentype"),
  url("IBMPlexSansCondensed-BoldItalic.woff") format("woff"),
  url("IBMPlexSansCondensed-BoldItalic.ttf") format("truetype"),
  url("IBMPlexSansCondensed-BoldItalic.svg") format("svg");
  font-weight: 700;
  font-style: italic;
}
*/
