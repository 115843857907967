@use '~@angular/material' as mat;
@import "variables/variables";
@import "mixins/flex.scss";

.mat-menu-panel {
  min-width: 200px;
  margin-top: 7px;
  background: $white;
  // box-shadow: 0px 2px 4px -1px rgba($black, 0.05),0px 4px 5px 0px rgba($black, 0.04),0px 1px 10px 0px rgba($black, 0.02)!important;
  &:not([class*=mat-elevation-z]) {        
    box-shadow: 0px 2px 4px -1px rgba($black, 0.05),0px 4px 5px 0px rgba($black, 0.04),0px 1px 10px 0px rgba($black, 0.02);
  }
  .mat-menu-content {
    &:not(:empty) {
      padding: 4px;
    }
    .mat-menu-item {
      font-size: 16px;
      line-height: 20px;
      @include flex;
      @include justify-content-start;
      @include align-items-center;
    }

    button {
      &.mat-menu-item-primary {
        color: $primaryColor;
      }
      &.mat-menu-item-accent {
        color: $secondaryColor;
      }
      &.mat-menu-item-warn {
        color: $danger;
      }
    }
  }
}

.mat-menu-item {
  border-radius: 4px;
  background:transparent;
  color: rgba($black,.87);
  /*font-family: $primaryFont;*/
  font-size: 14px;
  font-weight: 400;
  
  &[disabled] {
    &,
    &::after,
    .mat-icon-no-color {
      color:rgba($black, 0.38);
    }
  }
  
  .mat-menu-ripple {
    border-radius: 4px;
  }
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba($black, 0.54);
}
.mat-menu-item:hover,
.mat-menu-item.cdk-program-focused,
.mat-menu-item.cdk-keyboard-focused,
.mat-menu-item-highlighted {
  &:not([disabled]) {
    background: rgba($black, 0.04);
  }
}
