@import "variables/colors";

.mat-badge {
  position: relative;
  .mat-badge-content {
    border-radius: 50%;
    background: $primaryColor;
    color: $white;
    /*font-family: $primaryFont;*/
    @include text-h4;
    font-weight: 600;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    position: absolute;
    transition: transform 200ms ease-in-out;
    transform: scale(0.6);
    pointer-events: none;
  }
  &.mat-badge-disabled {
    .mat-badge-content {
      background: #b9b9b9;
      color: $dark-disabled-text;
    }
  }

  &.mat-badge-accent {
    .mat-badge-content {
      background: $secondaryColor;
      color: $white;
    }
  }
  &.mat-badge-warn {
    .mat-badge-content {
      background: #f44336;
      color: $white;
    }
  }

  &.mat-badge-small {
    .mat-badge-content {
      width: 16px;
      height: 16px;
      font-size: 9px;
      line-height: 16px;
    }
    &.mat-badge-above {
      .mat-badge-content {
        top: -8px;
      }
    }
    &.mat-badge-below {
      .mat-badge-content {
        bottom: -8px;
      }
    }
    &.mat-badge-before {
      .mat-badge-content {
        left: -16px;
      }
    }
    &.mat-badge-after {
      .mat-badge-content {
        right: -16px;
      }
    }
    &.mat-badge-overlap {
      &.mat-badge-before {
        .mat-badge-content {
          left: -8px;
        }
      }
      &.mat-badge-after {
        .mat-badge-content {
          right: -8px;
        }
      }
    }
  }

  &.mat-badge-medium {
    .mat-badge-content {
      width: 22px;
      height: 22px;
      line-height: 22px;
    }
    &.mat-badge-above {
      .mat-badge-content {
        top: -11px;
      }
    }
    &.mat-badge-below {
      .mat-badge-content {
        bottom: -11px;
      }
    }
    &.mat-badge-before {
      .mat-badge-content {
        left: -22px;
      }
    }
    &.mat-badge-after {
      .mat-badge-content {
        right: -22px;
      }
    }
    &.mat-badge-overlap {
      &.mat-badge-before {
        .mat-badge-content {
          left: -11px;
        }
      }
      &.mat-badge-after {
        .mat-badge-content {
          right: -11px;
        }
      }
    }
  }

  &.mat-badge-large {
    .mat-badge-content {
      width: 28px;
      height: 28px;
      font-size: 24px;
      line-height: 28px;
    }
    &.mat-badge-above {
      .mat-badge-content {
        top: -14px;
      }
    }
    &.mat-badge-below {
      .mat-badge-content {
        bottom: -14px;
      }
    }
    &.mat-badge-before {
      .mat-badge-content {
        left: -28px;
      }
    }
    &.mat-badge-after {
      .mat-badge-content {
        right: -28px;
      }
    }
    &.mat-badge-overlap {
      &.mat-badge-before {
        .mat-badge-content {
          left: -14px;
        }
      }
      &.mat-badge-after {
        .mat-badge-content {
          right: -14px;
        }
      }
    }
  }
}

.mat-badge-hidden {
  .mat-badge-content {
    display: none;
  }
}

[dir=rtl] {
  .mat-badge-small {
    &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -16px;
      }
    }
    &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -16px;
      }
    }
    &.mat-badge-overlap {
      &.mat-badge-before {
        .mat-badge-content {
          left: auto;
          right: -8px;
        }
      }
      &.mat-badge-after {
        .mat-badge-content {
          right: auto;
          left: -8px;
        }
      }
    }
  }

  .mat-badge-medium {
    &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -22px;
      }
    }
    &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -22px;
      }
    }
    &.mat-badge-overlap {
      &.mat-badge-before {
        .mat-badge-content {
          left: auto;
          right: -11px;
        }
      }
      &.mat-badge-after {
        .mat-badge-content {
          right: auto;
          left: -11px;
        }
      }
    }
  }

  .mat-badge-large {
    &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -28px;
      }
    }
    &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -28px;
      }
    }
    &.mat-badge-overlap {
      &.mat-badge-before {
        .mat-badge-content {
          left: auto;
          right: -14px;
        }
      }
      &.mat-badge-after {
        .mat-badge-content {
          right: auto;
          left: -14px;
        }
      }
    }
  }
}

.cdk-high-contrast-active {
  .mat-badge-content {
    outline: solid 1px;
    border-radius: 0;
  }
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none
}
.mat-badge-content.mat-badge-active {
  transform: none
}
