@import "variables/colors";

.mat-toolbar {
  /*background: transparent;*/
  color: rgba($black, 0.87);
  &,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    /*font-family: $primaryFont;*/
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: normal;
  }
  &.mat-primary {
    background: $primaryColor;
    color: $white;
  }
  &.mat-accent {
    background: $secondaryColor;
    color: $white;
  }
  &.mat-warn {
    background: $danger;
    color: $white;
  }
  .mat-form-field-underline,
  .mat-form-field-ripple,
  .mat-focused .mat-form-field-ripple {
    background-color: currentColor;
  }
  .mat-form-field-label,
  .mat-focused .mat-form-field-label,
  .mat-select-value,
  .mat-select-arrow,
  .mat-form-field.mat-focused .mat-select-arrow {
    color: inherit;
  }
  .mat-input-element {
    caret-color: currentColor;
  }
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}
.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 64px;
}
@media(max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row,
  .mat-toolbar-single-row {
    height: 56px;
  }
}
