@import "variables/colors";
@import "mixins/flex.scss";


.mat-form-field:not(.mat-form-field-appearance-legacy) {
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    display: inline-flex;
    align-items: center;

    .mat-icon-button {
      width: 38px;
      height: 38px;
    }
    /*.mat-button-toggle {
      display: block;
    }*/

    .mat-icon {
      @include inline-flex;
      @include align-items-center;
      @include justify-content-center;
    }

    > .mat-icon {
      width: 32px;
      height: auto;
      padding-right: 8px;
      padding-top: 3px;
      position: relative;
      top: 2px;
    }
  }
}


.mat-form-field {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.125;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix,
.mat-form-field-suffix {
  top: 0;

  .mat-icon {
    /*width: 20px;
    height: 20px;
    font-size: 20px;*/
    line-height: 1.125;
    @include inline-flex;
    @include align-items-center;
    @include justify-content-center;
    top: 0;
  }

  /*.mat-icon-button,
  .mat-icon-button {
    !*width: 1.5em;
    height: 1.5em;*!

    .mat-icon,
    .mat-icon {
      height: 1.125em;
      line-height: 1.125;
    }
  }*/

  &.mat-focused {
    .mat-form-field-label {
      color: $primaryColor;

      &.mat-accent {
        color: $secondaryColor;
      }

      &.mat-warn {
        color: $danger;
      }
    }

    .mat-form-field-ripple {
      background-color: $primaryColor;

      &.mat-accent {
        background-color: $secondaryColor;
      }

      &.mat-warn {
        background-color: $danger;

      }
    }
  }

  &.mat-form-field-invalid {
    .mat-form-field-label {
      &,
      &.mat-accent,
      .mat-form-field-required-marker {
        color: $danger;
      }
    }

    .mat-form-field-ripple {
      &,
      &.mat-accent {
        background-color: $danger;
      }
    }
  }
}

.input-file {
  .mat-form-field-flex {
    padding-right: 0!important;
  }
  .mat-form-field-infix {
    @include flex;
    @include align-items-center;
  }
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float {
  &.mat-form-field-should-float .mat-form-field-label,
  .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    width: 133.3333333333%;
    transform: translateY(-1.34375em) scale(0.75);
  }

  .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    width: 133.3333433333%;
    transform: translateY(-1.34374em) scale(0.75);
  }
}

.mat-form-field-label {
  /*color: rgba($black, 0.6);*/
  color: $grey3;
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-label-wrapper {
  padding-top: 0.84375em;
  top: -0.84375em;
}

.mat-form-field-subscript-wrapper {
  margin-top: 0;
  @include text-h4;
  /*font-style: italic;*/
  top: calc(100% - 20px);
}
.mat-error {
  color: $danger;
  font-style: italic;
}

.mat-form-field-ripple {
  background-color: rgba($black, 0.87);
}

.mat-focused {
  .mat-form-field-required-marker {
    color: $secondaryColor;
  }
}

.mat-form-field-appearance-legacy {
  .mat-form-field-wrapper {
    padding-bottom: 1.25em;
  }

  .mat-form-field-infix {
    padding: 0.4375em 0;
  }

  &.mat-form-field-can-float {
    &.mat-form-field-should-float .mat-form-field-label,
    .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
      width: 133.3333333333%;
      transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
      -ms-transform: translateY(-1.28125em) scale(0.75);
    }

    .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
      width: 133.3333433333%;
      transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
      -ms-transform: translateY(-1.28124em) scale(0.75);
    }

    .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
      width: 133.3333533333%;
      transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
      -ms-transform: translateY(-1.28123em) scale(0.75);
    }
  }

  .mat-form-field-label {
    color: rgba($black, 0.54);
    top: 1.28125em;
  }

  .mat-form-field-underline {
    background-color: rgba($black, 0.42);
    bottom: 1.25em;
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 0.5416666667em;
    top: calc(100% - 1.6666666667em);
  }

  .mat-hint {
    color: rgba($black, 0.54);
  }

  &.mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(to right, rgba($black, 0.42) 0%, rgba($black, 0.42) 33%, transparent 0%);
    background-size: 4px 100%;
    background-repeat: repeat-x;
  }
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float {
    &.mat-form-field-should-float .mat-form-field-label,
    .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.28122em) scale(0.75);
    }

    .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.28121em) scale(0.75);
    }

    .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.2812em) scale(0.75);
    }
  }
}

.mat-form-field-appearance-fill {
  .mat-form-field-infix {
    padding: 0.25em 0 0.75em 0;
  }

  .mat-form-field-label {
    margin-top: -0.5em;
    top: 1.09375em;
  }

  &.mat-form-field-can-float {
    &.mat-form-field-should-float .mat-form-field-label,
    .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
      width: 133.3333333333%;
      transform: translateY(-0.59375em) scale(0.75);
    }

    .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
      width: 133.3333433333%;
      transform: translateY(-0.59374em) scale(0.75);
    }
  }

  .mat-form-field-flex {
    background-color: rgba($black, 0.04);
  }

  .mat-form-field-underline::before {
    background-color: rgba($black, 0.42)
  }

  &.mat-form-field-disabled {
    .mat-form-field-flex {
      background-color: rgba($black, 0.02)
    }

    .mat-form-field-label {
      color: rgba($black, 0.38);
    }

    .mat-form-field-underline::before {
      background-color: transparent
    }
  }
}

.form-control:focus {
  box-shadow: none;
}

.mat-form-field-appearance-outline {
  .mat-form-field-flex {
    margin-top: 0;
    top: 0;

    & > * {
      height: 40px;
      top: 0;

      &.mat-form-field-suffix,
      &.mat-form-field-prefix {
        height: auto;
      }
    }
  }

  .mat-form-field-infix {
    width: 162px;
    height: 40px;
    padding: 9px 0;
    /*padding: 17px 0 6px 0;*/
    border-top-width: 1px;
    top: 0;

    .mat-input-element {
      margin-top: 0;
      // line-height: 26px;
    }

    input {      
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .mat-form-field-label {
    margin-top: 0;
    font-size: 16px;
    font-weight: 400;
    top: calc(50% + 6px);
    transform: translateY(-50%);
  }

  &.mat-form-field-can-float {
    &.mat-form-field-should-float .mat-form-field-label,
    .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
      width: 133.3333333333%;
      transform: translateY(-25px) scale(0.75);
    }

    .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
      width: 133.3333433333%;
      transform: translateY(-25px) scale(0.75);
    }
  }

  .mat-form-field-outline {
    /*color: rgba($black, 0.12);*/
    color: $grey3;
  }

  .mat-form-field-outline-thick {
    /*color: rgba($black, 0.87);*/
    color: $grey3;
    opacity: 1;

    .mat-form-field-outline-start,
    .mat-form-field-outline-end,
    .mat-form-field-outline-gap {
      border-width: 1px;
    }
  }

  &.mat-focused {
    .mat-form-field-outline-thick {
      color: $primaryColor;
    }

    .mat-form-field-label {
      color: $primaryColor;
    }

    &.mat-accent {
      .mat-form-field-outline-thick {
        color: $secondaryColor;
      }
    }

    &.mat-warn {
      .mat-form-field-outline-thick {
        color: $danger;
      }
    }
  }

  &.mat-form-field-invalid.mat-form-field-invalid {
    .mat-form-field-outline-thick {
      color: $danger;
    }

    .mat-form-field-subscript-wrapper {
      color: $danger;
    }

    .mat-form-field-label {
      color: $danger;
    }
  }

  &.mat-form-field-disabled {
    .mat-form-field-label {
      color: rgba($black, 0.38);
    }

    .mat-form-field-outline {
      color: rgba($black, 0.06);
    }
  }

  /*&.form-field-pseudoplaceholder {
    &.mat-form-field-can-float {
      &.mat-form-field-should-float .mat-form-field-label-wrapper,
      .mat-input-server:focus + .mat-form-field-label-wrapper {
        display: none;
      }
    }
    .mat-form-field-outline-thick {
      .mat-form-field-outline-gap {
        width: 0;
      }
    }
  }*/
}

.captcha-block {
  .captcha-label {
    width: 100%;
    margin-bottom: 2px;
    @include text-h4;
    text-align: left;
  }

  .mat-form-field-appearance-outline {
    height: 40px;

    .mat-form-field-infix {
      padding-top: 0;
      padding-bottom: 0;
      border-top-width: 1px;
    }

    .mat-form-field-flex {
      padding-right: 0;
      /*& > * {
        height: 40px;
        top: 0;
      }*/
    }

    .mat-form-field-wrapper {
      margin-top: 0;
    }
  }
}

.form-search {
  .mat-form-field-infix {
    padding-left: 30px;
  }
  // .mat-form-field-suffix {
  //   top: -5px;
  // }
  ::ng-deep button {
    min-width: auto;
    padding-left: 0;
    padding-right: 0;
    margin-top: -8px;
  }
}

.icono-mat-suffix {
  .mat-form-field-flex {
    padding-right: 0;
  }
}

.icono-mat-prefix {
  .mat-form-field-flex  {
    padding-left: 0;
  }
}

.campo-datapicker {
  .mat-form-field-infix {
    width: 121px;
    padding-right: 25px;
  }
  .btn-limpiar-filtro {
    // transform: translateY(2px);

    position: absolute;
    top: 11px;
    right: 40px;
    
    .mat-icon-button {
      width: 20px!important;
      height: 20px!important;
      i {
        line-height: 16px;
      }
    }
  }
}

.mat-form-field-type-mat-select {
  .mat-form-field-flex {
    padding-right: 11px;
    .mat-form-field-infix {
      width: 163px;
    }
  }
}



.mat-form-field-appearance-standard {
  .mat-form-field-underline {
    background-color: rgba($black, 0.42)
  }

  &.mat-form-field-disabled {
    .mat-form-field-underline {
      background-image: linear-gradient(to right, rgba($black, 0.42) 0%, rgba($black, 0.42) 33%, transparent 0%);
      background-size: 4px 100%;
      background-repeat: repeat-x;
    }
  }
}

.mat-form-field-type-mat-native-select {  

  &.mat-focused:not(.mat-form-field-invalid) {
    .mat-form-field-infix::after {
      color: $primaryColor;
    }

    &.mat-accent {
      .mat-form-field-infix::after {
        color: $secondaryColor;
      }
    }

    &.mat-warn {
      .mat-form-field-infix::after {
        color: $danger;
      }

      .mat-form-field-subscript-wrapper {
        color: $danger;
      }
    }
  }

  .mat-form-field-infix::after {
    color: rgba($black, 0.54);
  }

  &.mat-form-field-invalid {
    .mat-form-field-infix::after {
      color: $danger;
    }

    .mat-form-field-subscript-wrapper {
      color: $danger;
    }
  }
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba($black, 0.38);
}

.mat-form-field.mat-warn,
.mat-form-field-invalid {
  .mat-input-element {
    caret-color: $danger;
  }
}

.mat-date-range-input-separator {
  border-color: transparent;
  color: rgba($black, 0.87);
}

.mat-date-range-input-inner[disabled] {
  color: rgba($black, 0.38);
}

.mat-form-field-disabled {
  .mat-date-range-input-separator {
    color: rgba($black, 0.38);
  }
}
