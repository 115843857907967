@import "variables/colors";

.mat-tooltip {
  background: rgba(97,97,97, 0.9);
  /*font-family: $primaryFont;*/
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px
}
.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px
}
